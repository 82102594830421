import { Component, OnInit, Input } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { TakeawayBox } from 'hg-front-core';

@Component({
  selector: 'app-takeaway-box',
  templateUrl: './takeaway-box.component.html',
  styleUrls: ['./takeaway-box.component.scss'],
})
export class TakeawayBoxComponent implements OnInit {
  @Input() data: TakeawayBox;
  hide = false;
  constructor(private appService: AppService) {}

  ngOnInit() {
    this.appService.tooltipShown.subscribe((hide: boolean) => {
      this.hide = hide;
    });
  }
}

import { Component, OnInit, Input } from '@angular/core';
import * as d3 from 'd3';
import { GraphSize } from '../../../services/app.service';

@Component({
  selector: 'app-pillbar-varied-lengths',
  templateUrl: './text-pill-block.component.html',
  styleUrls: ['./text-pill-block.component.scss'],
})
export class TextPillBarBlockComponent implements OnInit {
  @Input() blockData: any;

  @Input() themes: string[];
  @Input() sizeOfCanvas: GraphSize;
  @Input() title = '21M';
  @Input() subtitle = 'Since whatever';
  @Input() titleFontSize = 20;
  @Input() subitleFontSize = 10;
  @Input() titleColor = 'purple';
  @Input() sizeOfSVG: GraphSize;
  @Input() firstPercentOfSVG: number;
  @Input() secondPercentOfSVG: number;
  @Input() firstText: string;
  @Input() secondText: string;
  @Input() svgPaddingText = 10;
  @Input() svgFontSize = 5;
  @Input() svgFontColor = 'white';
  @Input() data: number[];

  constructor() {
    // set all the fileds from the dataObj
  }

  ngOnInit(): void {
    this.drawPill();
  }
  drawPill(): void {
    d3.selectAll('#SVG > *').remove();
    const svg = d3.select('#SVG');
    const widthOfBar: number = this.sizeOfSVG.width * this.firstPercentOfSVG;
    const widthOfBarSecond: number =
      this.sizeOfSVG.width * this.secondPercentOfSVG;
    const rx = 20;
    const g = svg.append('g');
    g.append('rect')
      .attr('fill', this.themes[0])
      .attr('x', 0)
      .attr('y', 0)
      .attr('rx', rx)
      .attr('ry', rx)
      .attr('width', widthOfBar)
      .attr('height', this.sizeOfSVG.height);
    g.append('rect')
      .attr('fill', this.themes[1])
      .attr('x', 0)
      .attr('y', 0)
      .attr('rx', rx)
      .attr('ry', rx)
      .attr('width', widthOfBarSecond)
      .attr('height', this.sizeOfSVG.height);
    g.append('text')
      .attr('x', widthOfBarSecond + this.svgPaddingText)
      .attr('y', this.sizeOfSVG.height / 2 + this.svgFontSize / 2)
      .style('fill', this.svgFontColor)
      .text(this.firstText);
    g.append('text')
      .attr('x', this.svgPaddingText)
      .attr('y', this.sizeOfSVG.height / 2 + this.svgFontSize / 2)
      .style('fill', this.svgFontColor)
      .text(this.secondText);
  }
}

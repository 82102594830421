const initPwdStrengthArr = [
  {
    name: '8 characters long',
    isChecked: false,
  },
  {
    name: 'Has uppercase letter',
    isChecked: false,
  },
  {
    name: 'Has lowercase letter',
    isChecked: false,
  },
  {
    name: 'Has number',
    isChecked: false,
  },
  {
    name: 'Passwords match',
    isChecked: false,
  },
];

export const generatePwdArrCopy: any = () => {
  return JSON.parse(JSON.stringify(initPwdStrengthArr));
};

// takes a password string and returns an object {newArr: object[], isPassing: boolean}
export const updatePwdArr: any = (
  currArr: any,
  pwd: string,
  confirmPwd: string
) => {
  let numChecksPassing = 0;

  // check password length
  if (pwd.length >= 8) {
    currArr[0].isChecked = true;
    numChecksPassing += 1;
  } else currArr[0].isChecked = false;

  // check has uppercase
  if (iterateStr(pwd, (val: string) => isUpperCase(val))) {
    currArr[1].isChecked = true;
    numChecksPassing += 1;
  } else currArr[1].isChecked = false;

  // check has lowercase
  if (iterateStr(pwd, (val: string) => isLowerCase(val))) {
    currArr[2].isChecked = true;
    numChecksPassing += 1;
  } else currArr[2].isChecked = false;

  // check has number
  if (iterateStr(pwd, (val: string) => isNumber(val))) {
    currArr[3].isChecked = true;
    numChecksPassing += 1;
  } else currArr[3].isChecked = false;

  // check passwords matching
  if (pwd === confirmPwd) {
    currArr[4].isChecked = true;
    numChecksPassing += 1;
  } else currArr[4].isChecked = false;

  return {
    arr: currArr,
    isPassing: numChecksPassing === initPwdStrengthArr.length,
  };
};

const iterateStr = (str, fn) => {
  for (let i = 0; i < str.length; i += 1) {
    if (fn(str[i])) return true;
  }

  return false;
};

// checks if character is lower case letter. Very english centric check.
const isLowerCase = (str: string) => {
  return str.match(/^[a-z]/) !== null;
};

// checks if character is upper case letter. Very english centric check.
const isUpperCase = (str: string) => {
  return str.match(/^[A-Z]/) !== null;
};

// checks if character is number
const isNumber = (str: string) => {
  return str.match(/^[0-9]/) !== null;
};

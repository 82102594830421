import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvConfig, EnvConfigService } from '../injectables/env-config';
import {
  NotificationType,
  UpdateNotificationEmailConfig,
  UpdateNotifications,
  UpdateNotificationSlackConfig,
} from '../models/Notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  CONTROLLER_URL: string;

  constructor(
    private http: HttpClient,
    @Inject(EnvConfigService) private envConfig: EnvConfig
  ) {
    this.CONTROLLER_URL = `${this.envConfig.api_URL}/notifications`;
  }

  getList(): Observable<UpdateNotifications> {
    const url = this.CONTROLLER_URL;
    return this.http.get<UpdateNotifications>(url);
  }

  getListBy(visualizationId: string): Observable<UpdateNotifications> {
    const url = `${this.CONTROLLER_URL}/${visualizationId}`;
    return this.http.get<UpdateNotifications>(url);
  }

  getNotificationStatus(visualizationId: string): Observable<boolean> {
    return this.getListBy(visualizationId).pipe(
      map((notifications) => {
        const [notification] = notifications;
        return notification?.enabled;
      })
    );
  }

  toggleNotificationsFor(
    visualizationId: string,
    payload: UpdateNotificationPayload
  ): Observable<UpdatedNotificationsIds> {
    const url = `${this.CONTROLLER_URL}/${visualizationId}`;
    return this.http.put<UpdatedNotificationsIds>(url, payload);
  }
}

export type UpdateNotificationPayload = {
  config: UpdateNotificationEmailConfig | UpdateNotificationSlackConfig;
  enabled: boolean;
  notification_type: NotificationType;
};
export type UpdatedNotificationsIds = string[];

import { Directive, Input, OnInit, Self, OnDestroy } from '@angular/core';
import * as d3 from 'd3';
import * as d3Annotation from 'd3-svg-annotation';
import * as d3Tip from 'd3-tip';
import {
  Block,
  BlockAxis,
  BlockConfig,
  BlockGeoviz,
  BlockPointMap,
  ColorSchemeCategorical,
  ColorSchemeDivergent,
  ColorSchemeGraduated,
  ColorSchemeHighlight,
  ColorSchemeThreshold,
  ColorSchemeRange,
  ColorSchemeSingleColor,
  BlockDataPoint,
} from 'hg-front-core';
import { AppService } from '../../../services/app.service';
import * as generalUtils from '../../../utils/url.utils';
import * as utils from './base-chart-utils';
import { MatDialogModule } from '@angular/material/dialog';

@Directive()
export abstract class BaseChartBlockComponent implements OnInit, OnDestroy {
  margins = { top: 50, right: 50, bottom: 30, left: 50 };
  data: any;
  dataObj: any;

  isMobile: boolean = null;
  drawn: boolean = null;

  config: BlockConfig;
  _block: Block;

  @Input()
  set block(data: Block) {
    this._block = data;
    this.config = this._block.config;
    this.drawChart();
  }

  get block(): Block {
    return this._block;
  }

  constructor(@Self() public appServiceBase: AppService) {}

  /**
   * like regular inheritance, this method gets executed only if the component who extends this class doesn't provide an implementation.
   */
  ngOnInit(): void {
    this.appServiceBase.getIsMobile().subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  ngOnDestroy(): void {
    this.clearOldData();
  }

  abstract drawChart(): void; //require drawChart method
  //abstract getData(config: BlockConfig): void; //require drawChart method

  clearOldData(): void {
    d3.selectAll('#SVG > *').remove();
    d3.selectAll('.d3-tip').remove();
  }

  drawCategoricalXAxis(margin, width, height) {
    const svg = d3.select('#SVG');
    const isMobile = this.isMobile;

    const xScales = d3
      .scaleBand()
      .domain((<BlockAxis>this.config).data.map((d) => <string>d.x))
      .range([margin.left, width - margin.right])
      .padding(0.1);

    const xAxis = d3.axisBottom(xScales);

    svg
      .append('g')
      .attr('class', 'axis x-axis axisLabels')
      .attr('transform', 'translate(0,' + (height - margin.bottom) + ')')
      .call(isMobile ? xAxis.tickSize(0) : xAxis);

    if (isMobile) {
      d3.selectAll('.x-axis .tick text')
        .style('text-anchor', 'end')
        .attr('font-size', '10px')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', 'rotate(-65)');
    }

    return xScales;
  }

  createColorScheme(values?: number[]) {
    const coloring = this.block.color_scheme;
    let coloringConfig;
    let colorScheme = null;

    switch (coloring.type) {
      case 'graduated':
        const min = d3.min(values);
        const max = d3.max(values);
        coloringConfig = <ColorSchemeGraduated>coloring.config;
        colorScheme = d3
          .scaleSequential(
            d3.interpolate(coloringConfig.low_color, coloringConfig.high_color)
          )
          .domain([min, max]);

        break;
      case 'threshold': {
        coloringConfig = <ColorSchemeThreshold>coloring.config;

        const unique = values.filter(this.onlyUnique).sort(function (a, b) {
          return a - b;
        });

        const interpolator = d3.interpolateRgbBasis([
          coloringConfig.low_color,
          coloringConfig.high_color,
        ]);
        colorScheme = d3.scaleThreshold(
          unique,
          d3.quantize(interpolator, unique.length)
        );

        break;
      }
      case 'divergent': {
        const min = d3.min(values);
        const max = d3.max(values);
        coloringConfig = <ColorSchemeDivergent>coloring.config;

        const middleValue =
          this.block.color_scheme.options.divergent_middle_value != null
            ? this.block.color_scheme.options.divergent_middle_value
            : 0;
        const middleColor =
          coloringConfig.middle_color != null
            ? coloringConfig.middle_color
            : 'white';

        let color_range;

        if (middleValue <= min) {
          color_range = [
            middleColor,
            coloringConfig.high_mid_color,
            coloringConfig.high_color,
          ];
          const interpolator = d3.interpolateRgbBasis(color_range);
          const interpolator_number = (t) => <any>interpolator(t);
          colorScheme = d3
            .scaleSequential()
            .domain([d3.min(values), d3.max(values)])
            .interpolator(interpolator_number);
        } else if (max <= middleValue) {
          color_range = [
            coloringConfig.low_color,
            coloringConfig.low_mid_color,
            middleColor,
          ];
          const interpolator = d3.interpolateRgbBasis(color_range);
          const interpolator_number = (t) => <any>interpolator(t);
          colorScheme = d3
            .scaleSequential()
            .domain([d3.min(values), d3.max(values)])
            .interpolator(interpolator_number);
        } else {
          color_range = [
            coloringConfig.low_color,
            coloringConfig.low_mid_color,
            middleColor,
            coloringConfig.high_mid_color,
            coloringConfig.high_color,
          ];
          const interpolator = d3.interpolateRgbBasis(color_range);
          const interpolator_number = (t) => <any>interpolator(t);
          colorScheme = d3
            .scaleDiverging()
            .domain([d3.min(values), middleValue, d3.max(values)])
            .interpolator(interpolator_number);
        }

        break;
      }
      case 'categorical':
        coloringConfig = <ColorSchemeCategorical>coloring.config;
        colorScheme = d3
          .scaleOrdinal()
          .domain(this.block.categories)
          .range(coloringConfig.colors);
        break;

      case 'range': {
        coloringConfig = <ColorSchemeRange>coloring.config;
        const domain = coloring.options.range_cutoffs
          .slice(1)
          .map((range) => range.value);
        colorScheme = d3
          .scaleThreshold()
          .domain(domain)
          .range(coloringConfig.colors);
        break;
      }
      case 'highlight': {
        coloringConfig = <ColorSchemeHighlight>coloring.config;
        colorScheme = (x) =>
          x == coloring.options.highlight_value
            ? coloringConfig.highlight_color
            : coloringConfig.default_color;

        break;
      }

      case 'singlecolor':
        coloringConfig = <ColorSchemeSingleColor>coloring.config;
        colorScheme = coloringConfig.color;
        break;
      default:
        console.error('Invalid color Scheme');
    }

    return colorScheme;
  }

  // picks color according to colorScheme with consideration of outlierBounds
  pickColor(
    colorScheme: (a: any) => string,
    value,
    outlierBounds?: any[],
    lowColor?,
    highColor?
  ) {
    return colorScheme(value);
  }

  // creates tooltips for graphs
  // keyLabels is the name for the keys, usually it is the same as keys, except for multiline.
  createToolTip(
    keys: any[],
    title?,
    keyLabels?: any[],
    graphType: string = null,
    hyperlink: string = null,
    colorLabel: string = null,
  ): d3Tip {
    d3.selectAll('.d3-tip').remove();
    const textStyle = `color: #404864; font-family:Lato, sans-serif;`;
    let timeFormat: string;
    let percent = false;
    let dollars = false;
    let hideYear = true;
    let displayTime = false;
    if (this.config && this.block.type == 'axis') {
      this.config = <BlockAxis>this.config;
      percent = this.config.yaxis.percent;
      dollars = this.config.yaxis.dollars;
      hideYear = this.config.multi_line_options.hide_year;
      displayTime = this.config.xaxis.show_time;
      timeFormat = this.config.xaxis.time_format;
    }

    if (!timeFormat) {
      if (displayTime) {
        timeFormat = '%b %e, %I %p';
      } else if (hideYear) {
        timeFormat = '%B %e';
      } else {
        timeFormat = '%B %e, %Y';
      }
    }

    const tip = d3Tip
      .default()
      .attr('class', () => (hyperlink ? 'd3-tip' : 'd3-tip unclickable'))
      .style('max-width', '300px')
      .offset(() => [-5, 0])
      .html(function (d) {
        let tipTable = '';
        if (d[title]) {
          if (hyperlink) {
            tipTable += `<a style='font-size: 14px; padding:0px 5px 5px 5px;
            font-weight: 600; text-decoration: underline; ${textStyle}' href=${d[hyperlink]}>${d[title]}</a>`;
          } else {
            tipTable += `<div style='font-size: 14px; padding:0px 5px 5px 5px;
            font-weight: 600; ${textStyle}'>
            ${
              Object.prototype.toString.call(d[title]) === '[object Date]'
                ? d3.timeFormat(timeFormat)(d[title])
                : d[title]
            }</div>`;
          }
        }
        tipTable += '<table>';
        let header =
          "<tr style='border-bottom-style: solid; border-bottom-color: #DBDEF2; border-width: 0.8px;'>";
        const dot = colorLabel ? `<span style='height:10px; width: 10px; background-color:${d[colorLabel]}; border-radius: 50%; display: inline-block;'></span>` : '';
        let element = '<tr>';
        for (let i = 0; i < keys.length; i++) {
          // For grouped barchart
          if (
            graphType == 'groupedBarBlock' &&
            d['line'] == null &&
            d['key'] != keys[i]
          ) {
            continue;
          }
          if (d[keys[i]]) {
            header += `<td style='padding:0px 5px 5px; color: #404864; font-weight:${
              d[title] ? 400 : 600
            }'>
                     ${keyLabels ? keyLabels[i] : keys[i]}</td>`;

            let value;
            // if date
            if (
              Object.prototype.toString.call(d[keys[i]]) === '[object Date]'
            ) {
              value = d3.timeFormat(timeFormat)(d[keys[i]]);

              // if number
            } else if (
              d[keys[i]] &&
              (!isNaN(d[keys[i]]) ||
                !isNaN(d[keys[i]].replace(/(^\$|,|\%$)/g, '')))
            ) {
              value = `${dollars ? '$' : ''} ${d[keys[i]].toLocaleString(
                undefined,
                { maximumFractionDigits: 2 }
              )} ${percent ? '%' : ''}`;
              // if plain text
            } else {
              value = d[keys[i]];
            }
            element += `<td style='padding: 5px; ${textStyle}'>${i==0 ? dot : ''}${value}</td>`;
          }
        }
        

        tipTable = `${tipTable} ${header} </tr> ${element} </tr> </table>`;

        tipTable = `<div>${tipTable}</div>`;

        return tipTable;
      })
      .attr('border-style', 'solid')
      .attr('border-width', '0.8px')
      .attr('border-color', '#DBDEF2')
      .style('z-index', '1300');
    return tip;
  }

  createRowBasedTooltip(keys: any[], title?, label?) {
    let timeFormat: string;
    let percent = false;
    let dollars = false;
    let hideYear = true;
    let displayTime = false;
    if (this.config && this.block.type == 'axis') {
      this.config = <BlockAxis>this.config;
      percent = this.config.yaxis.percent;
      dollars = this.config.yaxis.dollars;
      hideYear = this.config.multi_line_options.hide_year;
      displayTime = this.config.xaxis.show_time;
      timeFormat = this.config.xaxis.time_format;
    }

    if (!timeFormat) {
      if (displayTime) {
        timeFormat = '%b %e, %I %p';
      } else if (hideYear) {
        timeFormat = '%B %e';
      } else {
        timeFormat = '%B %e, %Y';
      }
    }
    const textStyle = `color: #404864; font-family:Lato, sans-serif;`;
    const tip = d3Tip
      .default()
      .attr('class', 'd3-tip unclickable')
      .style('max-width', '200px')
      .offset(() => [-5, 0])
      .html(function (d) {
        const values: any[] = keys.map((i) => d[i]);
        if (!values.some((e) => e !== null))
          return `<span style='${textStyle}'>Data Unavailable</span>`;
        let tipTable = '';

        tipTable += `<div style='font-size: 14px; padding:0px 5px 5px 5px;
          font-weight: 600; ${textStyle}'>
          ${
            Object.prototype.toString.call(d[title]) === '[object Date]'
              ? d3.timeFormat(timeFormat)(d[title])
              : d3.timeFormat(timeFormat)(d[title])
          }</div>`;

        tipTable += '<table>';
        let firstRow = true;
        keys.forEach((k) => {
          let element;
          if (firstRow) {
            tipTable +=
              "<tr style='border-top-style: solid; border-top-color:#DBDEF2; border-width: 0.8px'>";
            firstRow = !firstRow;
          } else {
            tipTable += '<tr>';
          }
          element = `<td style='font-size:12px; padding: 2px 5px; font-weight: 600; ${textStyle}'>${k}</td>`;
          if (d[k] !== null && typeof d[k] !== 'undefined') {
            // if link
            if (generalUtils.isValidHttpUrl(d[k].toLocaleString())) {
              element += `<td style='${textStyle}'><a style='text-decoration: underline;' target="_blank" href=" ${d[
                k
              ].toLocaleString()} ">Click here to read more</a></td>`;
              // if number
            } else if (
              !isNaN(d[k]) ||
              !isNaN(d[k].replace(/(^\$|,|\%$)/g, ''))
            ) {
              /*
            var textColor
            if(typeof d[k] == "string" && d[k].slice(-1)== "%"){
              if(Number(d[k].replace(/(^\$|,|\%$)/g, '')) >= 0){
                textColor = "#15BE59"
              }else{
                textColor = "#E61C32"
              }
            }else{
              textColor = "#404864"
            }*/
              element += `<td style='font-size: 12px; font-weight: 400; ${textStyle}'>
                        ${d[k].toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}</td>`;
              // if plain text
            } else {
              element += `<td style='font-size: 12px; font-weight: 400; ${textStyle}'>${d[k]}</td>`;
            }
            tipTable += `${element}</tr>`;
          } else {
            tipTable += '</tr>';
          }
        });
        tipTable += '</table>';
        return tipTable;
      })
      .attr('border-style', 'solid')
      .attr('border-width', '0.8px')
      .attr('border-color', '#DBDEF2')
      .style('z-index', '1300');
    return tip;
  }

  // creates tooltips for maps
  createMapTooltip(keys: any[], title?: string, hyperlink?: string) {
    const textStyle = `color: #404864; font-family:Lato, sans-serif;`;
    const tip = d3Tip
      .default()
      .attr('class', 'd3-tip')
      .style('max-width', '300px')
      .offset(() => [-5, 0])
      .html(function (d) {
        const values: any[] = keys.map((i) => d[i]);

        if (!values.some((e) => e !== null))
          return `<span style='${textStyle}'>Data Unavailable</span>`;
        let tipTable = '';
        if (d[title]) {
          tipTable += `<div style='font-size: 14px; padding:0px 5px 5px 5px; font-weight: 600; ${textStyle}'>${d[title]}</div>`;
        }
        tipTable += '<table>';
        let firstRow = true;
        keys.forEach((k) => {
          let element;
          if (firstRow) {
            tipTable +=
              "<tr style='border-top-style: solid; border-top-color:#DBDEF2; border-width: 0.8px'>";
            firstRow = !firstRow;
          } else {
            tipTable += '<tr>';
          }
          element = `<td style='font-size:12px; padding: 2px 5px; font-weight: 600; ${textStyle}'>${k}</td>`;
          if (d[k] !== null && typeof d[k] !== 'undefined') {
            // if link
            if (generalUtils.isValidHttpUrl(d[k].toLocaleString())) {
              element += `<td style='${textStyle}'><a style='text-decoration: underline;' target="_blank" href=" ${d[
                k
              ].toLocaleString()} ">Click here to explore more</a></td>`;
              // if number
            } else if (
              !isNaN(d[k]) ||
              !isNaN(d[k].replace(/(^\$|,|\%$)/g, ''))
            ) {
              /*
            var textColor
            if(typeof d[k] == "string" && d[k].slice(-1)== "%"){
              if(Number(d[k].replace(/(^\$|,|\%$)/g, '')) >= 0){
                textColor = "#15BE59"
              }else{
                textColor = "#E61C32"
              }
            }else{
              textColor = "#404864"
            }*/
              element += `<td style='font-size: 12px; font-weight: 400; ${textStyle}'>
                        ${d[k].toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}</td>`;
              // if plain text
            } else {
              element += `<td style='font-size: 12px; font-weight: 400; ${textStyle}'>${d[k]}</td>`;
            }
            tipTable += `${element}</tr>`;
          } else {
            tipTable += '</tr>';
          }
        });
        tipTable += '</table>';
        return tipTable;
      })
      .attr('border-style', 'solid')
      .attr('border-width', '0.8px')
      .attr('border-color', '#DBDEF2')
      .attr('overflow-wrap', 'break-word')
      .style('z-index', '1300');
    return tip;
  }

  drawUnavailableMap(map) {
    const overlay = d3.select(map.getPanes().overlayPane);
    const svg = overlay.select('svg');
    map.dragging.disable();
    map.scrollWheelZoom.disable();
    map.zoomControl.disable();
    svg
      .append('rect')
      .attr('width', '100%')
      .attr('height', '100%')
      .style('fill', '#a1a1a1')
      .style('opacity', 0.6);
    svg
      .append('text')
      .text('Data Unavailable')
      .attr('x', parseInt(d3.select('#mapid').style('width')) / 2)
      .attr('y', parseInt(d3.select('#mapid').style('height')) / 2)
      .style('text-anchor', 'middle')
      .attr('font-size', '4vw')
      .attr('fill', '#FFFFFF');
  }

  drawHorizontalLegend(legendKeys: any[], width, svglegend): void {
    const spacingBetweenLegend = 10;
    let marginLeft = 8;
    const selector = svglegend ? '#legend' : '#SVG';
    d3.select(selector).selectAll('.legend-container').remove();
    const svg = d3.select(selector);

    const legend = svg
      .append('g')
      .attr('class', 'legend-container')
      .append('g')
      .attr('class', 'legend')
      .attr('transform', 'translate(0,-20)');

    legendKeys.sort((a, b) =>
      a.label < b.label ? -1 : a.label > b.label ? 1 : 0
    );

    const lg = legend.selectAll('g').data(legendKeys).enter().append('g');

    lg.append('circle')
      .style('fill', (d) => d.color)
      .attr('cx', 8)
      .attr('cy', 18)
      .attr('r', 5);

    lg.append('text')
      .style('font-size', '14px')
      .style('font-family', 'Lato, sans-serif')
      .attr('fill', '#898EAF')
      .attr('x', 25)
      .attr('y', 22)
      .text((d) => d.label);

    const nodeWidth = (d) => d.getBBox().width;
    let x_min = 0;
    let offset = 10;
    lg.each(function (d, i) {
      x_min += nodeWidth(this) + 10;
    });
    const nextLine =
      width - 2 * this.margins.left - 2 * this.margins.right < x_min;
    let target: number;

    if (window.innerWidth <= 800) {
      target = width - this.margins.left;
    } else {
      target = width; /// 1.6;
    }

    marginLeft = nextLine == true ? marginLeft : 0;
    offset = marginLeft;
    let yValue = 20;

    lg.attr('transform', function (d, i) {
      const x = offset;
      offset += nodeWidth(this) + spacingBetweenLegend;
      let ret: string;
      if (offset >= target && nextLine) {
        offset = nodeWidth(this) + spacingBetweenLegend + marginLeft;
        yValue += 20;
        ret = `translate(${marginLeft}, ${yValue})`;
      } else {
        ret = `translate(${x}, ${yValue})`;
      }
      return ret;
    });

    legend.attr('transform', function () {
      return `translate(${(width - nodeWidth(this)) / 2},${-20})`;
    });
  }

  getCategoricalLegendHeight(): number {
    const legendNode = d3.select('.legend-container').node() as any;
    return legendNode.getBBox().height;
  }

  drawColorLegend(
    width: number,
    values: number[],
    scheme: any,
    percentage: boolean
  ): void {
    const h = 5;
    const w = d3.min([480, width * 0.5]);
    const svg = d3.select('#legend');
    d3.select('#legend').selectAll('*').remove();
    const coloringType = this.block.color_scheme.type;
    const format = utils.getLegendTickFormat(
      d3.min(values),
      d3.max(values),
      false,
      percentage,
      scheme.invertExtent //this will be true if scheme is threshold only
    );

    let tickValues;

    //We override default ticks on divergent colors only
    if (coloringType == 'divergent') {
      const min = d3.min(values);
      const max = d3.max(values);
      const middleValue =
        this.block.color_scheme.options.divergent_middle_value != null
          ? this.block.color_scheme.options.divergent_middle_value
          : 0;

      if (min <= middleValue && middleValue <= max) {
        tickValues = [
          min,
          (min + middleValue) / 2,
          middleValue,
          (max + middleValue) / 2,
          max,
        ];
      }
    }

    const legendTitle = (<BlockPointMap | BlockGeoviz>this.config).legend.title;
    const legend = utils.Legend(scheme, {
      title: legendTitle,
      width: w,
      tickFormat: format,
      screenWidth: width,
      tickValues: tickValues,
    });
  }

  // annotateObj: {type, color, annotations[]} accessorX: d => xScale(d), accessorY: d => yScale()
  drawAnnotations(annotateObj, accessorX, accessorY) {
    const svg = d3.select('#SVG');
    const annotations = [];
    annotateObj.forEach((annotate) => {
      let type;
      // Note: annotate.subject must be shallow to avoid reference
      const subject = Object.assign({}, annotate.subject);
      switch (annotate.type) {
        case 'label':
          type = d3Annotation.annotationLabel;
          break;
        case 'callout':
          type = d3Annotation.annotationCallout;
          break;
        case 'calloutElbow':
          type = d3Annotation.annotationCalloutElbow;
          break;
        case 'calloutCircle':
          type = d3Annotation.annotationCalloutCircle;
          break;
        case 'calloutRect':
          type = d3Annotation.annotationCalloutRect;
          break;
        case 'XThreshold':
          type = d3Annotation.annotationXYThreshold;
          subject.x1 = accessorX(annotate.subject.x1);
          subject.x2 = accessorX(annotate.subject.x2);
          break;
        case 'YThreshold':
          type = new d3Annotation.annotationCustomType(
            d3Annotation.annotationXYThreshold,
            {
              note: {
                lineType: 'none',
                orientation: 'top',
                align: 'middle',
              },
            }
          );
          subject.y1 = accessorY(annotate.subject.y1);
          subject.y2 = accessorY(annotate.subject.y2);
          break;
        case 'badge':
          type = d3Annotation.annotationBadge;
          break;
        default:
          console.error('Unknown annotation type');
      }
      annotations.push({
        note: { title: annotate.title, label: annotate.label, wrap: 100 },
        data: { x: annotate.x, y: annotate.y },
        color: annotate.color,
        type: type,
        dx: annotate.type == 'YThreshold' ? 0 : -50,
        dy: annotate.type == 'YThreshold' ? 0 : -50,
        subject: subject,
      });
    });
    const makeAnnotations = d3Annotation
      .annotation()
      .accessors({
        x: (d: any) => accessorX(d.x),
        y: (d: any) => accessorY(d.y),
      })
      .annotations(annotations);

    svg
      .append('g')
      .attr('class', 'annotation-group')
      .call(makeAnnotations as any);

    // below here add css attributes for annotation customization
  }

  getTextWidth(text: string, fontFamily = 'Roboto', fontSize = 14): number {
    return utils.getTextWidth(text, fontFamily, fontSize);
  }

  getTooltipDirection(posX: number, width: number, isMobile: boolean): string {
    return utils.getTooltipDirection(posX, width, isMobile);
  }

  onlyUnique(value, index, self): boolean {
    return self.indexOf(value) === index;
  }

  getBoundingBoxCenter(element): number[] {
    return utils.getBoundingBoxCenter(element);
  }
}

import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private notificationService: NzNotificationService) {}

  success(title = 'Success!', content = ''): void {
    this.notificationService.success(title, content, {
      nzClass: 'notification-message success',
    });
  }

  error(
    title = 'Oops!',
    content = 'Something went wrong. Please, contact support!'
  ): void {
    this.notificationService.error(title, content, {
      nzClass: 'notification-message error',
    });
  }
}

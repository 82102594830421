import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserDetails } from '../models/user';
import { LocalStorageService } from './local-storage.service';

const LOCAL_STORAGE_KEY = 'layout';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  menuOpened$: Observable<boolean>;
  userDetails$: Observable<UserDetails>;
  menu$: Observable<MenuItems>;

  private currentLayoutState: Layout;
  private layoutStateSubject: BehaviorSubject<Layout>;

  constructor(private localStorageService: LocalStorageService) {
    this.currentLayoutState = localStorageService.loadState<Layout>(
      LOCAL_STORAGE_KEY,
      {
        menuOpened: false,
        userDetails: undefined,
        menu: [],
      }
    );
    this.layoutStateSubject = new BehaviorSubject<Layout>(
      this.currentLayoutState
    );

    this.menuOpened$ = this.layoutStateSubject
      .asObservable()
      .pipe(
        map(
          (layoutState) => layoutState.menuOpened && !!layoutState.menu?.length
        )
      );
    this.userDetails$ = this.layoutStateSubject
      .asObservable()
      .pipe(map((layoutState) => layoutState.userDetails));
    this.menu$ = this.layoutStateSubject
      .asObservable()
      .pipe(map((layoutState) => layoutState.menu));
    this.layoutStateSubject.asObservable().subscribe((newLayoutState) => {
      this.currentLayoutState = newLayoutState;
      localStorageService.updateItem(LOCAL_STORAGE_KEY, newLayoutState);
    });
  }

  toggleMenu(): void {
    this.layoutStateSubject.next({
      ...this.currentLayoutState,
      menuOpened: !this.currentLayoutState.menuOpened,
    });
  }

  setUser(userDetails: UserDetails): void {
    this.layoutStateSubject.next({
      ...this.currentLayoutState,
      userDetails,
    });
  }

  setMenu(menu: MenuItems): void {
    this.layoutStateSubject.next({
      ...this.currentLayoutState,
      menu,
    });
  }
  clear(): void {
    this.localStorageService
      .removeItem(LOCAL_STORAGE_KEY)
      .subscribe(() => this.layoutStateSubject.next(null));
  }
}
export type Layout = {
  menu: MenuItems;
  menuOpened: boolean;
  userDetails: UserDetails;
};

export type MenuItems = MenuItem[];
export type MenuItem = {
  label: string;
  imgUrl: string;
  placement: 'main' | 'add-on' | 'bottom';
  disabled?: boolean;
} & (NavItem | ActionItem);

type NavItem = { path: string };
type ActionItem = { execute: () => void };

import { BoundingBox } from '../models/BoundingBox';
import { GeoDetails } from '../models/GeoData';

export const toBoundingBox = (geoLocation: GeoDetails): BoundingBox => {
  const {
    lat1: nw_latitude,
    lon1: nw_longitude,
    lat2: se_latitude,
    lon2: se_longitude,
  } = geoLocation;
  return { nw_latitude, nw_longitude, se_latitude, se_longitude };
};

export const getBounds = (boundinxBox: BoundingBox): [number, number][] => {
  const { nw_latitude, nw_longitude, se_latitude, se_longitude } = boundinxBox;
  return [
    [nw_latitude, nw_longitude],
    [se_latitude, se_longitude],
  ];
};

import { Component, Input } from '@angular/core';
import BLOCK_ERRORS from '../../../core/constants/block-errors';
import UI_ERRORS from '../../constants/ui-errors';

@Component({
  selector: 'ui-image-message',
  templateUrl: './image-message.component.html',
  styleUrls: ['./image-message.component.scss'],
})
export class ImageMessageComponent {
  @Input()
  type: Message;

  UI_ERRORS = { ...UI_ERRORS, ...BLOCK_ERRORS };
}

export type Message = UI_ERRORS | BLOCK_ERRORS;

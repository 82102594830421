import { Component, OnInit, Input } from '@angular/core';
import { GraphSize } from '../../../services/app.service';

@Component({
  selector: 'app-figureblock',
  templateUrl: './figureblock.component.html',
  styleUrls: ['./figureblock.component.scss'],
})
export class FigureblockComponent implements OnInit {
  @Input() blockData;
  @Input() sizeCanvas: GraphSize = { width: 310, height: 100 };
  @Input() name: string;
  @Input() figureText: string;
  @Input() subText: string;
  @Input() textColor = 'blue';
  @Input() subTextColor = 'gray';
  @Input() nameFont = 23;
  @Input() subtitleFont = 15;

  graphSize: GraphSize;
  constructor() {}

  ngOnInit(): void {
    console.debug('in figure block');
    this.figureText = this.blockData.title.text;
    this.subText = this.blockData.subtitle.text;
    const figureScale = this.blockData.title.scale;
    const subScale = this.blockData.subtitle.scale;
    const figureRef = this.blockData.title.color;
    const subRef = this.blockData.subtitle.color;
    this.nameFont = this.sizeCanvas.width / 10 + 5;
    this.nameFont = 35;
    this.subtitleFont = 13;
    this.textColor = this.blockData.theme[figureRef];
    this.subTextColor = this.blockData.theme[subRef];

    if (window.innerWidth < 750) {
      if (window.innerWidth < 500) {
        this.nameFont = this.nameFont * 0.5;
      } else {
        this.nameFont = this.nameFont * 0.75;
      }
    }
  }
}

import { EMPTY, Observable, of } from 'rxjs';

export class StorageService {
  private storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  isAvailable(): boolean {
    return this.storage !== undefined;
  }

  loadState<T>(key: string, defaultValue: T): T {
    const value = this.storage.getItem(key) || '';
    return value !== '' ? JSON.parse(value) : defaultValue;
  }

  updateItem<T>(key: string, value: T): Observable<T> {
    const valueToSave = JSON.stringify(value);
    const valueInCache = this.storage.getItem(key);
    if (valueToSave !== valueInCache) {
      this.storage.setItem(key, valueToSave);
      return of(value);
    }
    return EMPTY;
  }

  getItem<T>(key: string): Observable<T> {
    return of(this.loadState(key, undefined));
  }

  removeItem(key: string): Observable<boolean> {
    this.storage.removeItem(key);
    return of(true);
  }

  clear(): void {
    this.storage.clear();
  }
}

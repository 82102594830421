import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgZorroModule } from '../ng-zorro/ng-zorro.module';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CircleIconComponent } from './components/circle-icon/circle-icon.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { DividerComponent } from './components/divider/divider.component';
import { ImageMessageComponent } from './components/image-message/image-message.component';
import { LayoutComponent } from './components/layout/layout.component';
import { MenuComponent } from './components/menu/menu.component';
import { MetaDataItemComponent } from './components/meta-data-item/meta-data-item.component';
import { ModalComponent } from './components/modal/modal.component';
import { PageContentComponent } from './components/page-content/page-content.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { SkeletonListComponent } from './components/skeleton-list/skeleton-list.component';
import { TabComponent } from './components/tab/tab.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';

const UI_COMPONENTS = [
  CheckboxComponent,
  CircleIconComponent,
  ConfirmationModalComponent,
  DividerComponent,
  ImageMessageComponent,
  LayoutComponent,
  MenuComponent,
  MetaDataItemComponent,
  ModalComponent,
  PageContentComponent,
  SearchInputComponent,
  SectionHeaderComponent,
  SkeletonListComponent,
  TabsComponent,
  TabComponent,
  TopBarComponent,
];

@NgModule({
  declarations: UI_COMPONENTS,
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgZorroModule,
    FlexLayoutModule,
  ],
  exports: [
    // third-party
    FlexLayoutModule,
    NgZorroModule,
    // custom
    ...UI_COMPONENTS,
  ],
})
export class UIComponentsModule {}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-skeleton-list',
  templateUrl: './skeleton-list.component.html',
  styleUrls: ['./skeleton-list.component.scss'],
})
export class SkeletonListComponent implements OnInit {
  @Input()
  length = 3;

  @Input()
  avatar = true;

  constructor() {}

  ngOnInit(): void {}
}

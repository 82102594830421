<div class="table-block-container">
  <div class="table-block-title-container" *ngIf="!title">
    <p class="table-block-title">{{ title }}</p>
  </div>
  <table
    mdbTable
    mdbTableScroll
    class="table table-striped table-borderless table-condensed"
    [ngClass]="{ noHide }"
    scrollY="true"
    bordered="true"
    maxHeight="{{ _maxHeight }}"
    (wheel)="scrollAnalytics()"
  >
    <thead>
      <tr>
        <th
          class="sortable-header"
          *ngFor="let col of columns; let i = index"
          scope="col"
          style="vertical-align: text-top"
          [sortable]="col"
          (sort)="onSort($event)"
        >
          <span title="{{ col }}" *ngIf="i !== 0"
            >{{ isMobile ? col.substring(0, 20) : col
            }}{{ isMobile && col.length > 20 ? "..." : "" }}</span
          >
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let el of sortedElements; let i = index">
        <td *ngFor="let label of columns" [innerHTML]="el[label]"></td>
      </tr>
    </tbody>
  </table>
</div>

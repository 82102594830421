import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalizeFirst' })
export class CapitalizeFirstPipe implements PipeTransform {
  constructor() {}
  transform(text: string): string {
    const firstLetter = text.substring(0, 1);
    const rest = text.substring(1);
    return firstLetter.toUpperCase() + rest.toLowerCase();
  }
}

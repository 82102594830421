<ng-container *ngIf="navItems$ | async">
  <div
    class="menu"
    [ngClass]="{ collapsed }"
    fxLayout="column"
    fxLayoutAlign="space-between"
    fxFill
  >
    <div id="top-menu">
      <img
        class="brand-logo"
        [src]="
          'shared-assets/icons/higeorge-logo/dataherald-logo-' +
          (collapsed ? 'symbol' : 'full') +
          '-white.svg'
        "
        alt=""
        routerLink="/"
        (click)="logHomeLogoInteraction()"
      />
      <ng-container
        *ngTemplateOutlet="itemListRef; context: { items: mainItems }"
      ></ng-container>
      <ui-divider [width]="collapsed ? 'small' : 'medium'"></ui-divider>
      <ng-container
        *ngTemplateOutlet="itemListRef; context: { items: addOnItems }"
      ></ng-container>
    </div>
    <div id="bottom-menu">
      <div class="toggle-menu" (click)="toggleMenu()">
        <ng-container *ngIf="collapsed; else expanded">
          <span nz-icon nzType="double-right"></span>
        </ng-container>
        <ng-template #expanded>
          <div fxLayoutAlign="center">
            <span nz-icon nzType="double-left"></span>
          </div>
        </ng-template>
      </div>
      <ui-divider [width]="collapsed ? 'small' : 'medium'"></ui-divider>
      <ng-container
        *ngTemplateOutlet="
          itemListRef;
          context: { items: bottomItems, userItem: true }
        "
      >
      </ng-container>
    </div>
  </div>

  <ng-template #itemListRef let-menuItems="items" let-showUser="userItem">
    <ul nz-menu nzMode="inline" nzTheme="dark">
      <ng-container *ngFor="let menuItem of menuItems">
        <ng-container
          *ngTemplateOutlet="menuItemRef; context: { item: menuItem }"
        ></ng-container>
      </ng-container>

      <li *ngIf="showUser" class="menu-item" nz-menu-item>
        <div class="user" fxLayout="row" fxLayoutAlign="start center">
          <img
            [ngClass]="{ 'mr-2': !collapsed }"
            [src]="userDetails.image_url"
            alt=""
          />
          <span *ngIf="!collapsed" class="text">
            {{ userDetails.first_name }} {{ userDetails.last_name }}
          </span>
        </div>
      </li>

      <ng-template #menuItemRef let-menuItem="item">
        <li
          class="menu-item"
          nz-menu-item
          nz-tooltip
          [nzTooltipTitle]="collapsed ? menuItem.label : ''"
          nzTooltipPlacement="right"
          nzTooltipColor="#22205e"
        >
          <ng-container
            *ngTemplateOutlet="
              !!menuItem.path ? navItemRef : actionItemRef;
              context: { item: menuItem }
            "
          >
          </ng-container>
          <ng-template #navItemRef let-menuItem="item">
            <a
              class="nav-menu-item"
              fxLayout="row"
              fxLayoutAlign="start center"
              [routerLink]="[menuItem.path]"
              routerLinkActive="active-menu"
              (click)="logMenuItemInteraction(menuItem)"
            >
              <ng-container
                *ngTemplateOutlet="itemDisplayRef; context: { item: menuItem }"
              ></ng-container>
            </a>
          </ng-template>
          <ng-template #actionItemRef let-menuItem="item">
            <div
              class="action-menu-item"
              fxLayout="row"
              fxLayoutAlign="start center"
              (click)="logMenuItemInteraction(menuItem); menuItem.execute()"
            >
              <ng-container
                *ngTemplateOutlet="itemDisplayRef; context: { item: menuItem }"
              ></ng-container>
            </div>
          </ng-template>
        </li>
      </ng-template>

      <ng-template #itemDisplayRef let-menuItem="item">
        <img
          [ngClass]="{ 'mr-3': !collapsed }"
          [src]="menuItem.imgUrl"
          alt=""
        />
        <span *ngIf="!collapsed" class="text">
          {{ menuItem.label }}
        </span>
      </ng-template>
    </ul>
  </ng-template>
</ng-container>

<img
  src="shared-assets/icons/menu.svg"
  alt=""
  nz-dropdown
  nzTrigger="click"
  [nzDropdownMenu]="menu"
  [nzOverlayStyle]="{ overflow: 'auto' }"
/>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu class="mobile-menu">
    <ng-container *ngFor="let item of menuItems">
      <li
        *ngIf="!item.disabled"
        nz-menu-item
        (click)="menuItemClicked(item)"
        [ngClass]="{ unavailable: item.unavailable }"
      >
        <ng-container *ngIf="item.unavailable; else regularIcon">
          <img src="shared-assets/icons/computer.svg" alt="" />
        </ng-container>
        <ng-template #regularIcon>
          <img class="icon" [src]="item.iconUrl" alt="" />
        </ng-template>
        {{ item.label }}
      </li>
    </ng-container>
  </ul>
</nz-dropdown-menu>

<app-visualization-request-modal
  *ngIf="showVisualizationRequestModal"
  (onCloseModal)="showVisualizationRequestModal = false"
></app-visualization-request-modal>

<app-help-modal
  *ngIf="showHelpModal"
  (closeModal)="showHelpModal = false"
></app-help-modal>

<app-settings-modal
  *ngIf="showSettingsModal"
  (onCloseModal)="showSettingsModal = false"
></app-settings-modal>

<app-confirm-logout-modal
  *ngIf="showLogoutConfirmation"
  (onCloseModal)="showLogoutConfirmation = false"
></app-confirm-logout-modal>

import { Component, OnInit, SkipSelf } from '@angular/core';
import * as d3 from 'd3';
import { AnalyticsService, BlockAxis } from 'hg-front-core';
import { AxisBlockComponent } from 'projects/HGFrontSharedUI/src/lib/modules/core/components/blocks/axis-block/axis-block.component';
import BLOCK_ERRORS from '../../../constants/block-errors';
import { AppService } from '../../../services/app.service';
@Component({
  selector: 'app-grouped-barchart-block',
  templateUrl: './grouped-barchart-block.component.html',
  styleUrls: ['./grouped-barchart-block.component.scss'],
})
export class GroupedBarchartBlockComponent
  extends AxisBlockComponent
  implements OnInit
{
  margins = { top: 0, right: 30, bottom: 30, left: 50 };
  isMobile: boolean = null;

  constructor(
    private googleAnalayticsService: AnalyticsService,
    @SkipSelf() public appService: AppService
  ) {
    super(appService);
  }

  drawChart(): void {
    if (
      !this.block ||
      !this.size ||
      this.size.width === 0 ||
      this.size.height === 0
    )
      return;
    d3.selectAll('#SVG > *').remove();

    this.config = <BlockAxis>this.block.config;
    const self = this;
    const svg = d3.select('#SVG');
    const isMobile = this.isMobile;
    const width = this.size.width;
    const height = this.size.height;
    svg.attr('width', width).attr('height', height);
    const margin = {
      top: 55,
      right: isMobile ? 15 : 30,
      bottom: 40,
      left: 45,
    };
    const timeSeries = this.config.xaxis.timeseries;
    const rowBasedToolTip = false; //this.config.row_based_tooltip;
    const scheme = this.block.color_scheme;
    //const tooltips = this.dataObj.tooltips
    const axisColor = 'black';

    const colorScheme = this.createColorScheme();

    let xAxis;

    const series = this.config.data.map((d) => ({
      title: d.x,
      key: d.category,
      value: d.value,
      [d.category]: d.value,
    }));

    /* Following block adds the x-axis label which only remained on grouped-bar. Commenting until we decide whether to keep
     svg
      .append('text') //x axis label for categorical
      .attr(
        'transform',
        'translate(' + width / 2 + ' ,' + (height + 38 - margin.bottom) + ')'
      )
      .style('text-anchor', 'middle')
      .attr('class', 'axis')
      .style('fill', '#62678B')
      .text(this.config.xaxis.label);*/

    const maxNum = d3.max(series, (d) => d['value']);
    const scale = this.drawYAxis(0, maxNum, height, margin, width, true);

    let tip; // add createToolTip
    const multirow = false;

    let xScales, x_bandwidth, zScale;

    if (timeSeries) {
      // untested, need multi row
      tip = this.createToolTip(
        this.block.categories,
        this.block.categories,
        null,
        'groupedBarBlock'
      );

      x_bandwidth = d3
        .scaleBand<Date>()
        .domain(this.config.data.map((d) => <Date>d.x))
        .range([margin.left, width - margin.right])
        .padding(0.1)
        .bandwidth();
      xScales = this.drawXAxis(
        margin,
        width - x_bandwidth / 2,
        height,
        x_bandwidth
      );
    } else if (rowBasedToolTip) {
      tip = this.createRowBasedTooltip(this.block.categories, 'title');
    } else {
      tip = this.createToolTip(this.block.categories, 'title');
      xScales = this.drawCategoricalXAxis(margin, width, height);
      x_bandwidth = xScales.bandwidth();
    }

    zScale = d3
      .scaleBand(this.block.categories, [0, x_bandwidth])
      .padding(0.05);
    svg.call(tip);

    //I think this code does nothing
    if (isMobile && !timeSeries) {
      d3.selectAll('.x-axis .tick text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', 'rotate(-65)');
    }

    if (width <= 1250) {
      d3.selectAll('.x-axis .tick text')
        .style('text-anchor', 'end')
        .attr('font-size', '10px')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', 'rotate(-45)');
    }

    const ga = this.googleAnalayticsService;

    const timseriesOffset = timeSeries ? x_bandwidth / 2 : 0;
    svg
      .append('g') //draw rectangles
      .selectAll('rect')
      .data(series)
      .join('rect')
      .attr(
        'x',
        (d: any, i) => xScales(d['title']) - timseriesOffset + zScale(d['key'])
      )
      .attr('y', (d) => scale(d['value']))
      .attr('height', (d) => {
        if (d['value'] == 0) throw new Error(BLOCK_ERRORS.NEGATIVE_BAR_HEIGHT);
        else return scale(0) - scale(d['value'] ? d['value'] : 0);
      })
      .attr('width', zScale.bandwidth())
      .attr('fill', (d) => colorScheme(d['key']))
      .on('mouseover', function (d: any) {
        const centroid = self.getBoundingBoxCenter(this);
        const width = (svg as any).node().clientWidth;
        const direction = self.getTooltipDirection(
          centroid[0],
          width,
          isMobile
        );
        tip.direction(direction).show(d, this);
        d3.select(this).attr('fill', 'lightgray');
        ga.blockEventEmitter(
          'Interaction',
          'interaction',
          globalThis.vizCollection +
            '/' +
            globalThis.vizType +
            '/' +
            'groupedBarChart',
          null,
          0,
          {
            InteractionType: 'mouseOver',
            Collection: globalThis.vizCollection,
            vizType: globalThis.vizType,
            block: 'groupedBarChart',
          },
          true
        );
      })
      .on('mouseout', function (d: any) {
        tip.hide(d, this);
        d3.select(this).attr('fill', colorScheme(d['key']));
      });

    const legendKeys = []; //legend
    colorScheme.domain().forEach((key) => {
      legendKeys.push({ label: key, color: colorScheme(key) });
    });
    this.drawHorizontalLegend(legendKeys, width, false);
  }
}

export const isEmpty = (obj: Record<ObjectKey, unknown>): boolean =>
  !!obj && Object.keys(obj).length === 0;

/**
 * Fast comparision.
 * Order of keys matters for comparision.
 * @param o1
 * @param o2
 * @returns
 */
export const isEqual = <T = unknown>(
  o1: Record<ObjectKey, T> | T[],
  o2: Record<ObjectKey, T> | T[]
): boolean => JSON.stringify(o1) === JSON.stringify(o2);

export type ObjectKey = string | number | symbol;

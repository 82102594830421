// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { auth, shared_front_core } from './shared-env';

export const environment = {
  production: true,
  client_URL: 'https://dev.app.dataherald.com',
  embed_client_URL: 'https://dev.embed.dataherald.com/v4',
  api_URL: 'https://dev.api.dataherald.com/v4',
  website_URL: 'https://dev.dataherald.com',
  auth: {
    ...auth,
  },
  front_core: {
    api_URL: 'https://dev.api.dataherald.com/v4',
    api_v2_URL: 'https://dev.api.dataherald.com/api/v2',
    embed_client_URL: 'https://dev.embed.dataherald.com/v4',
    ...shared_front_core,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

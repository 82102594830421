import { MediaObserver } from '@angular/flex-layout';
import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'ui-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent {
  @Input()
  set backEnabled(value: boolean) {
    if (value !== undefined && value !== false) {
      this._backEnabled = true;
    } else {
      this._backEnabled = value;
    }
  }

  @Input()
  set whiteBG(value: boolean) {
    if (value !== undefined && value !== false) {
      this._whiteBG = true;
    } else {
      this._whiteBG = value;
    }
  }

  @Input()
  set appLogo(value: boolean) {
    if (value !== undefined && value !== false) {
      this._appLogo = true;
    } else {
      this._appLogo = value;
    }
  }

  _appLogo = false;
  _backEnabled = false;
  _whiteBG = false;

  constructor(
    public mediaObserver: MediaObserver,
    private location: Location
  ) {}

  goBack(): void {
    this.location.back();
  }
}

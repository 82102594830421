import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-viz-tile',
  templateUrl: './viz-tile.component.html',
  styleUrls: ['./viz-tile.component.scss'],
})
export class VizTileComponent implements OnInit {
  // required params
  @Input() isLoading: boolean;

  // all params below are optional
  @Input() previewImg?: string;
  @Input() dataProviderImg?: string;
  @Input() dataProviderName?: string;
  @Input() location?: string;
  @Input() graphType?: string;
  @Input() organization?: string;
  @Input() graphTypeIcon?: string;
  @Input() refreshPeriod?: string;
  @Input() isRecentlyUpdated?: boolean = false;
  @Input() isLive?: boolean = false;
  @Input() showHoverActions?: boolean = false;
  @Input() isDataFeed?: boolean = false;
  @Input() isNewFeed?: boolean = false;

  @Output() onDelete? = new EventEmitter<void>();
  @Output() onDuplicate? = new EventEmitter<void>();
  @Output() onView? = new EventEmitter<void>();
  @Output() onClick? = new EventEmitter<void>();
  @Output() onExport? = new EventEmitter<void>();

  /*
  lastUpdated should be passed in as a date string that can be parsed
  into a date via: new Date(Date.parse(this.lastUpdated));
  */
  @Input() lastUpdated?: string;
  formattedLastUpdated: string = null;

  // relevant constants for calculating the title below
  MAX_TITLE_WIDTH = (284 - 15) * 2; // (the width of one line - some padding) * 2 total lines
  ELLIPSIS_WIDTH = 19; // width of 3 dots
  _title = ' '; // the title that's applied to the tile

  /*
  If the title fits within the max width, set it immediately. Else,
  truncate the title until it fits then add an ellipsis to the end.

  Have to do this manually because CSS ellipsis only works when
  text is one line long.
  */
  @Input() set title(value: string) {
    // calculate initial values
    let currStr = value;
    let currWidth = this.getTextWidth(currStr);

    // set immediately if it fits
    if (currWidth < this.MAX_TITLE_WIDTH) {
      this._title = value;
    }

    // or else, manually remove letters until it fits
    else {
      while (currWidth > this.MAX_TITLE_WIDTH - this.ELLIPSIS_WIDTH) {
        // remove 1 letter for every 10px over the max width
        const diff = currWidth - (this.MAX_TITLE_WIDTH - this.ELLIPSIS_WIDTH);
        currStr = currStr.slice(0, -1 * Math.ceil(diff / 10));

        // calculate new width
        currWidth = this.getTextWidth(currStr);
      }
      this._title = currStr + '...';
    }
  }
  position: any = 'bottomRight';

  constructor() {}

  ngOnInit(): void {
    this.formattedLastUpdated = this.calculateFormattedDate();
  }

  calculateFormattedDate(): string {
    if (!this.lastUpdated) return null;

    // turn into date object
    const dateType = new Date(Date.parse(this.lastUpdated));

    // calculate how long ago in seconds
    const timeDiff = (Date.now() - dateType.valueOf()) / 1000;

    //determines if we'll show the "Data Updated!" floating label
    //set to 24 HOURS (in seconds)
    if (timeDiff < 86400) {
      this.isRecentlyUpdated = true;
    }
    // less than 1 hour ago
    if (timeDiff < 3600) {
      return Math.round(timeDiff / 60) + ' min ago';
    }

    // 1 hour to 1 day ago
    else if (timeDiff < 86400) {
      const numHoursAgo = Math.round(timeDiff / 3600);
      return numHoursAgo + (numHoursAgo === 1 ? ' hr ago' : ' hrs ago');
    }

    // 1 day to 30 days ago
    else if (timeDiff < 2592000) {
      const numDaysAgo = Math.round(timeDiff / 86400);
      return numDaysAgo + (numDaysAgo === 1 ? ' day ago' : ' days ago');
    }

    // greater than 30 days
    const numMonthsAgo = Math.round(timeDiff / 2592000);
    return numMonthsAgo + (numMonthsAgo === 1 ? ' month ago' : ' months ago');
  }

  onClickDelete(): void {
    this.onDelete.emit();
  }

  onClickDuplicate(): void {
    this.onDuplicate.emit();
  }

  onClickTile(): void {
    this.onClick.emit();
  }

  onClickView(): void {
    this.onView.emit();
  }

  onClickLink(): void {
    this.onExport.emit();
  }

  getTextWidth(text: string): number {
    // create element
    const el = document.createElement('p');

    // add styles passed by user
    el.style.font = 'Lato';
    el.style.fontSize = 17 + 'px';
    el.style.fontWeight = '900';
    el.innerHTML = text;

    // other styles
    el.style.height = 'auto';
    el.style.width = 'auto';
    el.style.position = 'absolute';
    el.style.whiteSpace = 'no-wrap';
    el.style.visibility = 'hidden';

    // retrieve width
    document.body.appendChild(el);
    const width = el.clientWidth;
    document.body.removeChild(el);

    return width;
  }
}

<div class="tabs" ngClass.xs="small" fxLayout="row">
  <ng-container *ngFor="let tab of tabs; index as i">
    <div class="tab" [class.active]="tab.active" (click)="selectTab(i)">
      {{ tab.title }}
    </div>
  </ng-container>
</div>
<div class="content">
  <ng-content></ng-content>
</div>

import { Directive, Input, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';
import { Block, BlockAxis, BlockDataPoint } from 'hg-front-core';
import { BaseChartBlockComponent } from 'projects/HGFrontSharedUI/src/lib/modules/core/components/blocks/base-chart-block/base-chart-block.component';
import * as utils from 'projects/HGFrontSharedUI/src/lib/modules/core/components/blocks/base-chart-block/base-chart-utils';
import { GraphSize } from '../../../services/app.service';

@Directive()
export abstract class AxisBlockComponent extends BaseChartBlockComponent {
  isMobile: boolean = null;
  @Input()
  set block(data: Block) {
    this._block = data;
    this.config = <BlockAxis>this._block.config;
    if (this.config.xaxis.timeseries) {
      this.config.data.forEach((datapoint) => {
        const datetime = new Date(datapoint['x']);

        const userTimezoneOffset = datetime.getTimezoneOffset() * 60000;
        datapoint['x'] = new Date(datetime.getTime() + userTimezoneOffset);
      });
    }
    this.drawChart();
  }

  get block(): Block {
    return this._block;
  }

  _size: GraphSize;
  @Input()
  set size(size: GraphSize) {
    this._size = size;
    this.drawChart();
  }
  get size(): GraphSize {
    return this._size;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.size) {
      if (changes.size.currentValue.width <= 575) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      this.drawChart();
    }
  }

  drawYAxis(minNum, maxNum, height, margin, width, minZero: boolean) {
    this.config = <BlockAxis>this.config;
    const svg = d3.select('#SVG');
    const gridLines = this.config.yaxis.gridlines;

    const textColor = this.config.axis_color;
    // attributes for all text inside y axis text (i.e. ticks, label, etc.)
    const textFont = 'Lato, sans-serif';
    const textFontSize = 14;

    // scaling for the y axis
    const { min, max } = this.calculateYAxisRange(minNum, maxNum, minZero);
    let significantDigitShown = 2;
    if (max - min < 8) {
      significantDigitShown = 3;
    }

    // add 40 to top margin to make space for the y axis title label
    let marginTop = margin.top;
    if (this.config.yaxis.label) marginTop += 55;

    const scale = d3
      .scaleLinear()
      .domain([min, max])
      .range([height - margin.bottom, marginTop]);

    // tick formatting for the y axis
    const tickFormat = utils.calcYTickFormat(
      this.config.yaxis.dollars,
      this.config.yaxis.percent,
      significantDigitShown
    );

    // create y axis to pass into svg
    const yAxis = d3
      .axisLeft(scale)
      .tickSize(-(width - margin.left - margin.right))
      .tickFormat(tickFormat);

    // calculate width of longest tick
    const formattedTicks = scale.ticks().map(function (t, i) {
      return yAxis.tickFormat()(t, i);
    });
    const longestTick = utils.longestStrInArr(formattedTicks);
    const tickWidth = utils.getTextWidth(longestTick, textFont, textFontSize);

    // update left margin if longest tick has greater width than margin
    const widthDiff = margin.left - tickWidth;
    if (widthDiff < 5) {
      if (widthDiff <= 0) margin.left = tickWidth + 5;
      else margin.left = margin.left + (5 - widthDiff);
    }

    if (gridLines) {
      svg.append('g').attr('class', 'grid axisLabels yAxisLabels').call(yAxis);

      d3.selectAll('.grid')
        .attr('transform', 'translate(' + margin.left + ',0)')
        .selectAll('path, line')
        .style('stroke', '#D7D5F9')
        .style('stroke-dasharray', '2, 2');

      d3.selectAll('.grid .domain').attr('visibility', 'hidden');
    } else {
      svg
        .append('g')
        .attr('class', 'axis yAxisLabels')
        .call(d3.axisLeft(scale))
        .attr('transform', 'translate(' + margin.left + ',0)');
    }

    // prepend the y axis title label to the beginning of the y axis if it exists
    if (this.config.yaxis.label) {
      d3.select('.yAxisLabels')
        // insert as the first child inside of yAxisLabels -- makes it appear on top
        .insert('text', ':first-child')
        .attr(
          'transform',
          'translate(' + -1 * tickWidth + ', ' + (marginTop - 15) + ')'
        )
        .attr('text-anchor', 'start')
        .text(this.config.yaxis.label);
    }

    d3.selectAll('.grid text')
      .attr('font-family', textFont)
      .attr('font-size', textFontSize + 'px')
      .style('fill', textColor);

    return scale;
  }

  drawXAxis(margin, width, height, bandwidth?, displayTime = false) {
    const svg = d3.select('#SVG');
    this.config = <BlockAxis>this.config;
    const axisColor = this.config.axis_color;
    const isMobile = this.isMobile;
    const feedTimeFormat = (<BlockAxis>this.block.config).xaxis.time_format;
    const dateExtent = d3.extent(
      <BlockDataPoint<Date>[]>(<BlockAxis>this.block.config).data,
      (d) => d.x
    );
    const xScales = d3
      .scaleTime()
      .domain(dateExtent)
      .range([
        margin.left + (bandwidth ? bandwidth / 2 : 0),
        width - margin.right,
      ]);
    const numDays =
      (dateExtent[1].getTime() - dateExtent[0].getTime()) / (1000 * 3600 * 24);
    const xAxis = d3.axisBottom(xScales).tickFormat(function (date) {
      if (displayTime) {
        return d3.timeFormat('%m/%d %I%p')(date as any);
      } else {
        if (dateExtent[1].getFullYear() == dateExtent[0].getFullYear()) {
          if (numDays >= 60) {
            return d3.timeFormat('%b')(date as any);
          }
          return d3.timeFormat('%b %e')(date as any);
        }
        if (d3.timeYear(date as any) >= (date as any)) {
          return d3.timeFormat('%b ’%y')(date as any);
        } else {
          //This second part of this clause is a safe fix. We should design the format to match the
          //datafeed timeformat in more cases
          return numDays < 365 && feedTimeFormat?.includes('%e')
            ? d3.timeFormat('%b %e')(date as any)
            : d3.timeFormat('%b')(date as any);
        }
      }
    });

    if (displayTime) {
      xAxis.ticks(d3.timeHour.every(12));
    } else if (!isMobile) {
      if (numDays < 15) xAxis.ticks(d3.timeDay.every(1));
      else if (numDays < 60) xAxis.ticks(d3.timeDay.every(4));
      else if (numDays < 1200) xAxis.ticks(d3.timeMonth.every(1));
      else if (numDays < 3000) xAxis.ticks(d3.timeMonth.every(3));
      else xAxis.ticks(d3.timeYear.every(1));
    } else {
      if (numDays < 15) xAxis.ticks(d3.timeDay.every(1));
      else if (numDays < 60) xAxis.ticks(d3.timeDay.every(4));
      else if (numDays < 1095) xAxis.ticks(d3.timeMonth.every(1));
      else if (numDays < 1825) xAxis.ticks(d3.timeMonth.every(3));
      else xAxis.ticks(d3.timeYear.every(1));
    }

    svg
      .append('g')
      .attr('class', 'axis x-axis axisLabels')
      .attr('transform', 'translate(0,' + (height - margin.bottom) + ')')
      .call(isMobile ? xAxis.tickSize(0) : xAxis);

    d3.selectAll('.x-axis .domain').style('stroke', axisColor);

    d3.selectAll('.x-axis .tick line').style('stroke', axisColor);

    d3.selectAll('.x-axis text')
      .attr('font-family', 'Lato, sans-serif')
      .attr('font-size', '14px')
      .style('fill', axisColor);

    if (isMobile) {
      d3.selectAll('.x-axis .tick text')
        .style('text-anchor', 'end')
        .attr('font-size', '10px')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', 'rotate(-65)');
    } else if (width <= 1250) {
      // temporary solution to clustered x-axis, need scale function
      d3.selectAll('.x-axis .tick text')
        .style('text-anchor', 'end')
        .attr('font-size', '10px')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', 'rotate(-45)');
    }

    return xScales;
  }

  // convenience function, since this function is called by other blocks
  // too, which don't have access to utils
  calculateYAxisRange(
    minNum: number,
    maxNum: number,
    minZero: boolean
  ): utils.Range {
    return utils.calculateYAxisRange('', minNum, maxNum, minZero);
  }
}

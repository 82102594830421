import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  AnalyticsService,
  Visualization,
  VisualizationData,
  VisualizationParametersValues,
  VisualizationService,
  VisualizationSummary,
} from 'hg-front-core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { VisualizationPreviewService } from '../../services/visualization-preview.service';

@Component({
  selector: 'app-visualization-preview',
  templateUrl: './visualization-preview.component.html',
  styleUrls: ['./visualization-preview.component.scss'],
})
export class VisualizationPreviewComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input()
  visualizationId: string;

  @Input()
  actionLabel = 'Edit';

  @Input()
  mode: PreviewMode = PREVIEW_MODE.EDIT;

  @Output()
  onActionClicked = new EventEmitter<Visualization>();

  vizData$: Observable<VisualizationData>;
  vizSummary$: Observable<VisualizationSummary>;

  PREVIEW_MODE = PREVIEW_MODE;
  error: boolean;
  actionClicked = false;
  isExportModalOpen = false;

  private vizDataSubject = new BehaviorSubject<VisualizationData>(null);
  private vizSummarySubject = new BehaviorSubject<VisualizationSummary>(null);
  private getSub: Subscription;
  private updateSub: Subscription;
  private duplicateSub: Subscription;

  constructor(
    private analytics: AnalyticsService,
    private router: Router,
    private visualizationAPIService: VisualizationService,
    private visualizationPreviewService: VisualizationPreviewService
  ) {}

  onActionClick(): void {
    this.actionClicked = true;
    this.onActionClicked.emit({
      data: this.vizDataSubject.value,
      summary: this.vizSummarySubject.value,
    });
  }

  goToEditWithError(): void {
    this.analytics.dhEvent('go-to-edit-with-preview-error', 'sidebar-preview', {
      'viz-id': this.visualizationId,
    });
    this.router.navigate([`visualization/edit/${this.visualizationId}`]);
  }

  updateGraph(parametersValues: VisualizationParametersValues): void {
    this.updateSub = this.loadVisualization(
      this.visualizationAPIService.patchParameters(
        this.visualizationId,
        parametersValues
      )
    );
  }

  openExportModal(vizSummary: VisualizationSummary): void {
    this.isExportModalOpen = true;
    this.analytics.v4EventEmitter({
      eventName: 'open-export-modal',
      vizSummary,
      extraProperties: {
        pageRoute: 'sidebar-preview',
      },
    });
  }

  duplicate(vizSummary: VisualizationSummary): void {
    const newTitle = `Copy of ${vizSummary.title}`;
    this.duplicateSub = this.visualizationAPIService
      .duplicate(vizSummary.id, { parameters: { title: newTitle }, save: true })
      .subscribe(({ id: newVizId }) => {
        this.router.navigate([`/visualization/edit/${newVizId}`]);
      });
  }

  ngOnChanges(): void {
    if (!this.visualizationId) return;

    this.visualizationPreviewService.clearError();

    this.getSub = this.loadVisualization(
      this.visualizationPreviewService.getVisualization(this.visualizationId)
    );
  }

  ngOnDestroy(): void {
    this.vizDataSubject.complete();
    this.vizSummarySubject.complete();
    this.getSub?.unsubscribe();
    this.updateSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.vizData$ = this.vizDataSubject.asObservable();
    this.vizSummary$ = this.vizSummarySubject.asObservable();
    this.visualizationPreviewService.error$.subscribe(
      (error) => (this.error = error)
    );
  }

  private loadVisualization(
    visualizationProvider: Observable<Visualization>
  ): Subscription {
    this.vizSummarySubject.next(null);
    this.vizDataSubject.next(null);
    return visualizationProvider.subscribe((visualization) => {
      const { summary, data } = visualization;
      this.vizSummarySubject.next(summary);
      this.vizDataSubject.next(data);
    });
  }
}

enum PREVIEW_MODE {
  CREATE = 'create',
  EDIT = 'edit',
}

type PreviewMode = PREVIEW_MODE;

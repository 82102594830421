<ng-container *ngIf="(user$ | async) && mediaObserver.isActive('gt-sm')">
  <ui-menu></ui-menu>

  <app-visualization-request-modal
    *ngIf="showVisualizationRequestModal"
    (onCloseModal)="showVisualizationRequestModal = false"
  ></app-visualization-request-modal>

  <app-help-modal
    *ngIf="showHelpModal"
    (closeModal)="showHelpModal = false"
  ></app-help-modal>

  <app-settings-modal
    *ngIf="showSettingsModal"
    (onCloseModal)="showSettingsModal = false"
  ></app-settings-modal>

  <app-confirm-logout-modal
    *ngIf="showLogoutConfirmation"
    (onCloseModal)="showLogoutConfirmation = false"
  >
  </app-confirm-logout-modal>
</ng-container>

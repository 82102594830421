import { distanceToNow } from '../../utils/date-utils';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateDistanceToNow',
})
export class DateDistanceToNowPipe implements PipeTransform {
  transform(
    value: string | Date,
    options: { addSuffix: boolean } = { addSuffix: true }
  ): string {
    const { addSuffix } = options;
    return value ? distanceToNow(value, addSuffix) : '-';
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss'],
})
export class SectionHeaderComponent implements OnInit {
  @Input()
  header: SectionHeader;

  constructor() {}

  ngOnInit(): void {}
}

export type SectionHeader = {
  title: string;
  iconUrl?: string;
};

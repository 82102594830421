<ui-modal
  [title]="modalTitle"
  width="600px"
  height="auto"
  (onCloseModal)="onClickNo()"
>
  <div class="wrapper">
    <div class="modal-copy">
      <h1>{{ header }}</h1>
      <p>{{ subheader }}</p>
    </div>
    <div class="options-buttons">
      <div class="btn-filled" (click)="onClickYes()">
        <p>{{ btnYesText }}</p>
      </div>
      <div class="btn-outline" (click)="onClickNo()">
        <p>{{ btnNoText }}</p>
      </div>
    </div>
  </div>
</ui-modal>

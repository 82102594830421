import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';

/**
 * We need to redirect from old URLs to the new ones preserving the queryParams.
 * At the day of this development, Angular doesn't provide a way to do it from the route configs.
 * This is a workaround for such requirement.
 */

@Injectable({
  providedIn: 'root',
})
export class OldRoutesGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const [oldUrlSegment, vizIdSegment] = route.url;
    const oldUrl = oldUrlSegment.path;
    const newUrl = NEW_ROUTES_MAP[oldUrl] || '/';
    const queryParams = newUrl !== '/' ? route.queryParams : {};
    switch (oldUrl) {
      case OLD_ROUTES.CREATE_VIZ: {
        return this.router.navigate([newUrl], {
          queryParams,
        });
      }
      case OLD_ROUTES.EDIT_VIZ:
        return this.router.navigate([`${newUrl}/${vizIdSegment.path}`]);
      default:
        return this.router.navigate(['/']);
    }
  }
}

const enum OLD_ROUTES {
  CREATE_VIZ = 'create-graph',
  EDIT_VIZ = 'editviz',
}
const NEW_ROUTES_MAP = {
  [OLD_ROUTES.CREATE_VIZ]: 'visualization/create',
  [OLD_ROUTES.EDIT_VIZ]: 'visualization/edit',
};

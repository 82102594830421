import { NgModule } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

const THIRD_PARTY_MODULES = [
  NzLayoutModule,
  NzMenuModule,
  NzIconModule,
  NzToolTipModule,
  NzButtonModule,
  NzLayoutModule,
  NzSpinModule,
  NzIconModule,
  NzInputModule,
  NzInputNumberModule,
  NzPageHeaderModule,
  NzCheckboxModule,
  NzDatePickerModule,
  NzListModule,
  NzSwitchModule,
  NzDrawerModule,
  NzTableModule,
  NzSelectModule,
  NzToolTipModule,
  NzSliderModule,
  NzSkeletonModule,
  NzTypographyModule,
  NzCardModule,
  NzDropDownModule,
  NzMessageModule,
  NzNotificationModule,
  NzTabsModule,
  NzModalModule,
  NzFormModule,
];
@NgModule({
  declarations: [],
  imports: THIRD_PARTY_MODULES,
  exports: THIRD_PARTY_MODULES,
})
export class NgZorroModule {}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { whatsNewCacheBuster } from '../constants/cache';
import { EnvConfig, EnvConfigService } from '../injectables/env-config';
import { WhatsNewPost, WhatsNewPosts } from '../models/WhatsNew';

@Injectable({
  providedIn: 'root',
})
export class WhatsNewService {
  CONTROLLER_URL: string;

  constructor(
    private http: HttpClient,
    @Inject(EnvConfigService) private envConfig: EnvConfig
  ) {
    this.CONTROLLER_URL = `${this.envConfig.api_URL}/whats_new`;
  }

  @Cacheable({
    cacheBusterObserver: whatsNewCacheBuster,
  })
  getList(queryParams?: WhatsNewPostListParams): Observable<WhatsNewPosts> {
    const url = this.CONTROLLER_URL;
    const params = new HttpParams({
      fromObject: queryParams,
    });
    return this.http.get<WhatsNewPosts>(url, { params });
  }

  @Cacheable({
    cacheBusterObserver: whatsNewCacheBuster,
  })
  getPost(postId: string): Observable<WhatsNewPost> {
    const url = `${this.CONTROLLER_URL}/${postId}`;
    return this.http.get<WhatsNewPost>(url);
  }

  @CacheBuster({
    cacheBusterNotifier: whatsNewCacheBuster,
  })
  create(payload: WhatsNewPostPayload): Observable<WhatsNewPost> {
    const url = this.CONTROLLER_URL;
    return this.http.post<WhatsNewPost>(url, payload);
  }

  @CacheBuster({
    cacheBusterNotifier: whatsNewCacheBuster,
  })
  update(
    postId: string,
    payload: WhatsNewPostPayload
  ): Observable<WhatsNewPost> {
    const url = `${this.CONTROLLER_URL}/${postId}`;
    return this.http.put<WhatsNewPost>(url, payload);
  }

  @CacheBuster({
    cacheBusterNotifier: whatsNewCacheBuster,
  })
  delete(postId: string): Observable<void> {
    const url = `${this.CONTROLLER_URL}/${postId}`;
    return this.http.delete<void>(url);
  }
}

export type WhatsNewPostListParams = {
  offset?: number;
};

export type WhatsNewPostPayload = {
  content_updates: string;
  feature_updates: string;
  title: string;
};

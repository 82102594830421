import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthService as Auth0Service,
  User as Auth0User,
} from '@auth0/auth0-angular';
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable,
  throwError,
} from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { UserDetails } from './../models/user';
import { LayoutService } from './layout.service';
import { LocalStorageService } from './local-storage.service';
import { LoggedInUserService } from './logged-in-user.service';
import { SessionStorageService } from './session-storage.service';
import { UserService, UserTrialPOST } from './user.service';

const GENERIC_ERROR =
  'Something went wrong. Please try again or contact support.';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentState: AuthStateAndMessage = {
    state: null,
    message: '',
  };
  private authStateSubject = new BehaviorSubject<AuthStateAndMessage>(
    this.currentState
  );
  private user$: Observable<AuthUser> = this.auth0Service.user$;

  state$: Observable<AuthStateAndMessage> =
    this.authStateSubject.asObservable();

  constructor(
    private userService: UserService,
    private layoutService: LayoutService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private loggedInUserService: LoggedInUserService,
    private auth0Service: Auth0Service
  ) {
    this.auth0Service.error$.subscribe((error) => {
      if (error['error'] === EAuthState.UNAUTHORIZED) {
        const errorArray = error['error_description'].split('---');
        if (errorArray.length < 2) {
          this.setAuthErr();
        } else if (errorArray[1] === 'Unauthorized Domain') {
          this.setAuthErr(
            'The current user is unauthorized to access this application.'
          );
        } else if (errorArray[1] === 'Email Not Verified') {
          this.setAuthErr('email-unverified');
        } else {
          this.setAuthErr();
        }
      } else {
        this.setAuthErr(error['error_description']);
      }
    });

    combineLatest([this.state$, this.user$]).subscribe(
      ([authState, authUser]) => {
        // CASE 1: no authUser, so set user to empty as they are not yet authenticated
        if (!authUser && authState.state !== EAuthState.ERROR) {
          this.setAuthState(EAuthState.UNAUTHORIZED);
          return this.loggedInUserService.setUser(null);
        } else if (authState.state === EAuthState.AUTHORIZED) {
          return;
        } else if (authState.state === EAuthState.ERROR) {
          // Slated for deletion think CASE 2: there's already a user and they are the same as authUser, so do nothing
          return;
        }
        // CASE 3: fetch the user object from server and set user to that
        else if (authState.state !== EAuthState.LOADING) {
          this.setAuthState(EAuthState.LOADING);
          this.createAccountWithOAuth().subscribe((newUser) => {
            if (!newUser.image_url) newUser.image_url = authUser.picture;
            /* Sets the user values if you are a google user */
            if (
              !newUser.finished_setup &&
              newUser.auth_type == 'google_oauth'
            ) {
              this.loggedInUserService
                .updateGoogleUser(newUser, authUser)
                .pipe(
                  catchError(() => {
                    this.setAuthErr();
                    return throwError('handled error');
                  })
                )
                .subscribe(() => this.setAuthState(EAuthState.AUTHORIZED));
            } else {
              this.loggedInUserService.setUser(newUser, true);
              this.setAuthState(EAuthState.AUTHORIZED);
            }
          });
        }
      }
    );
  }

  login(): void {
    this.auth0Service.loginWithRedirect();
  }

  logout(): void {
    this.layoutService.clear();
    this.localStorageService.clear();
    this.sessionStorageService.clear();
    this.auth0Service.logout({ returnTo: window.location.origin + '/' });
  }

  loginWithOAuthClient(redirect_uri: string): Observable<AuthUser> {
    return from(
      this.auth0Service.loginWithRedirect({
        redirect_uri,
      })
    ).pipe(mapTo(this.user$));
  }

  createAccountWithOAuth(): Observable<UserDetails> {
    return this.userService.login().pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.status == 401) {
          errorMessage = 'The current user is unauthorized.';
        } else if (error.message == 'Login required') {
          errorMessage =
            'It seems you are logging in from Incognito mode or have cookies disabled. Please change this setting and try again. If the problem persists, please contact support.';
        } else {
          errorMessage = error['error_description'] || 'unknown error';
        }
        this.setAuthErr(errorMessage);
        return throwError(errorMessage);
      })
    );
  }

  createAccountWithUsername(
    email: string,
    addToNewsLetter: boolean
  ): Observable<UserDetails> {
    const payload: UserTrialPOST = { email, newsletter: addToNewsLetter };
    return this.userService.createUserTrial(payload).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error.status);
      })
    );
  }

  signUpGoogleOauth(redirect_uri: string): Observable<AuthUser> {
    return from(
      this.auth0Service.loginWithRedirect({
        redirect_uri,
        conection: 'google-oauth2',
      })
    ).pipe(mapTo(this.user$));
  }

  private setAuthErr(message: string = GENERIC_ERROR): void {
    this.setAuthState(EAuthState.ERROR, message);
  }

  private setAuthState(state: AuthState, message = ''): void {
    if (
      state !== this.currentState.state ||
      message !== this.currentState.message
    ) {
      console.debug('Authentication Status transitioned.');
      console.debug('Previous state: ', this.currentState);
      this.currentState = { state, message };
      console.debug('Current state: ', this.currentState);
      this.authStateSubject.next(this.currentState);
    }
  }
}

export type AuthStateAndMessage = {
  state: AuthState;
  message: string;
};
export type AuthState = EAuthState | null;
export enum EAuthState {
  AUTHORIZED = 'authorized',
  UNAUTHORIZED = 'unauthorized',
  LOADING = 'loading',
  ERROR = 'error',
}
export type AuthUser = Auth0User;

export type AuthType = 'google_oauth' | 'username_password';

import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AnalyticsService,
  LayoutService,
  MenuItem,
  MenuItems,
  UserDetails,
} from 'hg-front-core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ui-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  navItems$: Observable<MenuItems>;

  mainItems: MenuItems = [];
  addOnItems: MenuItems = [];
  bottomItems: MenuItems = [];

  collapsed: boolean;

  userDetails: UserDetails;

  userSub: Subscription;
  menuOpenedSub: Subscription;

  constructor(
    private analytics: AnalyticsService,
    private layoutService: LayoutService
  ) {}

  logMenuItemInteraction(menuItem: MenuItem): void {
    this.analytics.dhEvent('menu-item-click', 'menu', {
      'menu-label': menuItem.label,
    });
  }

  logHomeLogoInteraction(): void {
    this.analytics.dhEvent('home-logo-click', 'menu');
  }

  toggleMenu(): void {
    this.analytics.dhEvent('menu-size-toggle', 'menu', {
      collapsed: this.collapsed,
    });
    this.layoutService.toggleMenu();
  }

  ngOnDestroy(): void {
    this.menuOpenedSub.unsubscribe();
    this.userSub.unsubscribe();
  }

  ngOnInit(): void {
    this.navItems$ = this.layoutService.menu$.pipe(
      tap((navItems) => {
        this.mainItems = navItems.filter(
          (menuItem) => !menuItem.disabled && menuItem.placement === 'main'
        );
        this.addOnItems = navItems.filter(
          (menuItem) => !menuItem.disabled && menuItem.placement === 'add-on'
        );
        this.bottomItems = navItems.filter(
          (menuItem) => !menuItem.disabled && menuItem.placement === 'bottom'
        );
      })
    );
    this.userSub = this.layoutService.userDetails$.subscribe(
      (userDetails) => (this.userDetails = userDetails)
    );
    this.menuOpenedSub = this.layoutService.menuOpened$.subscribe(
      (menuOpened) => (this.collapsed = !menuOpened)
    );
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AnalyticsService } from 'hg-front-core';

@Component({
  selector: 'app-visualization-request-modal',
  templateUrl: './visualization-request-modal.component.html',
  styleUrls: ['./visualization-request-modal.component.scss'],
})
export class VisualizationRequestModalComponent implements OnInit {
  @Output() onCloseModal? = new EventEmitter<void>();

  constructor(private analytics: AnalyticsService) {}

  ngOnInit(): void {}

  onClickCreateCCR(): void {
    this.analytics.dhEvent('open-ccr-form', 'ccr-modal');
    window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLScmdc9aT8KuSxaR2TKve3VtrTbp3yve8cJYClZmimLM8VrW2w/viewform',
      '_blank'
    );
  }
}

import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HGFrontCoreModule } from 'hg-front-core';
import { SharedUICoreModule, UIComponentsModule } from 'hg-front-shared-ui';
import { environment } from '../../../environments/environment';
import { GraphViewComponent } from './components/graph-view/graph-view.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { UnsupportedDeviceComponent } from './components/unsupported-device/unsupported-device.component';
import { VisualizationPreviewComponent } from './components/visualization-preview/visualization-preview.component';
import { VizTileComponent } from './components/viz-tile/viz-tile.component';
import { ConfirmLogoutModalComponent } from './modals/confirm-logout-modal/confirm-logout-modal.component';
import { VisualizationRequestModalComponent } from './modals/custom-request-modal/visualization-request-modal.component';
import { HelpModalComponent } from './modals/help-modal/help-modal.component';
import { SettingsModalComponent } from './modals/settings-modal/settings-modal.component';
import { VizExportModalComponent } from './modals/viz-export-modal/viz-export-modal.component';

@NgModule({
  declarations: [
    GraphViewComponent,
    SidebarMenuComponent,
    VizTileComponent,
    VisualizationRequestModalComponent,
    VisualizationPreviewComponent,
    VizExportModalComponent,
    SettingsModalComponent,
    ConfirmLogoutModalComponent,
    UnsupportedDeviceComponent,
    MobileMenuComponent,
    HelpModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UIComponentsModule,
    SharedUICoreModule,
    ClipboardModule,
    HGFrontCoreModule.forRoot({
      ...environment.front_core,
    }),
  ],
  exports: [
    GraphViewComponent,
    SidebarMenuComponent,
    UnsupportedDeviceComponent,
    VisualizationPreviewComponent,
    VizExportModalComponent,
    VizTileComponent,
    VisualizationRequestModalComponent,
    MobileMenuComponent,
    [
      HGFrontCoreModule.forRoot({
        ...environment.front_core,
      }),
    ],
  ],
})
export class DataHeraldCoreModule {}

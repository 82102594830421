import { Injectable } from '@angular/core';
import {
  AnalyticsService,
  CacheService,
  Visualization,
  VisualizationService,
} from 'hg-front-core';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { catchError, map, pluck, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VisualizationPreviewService {
  private visualizationStore: CacheService<Visualization>;
  private createdVizIdFromDataFeedStore: CacheService<string>;
  private errorSubject = new ReplaySubject<boolean>(1);

  error$ = this.errorSubject.asObservable();

  constructor(
    private analytics: AnalyticsService,
    private visualizationAPIService: VisualizationService
  ) {
    this.visualizationStore = new CacheService<Visualization>(
      (visualizationId: string) =>
        combineLatest([
          this.visualizationAPIService.getData(visualizationId),
          this.visualizationAPIService.getSummary(visualizationId),
        ]).pipe(map(([data, summary]) => ({ data, summary })))
    );

    this.createdVizIdFromDataFeedStore = new CacheService<string>(
      (dataFeedId: string) =>
        this.visualizationAPIService
          .createFromDataFeed({
            dataFeedId,
          })
          .pipe(
            pluck('id'),
            tap((vizId) => {
              this.analytics.dhEvent(
                'create-viz-from-curated-datafeed',
                'home',
                { 'feed-id': dataFeedId, 'viz-id': vizId }
              );
            })
          )
    );
  }

  getVisualization(vizId: string): Observable<Visualization> {
    return this.visualizationStore.byId(vizId).pipe(
      catchError(() => {
        this.errorSubject.next(true);
        throw 'Error loading visualization preview';
      })
    );
  }

  getVisualizationId(dataFeedId: string): Observable<string> {
    return this.createdVizIdFromDataFeedStore.byId(dataFeedId);
  }

  removeVisualizationFromCache(visualizationId: string): void {
    this.visualizationStore.bustCache(visualizationId);
  }

  clearError(): void {
    this.errorSubject.next(false);
  }
}

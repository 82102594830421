import { Component, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { LayoutService, LoggedInUserService } from 'hg-front-core';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent implements OnInit {
  user$ = this.loggedInUserService.user$.pipe(
    tap((userDetails) => this.layoutService.setUser(userDetails))
  );
  showVisualizationRequestModal = false;
  showHelpModal = false;
  showSettingsModal = false;
  showLogoutConfirmation = false;

  constructor(
    public mediaObserver: MediaObserver,
    private loggedInUserService: LoggedInUserService,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.layoutService.setMenu([
      {
        label: 'Discover',
        imgUrl: 'shared-assets/icons/rss.svg',
        path: '/home',
        placement: 'main',
      },
      {
        label: 'Workspace',
        imgUrl: 'shared-assets/icons/grid.svg',
        path: '/workspace',
        placement: 'main',
      },
      {
        label: 'Build',
        imgUrl: 'shared-assets/icons/plus-black-icon.svg',
        path: '/visualization/create/feed-search',
        placement: 'main',
      },
      {
        label: "What's new",
        imgUrl: 'shared-assets/icons/gift-black-icon.svg',
        path: '/whats-new',
        placement: 'add-on',
      },
      {
        label: 'Suggest a source',
        imgUrl: 'shared-assets/icons/package-plus.svg',
        placement: 'add-on',
        execute: () => (this.showVisualizationRequestModal = true),
      },
      {
        label: 'Help',
        imgUrl: 'shared-assets/icons/help-circle.svg',
        placement: 'bottom',
        execute: () => (this.showHelpModal = true),
      },
      {
        label: 'Settings',
        imgUrl: 'shared-assets/icons/settings.svg',
        placement: 'bottom',
        execute: () => (this.showSettingsModal = true),
      },
      {
        label: 'Logout',
        imgUrl: 'shared-assets/icons/logout.svg',
        placement: 'bottom',
        execute: () => (this.showLogoutConfirmation = true),
      },
    ]);
  }
}

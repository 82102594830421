import { formatDistanceToNow, isAfter, isBefore, isEqual } from 'date-fns';

export const getUTCDate = (dateStr: string | null): Date => {
  // TODO not working
  if (!dateStr) return null;
  const date = new Date(dateStr);

  const utcDate = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours()
  );

  return new Date(utcDate);
};

export const distanceToNow = (
  date: string | Date,
  addSuffix = true
): string => {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  return formatDistanceToNow(dateObj, {
    addSuffix,
  });
};

/** if first date is before than the second one */
export const isDateBefore = (
  date: number | Date,
  dateToCompare: number | Date
): boolean => isBefore(date, dateToCompare);

/** if first date is after than the second one */
export const isDateAfter = (
  date: number | Date,
  dateToCompare: number | Date
): boolean => isAfter(date, dateToCompare);

export const isDateEqual = (
  date1: number | Date,
  date2: number | Date
): boolean => isEqual(date1, date2);

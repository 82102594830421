import { CapitalizeFirstPipe } from './pipes/dom/capitalize-first.pipe';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EnvConfig, EnvConfigService } from './injectables/env-config';
import { DateDistanceToNowPipe } from './pipes/date/date-distance-to-now.pipe';
import { SanitizeUrlPipe } from './pipes/dom/dom-sanitizer.pipe';

@NgModule({
  declarations: [CapitalizeFirstPipe, DateDistanceToNowPipe, SanitizeUrlPipe],
  imports: [],
  exports: [CapitalizeFirstPipe, DateDistanceToNowPipe, SanitizeUrlPipe],
})
export class HGFrontCoreModule {
  public static forRoot(
    env: EnvConfig
  ): ModuleWithProviders<HGFrontCoreModule> {
    return {
      ngModule: HGFrontCoreModule,
      providers: [
        {
          provide: EnvConfigService,
          useValue: env,
        },
      ],
    };
  }
}

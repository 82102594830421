import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnInit {
  @Input()
  set active(value: boolean) {
    if (value !== undefined && value !== false) {
      this._active = true;
    } else {
      this._active = value;
    }
  }

  get active(): boolean {
    return this._active;
  }

  _active = false;

  @Input()
  title: string;

  constructor() {}

  ngOnInit(): void {}
}

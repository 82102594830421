import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-shared-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() header: Header;

  constructor() {}
}

type Header = {
  title: string;
  subtitle?: string;
};

import { organizationBuster } from '../constants/cache';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { EnvConfig, EnvConfigService } from '../injectables/env-config';
import {
  OrganizationDefaultValues,
  OrganizationDetails,
  OrganizationType,
  PartialOrganization,
  PartialOrganizations,
} from '../models/organization';
import { UserDetails } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  CONTROLLER_URL: string;

  constructor(
    private http: HttpClient,
    @Inject(EnvConfigService) private envConfig: EnvConfig
  ) {
    this.CONTROLLER_URL = `${this.envConfig.api_URL}/organization`;
  }

  @Cacheable({
    cacheBusterObserver: organizationBuster,
  })
  getList(): Observable<PartialOrganizations> {
    const url = this.CONTROLLER_URL;
    return this.http.get<PartialOrganizations>(url);
  }

  @Cacheable({
    cacheBusterObserver: organizationBuster,
  })
  getOne(organizationId: string): Observable<OrganizationDetails> {
    const url = `${this.CONTROLLER_URL}/${organizationId}`;
    return this.http.get<OrganizationDetails>(url);
  }

  getUserList(organizationId: string): Observable<UserDetails[]> {
    const url = `${this.CONTROLLER_URL}/${organizationId}/users`;
    return this.http.get<UserDetails[]>(url);
  }

  @CacheBuster({
    cacheBusterNotifier: organizationBuster,
  })
  create(payload: OrganizationPOST): Observable<PartialOrganization> {
    const url = this.CONTROLLER_URL;
    return this.http.post<PartialOrganization>(url, payload);
  }

  @CacheBuster({
    cacheBusterNotifier: organizationBuster,
  })
  update(
    organizationId: string,
    payload: OrganizationPUT
  ): Observable<OrganizationDetails> {
    const url = `${this.CONTROLLER_URL}/${organizationId}`;
    return this.http.put<OrganizationDetails>(url, payload);
  }

  @CacheBuster({
    cacheBusterNotifier: organizationBuster,
  })
  delete(organizationId: string): Observable<void> {
    const url = `${this.CONTROLLER_URL}/${organizationId}`;
    return this.http.delete<void>(url);
  }
}

export type OrganizationPOST = {
  datafeeds?: string[];
  default_values?: OrganizationDefaultValues;
  name: string;
  subscription_tier?: string;
  type?: OrganizationType;
};

export type OrganizationPUT = {
  datafeeds: string[];
  default_values: OrganizationDefaultValues;
};

import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AngularResizeEventModule } from 'angular-resize-event';
import { NgZorroModule } from '../ng-zorro/ng-zorro.module';
import { UIComponentsModule } from '../ui-components/ui-components.module';
import { AvatarBlockComponent } from './components/blocks/avatar-block/avatar-block.component';
import { BarlineBlockComponent } from './components/blocks/barline-block/barline-block.component';
import { BlockComponent } from './components/blocks/block/block.component';
import { FigureblockComponent } from './components/blocks/figureblock/figureblock.component';
import { GeovizBlockComponent } from './components/blocks/geoviz-block/geoviz-block.component';
import { GraphBlockComponent } from './components/blocks/graph-block/graph-block.component';
import { GroupedBarchartBlockComponent } from './components/blocks/grouped-barchart-block/grouped-barchart-block.component';
import { HeatmapGridBlockComponent } from './components/blocks/heatmap-grid-block/heatmap-grid-block.component';
import { HorizontalBarchartBlockComponent } from './components/blocks/horizontal-barchart-block/horizontal-barchart-block.component';
import { MultilineBlockComponent } from './components/blocks/multiline-block/multiline-block.component';
import { PercentPillBlockComponent } from './components/blocks/percent-pill-block/percent-pill-block';
import { PieChartBlockComponent } from './components/blocks/pie-chart-block/pie-chart-block.component';
import { PillBlockComponent } from './components/blocks/pill-block/pill-block.component';
import { PointMapBlockComponent } from './components/blocks/point-map-block/point-map-block.component';
import { StackedBarchartBlockComponent } from './components/blocks/stacked-barchart-block/stacked-barchart-block.component';
import {
  TableBlockComponent,
  TableSortableHeader,
} from './components/blocks/table-block/table-block.component';
import { TextPillBarBlockComponent } from './components/blocks/text-pill-block/text-pill-block.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { LoginComponent } from './components/login/login.component';
import {
  EllipsisText,
  FooterComponent,
} from './components/partials/footer/footer.component';
import { HeaderComponent } from './components/partials/header/header.component';
import { TakeawayBoxComponent } from './components/partials/takeaway-box/takeaway-box.component';
import { SmartTilesComponent } from './components/smart-tiles/smart-tiles.component';
import { TabbedMapTableTemplateComponent } from './components/tabbed-map-table-template/tabbed-map-table-template.component';
import { UIControlComponent } from './components/uicontrol/uicontrol.component';
import { UIErrorHandler } from './handlers/ui-error.handler';
import { AbsolutePipe } from './pipes/absolute.pipe';
import { BigValuePercentagePipe } from './pipes/big-value-percentage.pipe';
import { MultipartiteGraphBlockComponent } from './components/blocks/multipartite-graph-block/multipartite-graph-block.component';
import { SimplelineBlockComponent } from './components/blocks/simpleline-block/simpleline-block.component';

@NgModule({
  declarations: [
    AbsolutePipe,
    BigValuePercentagePipe,
    TabbedMapTableTemplateComponent,
    AvatarBlockComponent,
    StackedBarchartBlockComponent,
    GroupedBarchartBlockComponent,
    BarlineBlockComponent,
    TableBlockComponent,
    TableSortableHeader,
    GeovizBlockComponent,
    MultilineBlockComponent,
    PercentPillBlockComponent,
    TextPillBarBlockComponent,
    HorizontalBarchartBlockComponent,
    GraphBlockComponent,
    FigureblockComponent,
    PillBlockComponent,
    PointMapBlockComponent,
    HeatmapGridBlockComponent,
    PieChartBlockComponent,
    HeaderComponent,
    FooterComponent,
    EllipsisText,
    TakeawayBoxComponent,
    LoginComponent,
    PieChartBlockComponent,
    BlockComponent,
    UIControlComponent,
    SmartTilesComponent,
    ErrorDialogComponent,
    MultipartiteGraphBlockComponent,
    SimplelineBlockComponent,
  ],
  imports: [
    CommonModule,
    UIComponentsModule,
    MDBBootstrapModule,
    AngularResizeEventModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatIconModule,
    MatSliderModule,
    MatDialogModule,
    MatSelectModule,
    MatButtonModule,
    NgZorroModule,
    ReactiveFormsModule,
  ],
  exports: [
    TabbedMapTableTemplateComponent,
    HeaderComponent,
    FooterComponent,
    TakeawayBoxComponent,
    LoginComponent,
    BigValuePercentagePipe,
    BlockComponent,
    UIControlComponent,
    SmartTilesComponent,
  ],
  providers: [{ provide: ErrorHandler, useClass: UIErrorHandler }],
})
export class SharedUICoreModule {}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from 'hg-front-core';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  showVisualizationRequestModal = false;
  showHelpModal = false;
  showSettingsModal = false;
  showLogoutConfirmation = false;

  menuItems: MobileMenuItems = [
    {
      label: 'Discover',
      iconUrl: 'shared-assets/icons/rss.svg',
      path: '/home',
    },
    {
      label: 'Workspace',
      iconUrl: 'shared-assets/icons/grid.svg',
      path: '/workspace',
    },
    {
      label: 'Build',
      iconUrl: 'shared-assets/icons/plus-black-icon.svg',
      path: '/visualization/create',
    },
    {
      label: "What's new",
      iconUrl: 'shared-assets/icons/gift-black-icon.svg',
      path: '/whats-new',
    },
    {
      label: 'Suggest a source',
      iconUrl: 'shared-assets/icons/package-plus.svg',
      execute: (): boolean => (this.showVisualizationRequestModal = true),
    },
    {
      label: 'Help',
      iconUrl: 'shared-assets/icons/help-circle.svg',
      execute: (): boolean => (this.showHelpModal = true),
    },
    {
      label: 'Settings',
      iconUrl: 'shared-assets/icons/settings.svg',
      execute: (): boolean => (this.showSettingsModal = true),
    },
    {
      label: 'Logout',
      iconUrl: 'shared-assets/icons/logout.svg',
      execute: (): boolean => (this.showLogoutConfirmation = true),
    },
  ];

  constructor(private analytics: AnalyticsService, private router: Router) {}

  menuItemClicked(menuItem: MobileMenuItem): void {
    this.analytics.dhEvent('menu-item-click', 'mobile-menu', {
      'menu-label': menuItem.label,
    });
    if (menuItem.path) {
      this.router.navigate([`${menuItem.path}`]);
    } else {
      menuItem.execute();
    }
  }

  ngOnInit(): void {}
}

type MobileMenuItems = MobileMenuItem[];
type MobileMenuItem = {
  label: string;
  disabled?: boolean;
  iconUrl?: string;
  unavailable?: boolean;
  path?: string;
  execute?: () => void;
};

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AnalyticsService, AuthService } from 'hg-front-core';

@Component({
  selector: 'app-confirm-logout-modal',
  templateUrl: './confirm-logout-modal.component.html',
  styleUrls: ['./confirm-logout-modal.component.scss'],
})
export class ConfirmLogoutModalComponent implements OnInit {
  @Output() onCloseModal? = new EventEmitter<void>();
  constructor(
    private authService: AuthService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {}

  onClickClose(): void {
    this.analytics.dhEvent('not-logout', 'confirm-logout-modal');
    this.onCloseModal.emit();
  }

  onClickLogout(): void {
    this.analytics.dhEvent('logout', 'confirm-logout-modal');
    this.authService.logout();
  }
}

import { Component, Input, OnDestroy, OnInit, SkipSelf } from '@angular/core';
import * as d3 from 'd3';
import {
  AnalyticsService,
  Block,
  BlockDataPointMap,
  BlockPointMap,
  ColorSchemeCategorical,
} from 'hg-front-core';
import { BaseChartBlockComponent } from '../../../components/blocks/base-chart-block/base-chart-block.component';
import { AppService, GraphSize } from '../../../services/app.service';
import { LeafletService } from '../../../services/leaflet.service';

@Component({
  selector: 'app-point-map-block',
  templateUrl: './point-map-block.component.html',
  styleUrls: ['./point-map-block.component.scss'],
})
export class PointMapBlockComponent
  extends BaseChartBlockComponent
  implements OnInit, OnDestroy
{
  _block: Block;
  @Input()
  set block(data: Block) {
    this._block = data;
    this.config = <BlockPointMap>this._block.config;
    this.draw();
  }

  get block(): Block {
    return this._block;
  }

  _size: GraphSize;
  @Input()
  set size(size: GraphSize) {
    this._size = size;
    this.draw();
  }
  get size(): GraphSize {
    return this._size;
  }

  graphSize: GraphSize;
  dataObj: any;
  data: any[];
  map;
  outlierBounds;
  isMobile: boolean = null;
  tip;
  colorScheme: any;

  constructor(
    @SkipSelf() public appService: AppService,
    private analayticsService: AnalyticsService,
    private leafletService: LeafletService
  ) {
    super(appService);
  }

  draw(): void {
    if (this.size) {
      this.drawChart();
      const containerBox = document.getElementById('mapid');

      this.drawLegend();
      this.drawCircles(containerBox);

      this.map.invalidateSize(true);
    }
  }

  drawChart(): void {
    this.config = <BlockPointMap>this.config;
    d3.selectAll('.d3-tip').remove();
    d3.selectAll('#mapid > *').remove();
    const self = this; // This is used bc "this" is not accessible in the D3 callback function
    const relative = this.config.relative;
    const ga = this.analayticsService;
    const isMobile = this.isMobile;
    const coloring = this.block.color_scheme;
    const priorityCategory = 'none';
    const scheme = coloring.type;
    const tooltipLabels = this.config.tooltips;
    let bounds = [
      [this.config.bounds.nw_latitude, this.config.bounds.nw_longitude],
      [this.config.bounds.se_latitude, this.config.bounds.se_longitude],
    ];
    const basemap = this.config.basemap;
    const tooltipShown = this.appService.tooltipShown;
    let clickFlag = false;

    this.leafletService.L.Control.prototype._refocusOnMap =
      function _refocusOnMap(ev) {
        if (this._map && ev && ev.screenX > 0 && ev.screenY > 0) {
          this._map.getContainer().focus({ preventScroll: true });
        }
      };

    let latLngs;

    if (this.config.data.length == 0) {
      // default bounds
      latLngs = bounds.map((d) => self.leafletService.L.latLng(d[0], d[1]));
      bounds = this.leafletService.L.latLngBounds(latLngs);
    } else {
      latLngs = this.config.data.map((d) =>
        self.leafletService.L.latLng(d.latitude, d.longitude)
      );
      bounds = this.leafletService.L.latLngBounds(latLngs);
    }

    if (this.map) {
      //check if map is initiated
      this.map.remove();
    }

    const map = this.leafletService.L.map('mapid', {
      scrollWheelZoom: true,
      zoomControl: true,
      keyboard: false,
    }).fitBounds(bounds, true);

    this.map = map;

    if (basemap == 'natural_landmark') {
      this.leafletService.L.tileLayer(
        'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
        {
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
        }
      ).addTo(map);
    } else {
      this.leafletService.L.tileLayer(
        'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
        {
          attribution:
            "Map data © <a href='http://openstreetmap.org'>OpenStreetMap</a>",
        }
      ).addTo(map);
    }

    this.leafletService.L.svg({ clickable: true }).addTo(map);
    const overlay = d3.select(map.getPanes().overlayPane);
    const svg = overlay.select('svg').attr('pointer-events', 'auto');

    // need testing
    if (this.config.data.length == 0) {
      this.drawUnavailableMap(this.map);
      return;
    }

    //The below if currently dead code and needs to be fixed
    let minNum, c, maxNum: number;
    const sizeScale = d3.scaleLinear().domain([minNum, maxNum]).range([1, c]);

    const vals = this.config.data.map((d) => <number>d.value);
    this.colorScheme = this.createColorScheme(vals);

    const tip = this.createMapTooltip(tooltipLabels, 'name');

    this.tip = tip;
    svg.call(tip);

    const circles = svg
      .selectAll('myCircles')
      .data(this.config.data)
      .enter()
      .append('circle')
      .attr('class', function (d) {
        if (d.value == priorityCategory) {
          return 'priority svg-circle';
        }
        return 'svg-circle';
      })
      .attr('cx', function (d) {
        return map.latLngToLayerPoint([d.latitude, d.longitude]).x;
      })
      .attr('cy', function (d) {
        return map.latLngToLayerPoint([d.latitude, d.longitude]).y;
      })
      .attr('r', function (d) {
        if (scheme != 'categorical') {
          if (relative) {
            if (d.value > maxNum) {
              return c + 5;
            } else {
              return sizeScale(<number>d.value);
            }
          } else {
            return 5;
          }
        } else {
          return 5;
        }
      })
      .style('fill', (d) => self.colorScheme(d.value))
      .attr('fill-opacity', basemap == 'natural_landmark' ? 0.85 : 0.75)

      .on('mouseover', function (d) {
        d3.select(this).style('stroke', (d) =>
          self.colorScheme((<BlockDataPointMap>d).value)
        );
      })
      .on('mouseout', function (d) {
        if (!isMobile && !clickFlag) {
          tip.hide(d, this);
          tooltipShown.emit(false);
          d3.select(this).style('stroke', 'none');
        }
      })
      .on('click', function (d) {
        tip.show(d, this);
        tooltipShown.emit(true);
        ga.blockEventEmitter(
          'Interaction',
          'interaction',
          globalThis.vizCollection +
            '/' +
            globalThis.vizType +
            '/' +
            'pointMap',
          null,
          0,
          {
            InteractionType: 'click',
            Collection: globalThis.vizCollection,
            vizType: globalThis.vizType,
            block: 'pointMap',
          },
          true
        );
        clickFlag = !clickFlag;
      });

    if (!isMobile) {
      svg.on('click', function (d) {
        if (!d3.select(d3.event.target).classed('svg-circle')) {
          tip.hide();
          tooltipShown.emit(false);
          clickFlag = !clickFlag;
          circles.style('stroke', 'none');
        }
      });
    }
    d3.selectAll('.priority').raise().attr('fill-opacity', 0.9);

    if (isMobile) {
      //mouseover doesn't work on Leaflet mobile, switch to click
      svg.on('click', function (d) {
        if (d3.select(d3.event.target).classed('svg-circle')) {
          d3.select(d3.event.target).style('stroke', (d) =>
            self.colorScheme((<BlockDataPointMap>d).value)
          );
        } else {
          tip.hide();
          circles.style('stroke', 'none');
        }
      });
    }

    function update() {
      //update circle position if window resizes
      circles
        .attr('cx', (d) => map.latLngToLayerPoint([d.latitude, d.longitude]).x)
        .attr('cy', (d) => map.latLngToLayerPoint([d.latitude, d.longitude]).y);
      tip.hide();
    }

    map.on('zoomend', update);
    map.on('moveend', update);
  }

  drawLegend(): void {
    const coloring = this.block.color_scheme;
    this.config = <BlockPointMap>this.config;
    if (coloring.type == 'categorical') {
      const legendKeys = this.block.categories.map(function (d, i) {
        return {
          label: d,
          color: (<ColorSchemeCategorical>coloring.config).colors[i],
        };
      });
      this.drawHorizontalLegend(legendKeys, this.size.width, true);
    } else if (this.config.data.length > 0) {
      const values = this.config.data
        .map((d) => d.value)
        .sort(function (a: number, b: number) {
          return a - b;
        });
      if (
        coloring.type == 'divergent' ||
        coloring.type == 'graduated' ||
        coloring.type == 'threshold'
      ) {
        this.drawColorLegend(
          this.size.width,
          <number[]>values,
          this.colorScheme,
          this.config.legend.percent
        );
      } else if (coloring.type == 'range') {
        const legendKeys = [];
        this.block.color_scheme.options.range_cutoffs.forEach((range) =>
          legendKeys.push({
            label: range.name,
            color: this.colorScheme(range.value),
          })
        );
        this.drawHorizontalLegend(legendKeys, this.size.width, true);
      }
    }
  }

  drawCircles(containerBox: any): void {
    const self = this;
    const circles = d3.selectAll('.svg-circle');
    const tooltipShown = this.appService.tooltipShown;
    //change tooltip direction when at edge
    circles.on('mouseover', function (d) {
      const position = d3.mouse(containerBox);
      const direction = self.getTooltipDirection(
        position[0],
        self.size.width,
        self.isMobile
      );
      self.tip.direction(direction).show(d, this);
      tooltipShown.emit(true);
      self.analayticsService.blockEventEmitter(
        'Interaction',
        'interaction',
        globalThis.vizCollection + '/' + globalThis.vizType + '/' + 'pointMap',
        null,
        0,
        {
          InteractionType: 'mouseOver',
          Collection: globalThis.vizCollection,
          vizType: globalThis.vizType,
          block: 'pointMap',
        },
        true
      );
    });
    if (this.isMobile) {
      circles.on('click', function (d) {
        const position = d3.mouse(containerBox);
        const direction = self.getTooltipDirection(
          position[0],
          self.size.width,
          self.isMobile
        );
        self.tip.direction(direction).show(d, this);
        tooltipShown.emit(true);
        self.analayticsService.blockEventEmitter(
          'Interaction',
          'interaction',
          globalThis.vizCollection +
            '/' +
            globalThis.vizType +
            '/' +
            'pointMap',
          null,
          0,
          {
            InteractionType: 'mouseOver',
            Collection: globalThis.vizCollection,
            vizType: globalThis.vizType,
            block: 'pointMap',
          },
          true
        );
      });
    }
  }

  ngOnDestroy(): void {
    this.tip?.hide();
  }
}

import { Component, OnInit, SkipSelf } from '@angular/core';
import * as d3 from 'd3';
import { BlockSegment, ColorSchemeCategorical } from 'hg-front-core';
import { SegmentBlockComponent } from '../segment-block/segment-block.component';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'app-horizontal-barchart-block',
  templateUrl: './horizontal-barchart-block.component.html',
  styleUrls: ['./horizontal-barchart-block.component.scss'],
})
export class HorizontalBarchartBlockComponent
  extends SegmentBlockComponent
  implements OnInit
{
  margins = { top: 50, right: 50, bottom: 30, left: 200 };

  constructor(@SkipSelf() public appService: AppService) {
    super(appService);
  }

  drawChart(): void {
    if (
      !this.block ||
      !this.size ||
      this.size.width === 0 ||
      this.size.height === 0
    )
      return;

    this.config = <BlockSegment>this.config;
    d3.selectAll('#SVG > *').remove();
    const svg = d3.select('#SVG');
    const width = this.size.width;
    const height = this.size.height;
    svg.attr('width', width).attr('height', height);
    const margin = { top: 0, right: 50, bottom: 20, left: 130 };

    const gridLines = true; //this.dataObj.series.gridlines;
    const percent = this.config.charts[0].percent;
    const dollars = this.config.charts[0].dollars;
    const color = (<ColorSchemeCategorical>this.block.color_scheme.config)
      .colors[0];
    const textColor = '#F1F0FE';
    const rounded = false; //this.dataObj.series.rounded !== false;
    if (this.config.horizontal_bar_options.sort) {
      this.config.charts[0].data = this.config.charts[0].data.sort(
        (a, b) => a.value - b.value
      );
    } else {
      this.config.charts[0].data = this.config.charts[0].data.sort((a, b) => {
        if (a.category < b.category) {
          return -1;
        }
        if (a.category > b.category) {
          return 1;
        }
        return 0;
      });
    }
    const keys = this.config.charts[0].data.map((e) => e.category);
    const yScale = d3
      .scaleBand()
      .domain(keys.reverse())
      .range([height - margin.bottom, margin.top])
      .padding(0.1);
    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(this.config.charts[0].data, (d) => d.value)])
      .range([0, width - margin.right - margin.left]);

    //set font size
    let fontSize = 16;
    if (height <= 400) fontSize = 15;

    svg
      .append('g')
      .attr('class', 'axis')
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(yScale).tickSize(0))

      .selectAll('.tick text')
      .attr('font-family', 'Lato, sans-serif')
      .attr('font-size', fontSize + 'px')
      .style('fill', '#525B86')
      .attr('alignment-baseline', 'middle')
      .style('text-anchor', 'start')
      .call(wrap, margin.left - 10);

    const yAxis = d3.axisLeft(yScale).tickSize(-width);

    const extent = d3.max(this.config.charts[0].data, (d) => d.value);

    function make_x_gridlines() {
      return d3.axisBottom(xScale);
    }

    const h = height - margin.top - margin.bottom;

    if (gridLines) {
      svg
        .append('g')
        .attr('class', 'grid axisLabels')
        .call(
          make_x_gridlines()
            .tickSize(-h)
            .tickFormat('' as any)
        );
    } else {
      svg
        .append('g')
        .attr('class', 'axis axisLabels')
        .attr('transform', `translate(0, ${height - margin.bottom})`)
        .call(d3.axisBottom(xScale).tickSize(0));
      d3.selectAll('path').style('display', 'none');
    }

    d3.selectAll('.grid')
      .attr('transform', `translate(${margin.left}, ${h})`)
      .selectAll('path, line')
      .style('stroke', '#F0F0F0');

    let labelPadding = margin.right;
    let labelSize = 22;
    let rx = yScale.bandwidth() / 2;
    if (width <= 600) {
      labelPadding = 5;
      labelSize = 15;
      if (width <= 400) {
        labelSize = 13;
        rx = yScale.bandwidth() * 0.4;
      }
    } else if (this.config.charts[0].data.length > 6) labelSize = 20;

    svg
      .selectAll('rect')
      .data(this.config.charts[0].data)
      .enter()
      .append('rect')
      .attr('x', margin.left)
      .attr('y', (d) => yScale(d.category))
      .attr('width', (d) => xScale(d.value))
      .attr('height', yScale.bandwidth())
      .attr('fill', color)
      .attr('rx', rounded ? rx : 0);

    svg
      .selectAll('.label')
      .data(this.config.charts[0].data)
      .enter()
      .append('text')
      .attr('x', (d) => {
        const xVal = xScale(d.value);
        if (xVal > 200) {
          return xVal - labelPadding + margin.left;
        } else {
          return xVal + labelPadding / 2 + margin.left;
        }
      })
      .attr('y', (d) => yScale(d.category) + yScale.bandwidth() / 2)
      .text(function (d) {
        return d3.format(',')(d.value) + '%';
      })
      .text(function (d) {
        let val = percent
          ? d3.format(',.1%')(d.value)
          : d3.format(',')(d.value);
        val = dollars ? '$' + val : val;
        return val;
      })
      .attr('font-family', 'Lato, sans-serif')
      .attr('fill', function (d) {
        if (xScale(d.value) > 200) {
          return textColor;
        } else {
          return '#525B86';
        }
      })
      .attr('font-size', labelSize + 'px')
      .attr('font-weight', 'bold')
      .attr('text-anchor', function (d) {
        if (xScale(d.value) > 200) {
          return 'end';
        } else {
          return 'start';
        }
      })
      .attr('dominant-baseline', 'central');

    //modified Mike Bostock function for wrapping long y-axis labels
    function wrap(text, width) {
      text.each(function () {
        const text = d3.select(this);
        let textContent = text.text();
        textContent = concatenateString(textContent, yScale.bandwidth());

        const tempWord = addBreakSpace(textContent).split(/\s+/),
          x = text.attr('x'),
          y = text.attr('y'),
          dy = parseFloat((text as any).attr('dy') || 0);

        let tspan = text
          .text(null)
          .append('tspan')
          .attr('x', x)
          .attr('y', y)
          .attr('dy', dy + 'em');
        for (let i = 0; i < tempWord.length; i++) {
          tempWord[i] = calHyphen(tempWord[i]);
        }
        textContent = tempWord.join(' ');

        let words = textContent.split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 0.9, // ems
          spanContent,
          breakChars = ['/', '&', '-'];

        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(' '));
          if (tspan.node().getComputedTextLength() > width - 8) {
            line.pop();
            spanContent = line.join(' ');
            breakChars.forEach(function (char) {
              // Remove spaces trailing breakChars that were added above
              spanContent = spanContent.replace(char + ' ', char);
            });
            tspan.text(spanContent);
            line = [word];
            lineNumber = lineNumber + 1;

            tspan = text
              .append('tspan')
              .attr('x', x)
              .attr('y', y)
              .attr('dy', lineHeight + dy + 'em')
              .text(word);
          }
        }
        const emToPxRatio = parseInt(
          window
            .getComputedStyle((text as any)._groups[0][0])
            .fontSize.slice(0, -2)
        );
        text.attr(
          'transform',
          'translate(-' +
            (margin.left - 13) +
            ', -' +
            (lineNumber / 2) * lineHeight * emToPxRatio +
            ')'
        );

        function concatenateString(textInput, height) {
          let cutOff: number;
          if (height > 90) {
            cutOff = 75;
          } else if (height < 90 && height > 80) {
            cutOff = 45;
          } else if (height > 65 && height < 80) {
            cutOff = 35;
          } else {
            cutOff = 20;
          }
          if (textInput.length > cutOff) {
            return textInput.substring(0, cutOff) + '...';
          } else {
            return textInput;
          }
        }
        function calHyphen(word) {
          tspan.text(word);
          if (tspan.node().getComputedTextLength() > width) {
            const chars = word.split('');
            let asword = '';
            for (let i = 0; i < chars.length; i++) {
              asword += chars[i];
              tspan.text(asword);
              if (tspan.node().getComputedTextLength() > width) {
                if (chars[i - 1] !== '-') {
                  word =
                    word.slice(0, i - 1) + '- ' + calHyphen(word.slice(i - 1));
                }
                i = chars.length;
              }
            }
          }
          return word;
        }
      });

      function addBreakSpace(inputString) {
        const breakChars = ['/', '&', '-'];
        breakChars.forEach(function (char) {
          // Add a space after each break char for the function to use to determine line breaks
          inputString = inputString.replace(char, char + ' ');
        });
        return inputString;
      }
    }
  }
}

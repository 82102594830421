import { Observable } from 'rxjs';
import { publishReplay, refCount, take } from 'rxjs/operators';

export class CacheService<T> {
  private byIdMap: Record<CacheKey, Observable<T>> = {};

  constructor(private loader: (id: CacheKey) => Observable<T>) {}

  byId(id: CacheKey): Observable<T> {
    if (!this.byIdMap[id]) {
      this.byIdMap[id] = this.loader(id).pipe(
        publishReplay(),
        refCount(),
        take(1)
      );
    }
    return this.byIdMap[id];
  }

  bustCache(id: CacheKey): void {
    delete this.byIdMap[id];
  }
}

type CacheKey = string | number;

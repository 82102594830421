import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'largevalue',
})
export class BigValuePercentagePipe implements PipeTransform {
  nFormatter(num, digits): any {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });

    return item
      ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
      : num;
  }

  transform(data: any): any {
    let dollarFlag = false;
    let percentageFlag = false;
    const startString = data;
    let value: number;

    if (startString[0] === '$') {
      dollarFlag = true;
      value = parseFloat(data.substring(1).replace(/,/g, ''));
    } else if (startString[startString.length - 1] === '%') {
      percentageFlag = true;
      value = parseFloat(data.replace(/,/g, ''));
    }

    if (dollarFlag) {
      if (value < 10) {
        return '$' + this.nFormatter(value, 2);
      } else if (value >= 1000000) {
        return '$' + this.nFormatter(value, 2);
      }
      return '$' + this.nFormatter(value, 1);
    } else if (percentageFlag) {
      if (value < 0) {
        return '-' + this.nFormatter(-1 * value, 1) + '%';
      }

      return this.nFormatter(value, 1) + '%';
    }
    return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cacheable } from 'ts-cacheable';
import { ONE_HOUR } from '../constants/cache';
import { EnvConfig, EnvConfigService } from '../injectables/env-config';
import { PartialSource, PartialSources } from '../models/PartialSource';

@Injectable({
  providedIn: 'root',
})
export class SourceService {
  CONTROLLER_URL: string;

  constructor(
    private http: HttpClient,
    @Inject(EnvConfigService) private envConfig: EnvConfig
  ) {
    this.CONTROLLER_URL = `${this.envConfig.api_URL}/source`;
  }

  @Cacheable({
    maxAge: ONE_HOUR,
  })
  getList(): Observable<PartialSources> {
    const url = this.CONTROLLER_URL;
    return this.http.get<PartialSources>(url);
  }

  update(id: string, body: SourceUpdateBody): Observable<PartialSource> {
    const url = `${this.CONTROLLER_URL}/${id}`;
    return this.http.put<PartialSource>(url, body);
  }
}

type SourceUpdateBody = {
  last_updated: string;
  tables: string[];
};

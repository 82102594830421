import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cacheable } from 'ts-cacheable';
import { ONE_HOUR } from '../constants/cache';
import { EnvConfig, EnvConfigService } from '../injectables/env-config';
import { GeoData } from './../models/GeoData';

@Injectable({
  providedIn: 'root',
})
export class GeoDataService {
  CONTROLLER_URL: string;

  constructor(
    private http: HttpClient,
    @Inject(EnvConfigService) private envConfig: EnvConfig
  ) {
    this.CONTROLLER_URL = `${this.envConfig.api_URL}/geo`;
  }

  @Cacheable({
    maxAge: ONE_HOUR,
  })
  getCounties(state_fips: string): Observable<GeoData> {
    const url = `${this.CONTROLLER_URL}/counties`;
    const params = {
      state_fips,
    };
    return this.http.get<GeoData>(url, { params });
  }

  @Cacheable({
    maxAge: ONE_HOUR,
  })
  getStates(
    params: { exclude_territories?: boolean } = {}
  ): Observable<GeoData> {
    const url = `${this.CONTROLLER_URL}/states`;
    return this.http.get<GeoData>(url, { params });
  }

  @Cacheable({
    maxAge: ONE_HOUR,
  })
  getSuggestions(query: string): Observable<GeoData> {
    const url = `${this.CONTROLLER_URL}/suggestions`;
    const params = {
      query,
    };
    return this.http.get<GeoData>(url, { params });
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AnalyticsService,
  AuthService,
  AuthState,
  AuthStateAndMessage,
  AuthType,
  EAuthState,
  GeoDataService,
  GeoDetails,
  LoggedInUserService,
  OrganizationDetails,
  OrganizationService,
  UserDetails,
} from 'hg-front-core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ViewOption } from '../../../ng-zorro/models/ViewOption';
import { JOB_INDUSTRIES } from '../../constants/form-options';
import { generatePwdArrCopy, updatePwdArr } from './password-strength-utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @Input() applicationName = 'Dataherald';
  @Input() subtitle = 'Sign in to continue';
  @Input()
  set backoffice(value: boolean) {
    if (value !== undefined && value !== false) {
      this._backoffice = true;
    } else {
      this._backoffice = value;
    }
  }

  _backoffice = false;

  @Output() onSuccessfulLogin = new EventEmitter<LoginAnalyticEvent>();

  readonly EAuthState = EAuthState;

  states: ViewOption<GeoDetails>[] = [];
  counties: ViewOption[] = [];
  jobIndustries: ViewOption[] = JOB_INDUSTRIES.map((industry) => ({
    label: industry,
    value: industry,
  }));

  validateForm: FormGroup;
  passwordStrengthChecks = generatePwdArrCopy();
  isValidPassword = false;

  user: UserDetails;
  userOrganization: OrganizationDetails;
  isFormUser: boolean;
  isGoogleUser: boolean;
  authState: AuthState;
  authErrorMessage: string;
  loading = true;

  private authStateSub: Subscription;

  constructor(
    public mediaObserver: MediaObserver,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private loggedInUserService: LoggedInUserService,
    private organizationService: OrganizationService,
    private geoDataService: GeoDataService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.authStateSub = this.authService.state$.subscribe(
      (authState: AuthStateAndMessage) => {
        this.authState = authState.state;
        if (this.authState === EAuthState.AUTHORIZED) {
          this.loggedInUserService.user$
            .pipe(take(1))
            .subscribe((user: UserDetails) => {
              this.user = user;
              if (this.user.finished_setup) {
                this.onSuccessfulLogin.emit(
                  this.getUserAnalytics(this.user, false)
                );
                return;
              } else {
                this.logEvent(
                  'load-finish-setup',
                  this.getUserAnalytics(this.user, true)
                );
                let controlConfigs: { [key: string]: any } = {};
                this.isFormUser = this.user.auth_type === 'username_password';
                this.isGoogleUser = this.user.auth_type === 'google_oauth';
                if (this.isGoogleUser) {
                  if (!this.user.trial_account) {
                    // org google users are already setup
                    this.loggedInUserService
                      .updateUserInfo(this.user.id, { finished_setup: true })
                      .subscribe((updatedUser) =>
                        this.onSuccessfulLogin.emit(
                          this.getUserAnalytics(updatedUser, false)
                        )
                      );
                    return;
                  } else {
                    // trial google users need to input their location for his own org
                    controlConfigs = {
                      firstName: [null],
                      lastName: [null],
                      state: [null, [Validators.required]],
                      county: [null, [Validators.required]],
                      job_details: this.fb.group({
                        industry: [null, [Validators.required]],
                        company: [null, [Validators.required]],
                        title: [null, [Validators.required]],
                      }),
                      password: [null],
                      confirmPassword: [null],
                    };
                    this.loadLocations();
                  }
                } else {
                  // form users
                  if (!this.user.trial_account) {
                    // org form users can't change org location nor need to load job details.
                    controlConfigs = {
                      firstName: [null, [Validators.required]],
                      lastName: [null, [Validators.required]],
                      state: [null],
                      county: [null],
                      job_details: this.fb.group({
                        industry: [null],
                        company: [null],
                        title: [null],
                      }),
                      password: [null, [Validators.required]],
                      confirmPassword: [null, [Validators.required]],
                    };
                  } else {
                    // trial form users need to complete everything
                    controlConfigs = {
                      firstName: [null, [Validators.required]],
                      lastName: [null, [Validators.required]],
                      state: [null, [Validators.required]],
                      county: [null, [Validators.required]],
                      job_details: this.fb.group({
                        industry: [null, [Validators.required]],
                        company: [null, [Validators.required]],
                        title: [null, [Validators.required]],
                      }),
                      password: [null, [Validators.required]],
                      confirmPassword: [null, [Validators.required]],
                    };
                    this.loadLocations();
                  }
                }
                this.validateForm = this.fb.group(controlConfigs);
                this.loading = false;
              }
            });
        } else if (this.authState === EAuthState.UNAUTHORIZED) {
          this.authService.login();
        } else {
          this.authErrorMessage = authState.message;
        }
      }
    );
  }

  onSubmitSetupForm(): void {
    if (this.validateForm.invalid) {
      this.triggerValidations(this.validateForm);
      return;
    }
    if (this.isFormUser && !this.isValidPassword) return;

    let userRequestBody = {};
    if (this.user.trial_account) {
      // add job details to save
      userRequestBody = {
        job_details: this.validateForm.controls.job_details.value,
      };
      // save location
      const userOrgRequestBody = {
        ...this.userOrganization,
        default_values: {
          ...this.userOrganization.default_values,
          state: this.validateForm.controls.state.value.name,
          county: this.validateForm.controls.county.value,
        },
      };
      this.organizationService
        .update(this.userOrganization.id, userOrgRequestBody)
        .subscribe();
    }
    userRequestBody = this.isGoogleUser
      ? {
          ...userRequestBody,
          finished_setup: true,
        }
      : {
          ...userRequestBody,
          first_name: this.validateForm.controls.firstName.value,
          last_name: this.validateForm.controls.lastName.value,
          password: this.validateForm.controls.password.value,
          finished_setup: true,
        };
    this.loggedInUserService
      .updateUserInfo(this.user.id, userRequestBody)
      .subscribe((updatedUser) =>
        this.onSuccessfulLogin.emit(this.getUserAnalytics(updatedUser, true))
      );
  }

  updateCounties(state: GeoDetails): void {
    this.validateForm.controls.county.setValue(null);
    this.validateForm.controls.county.updateValueAndValidity();
    this.geoDataService.getCounties(state.state_fips).subscribe(
      (geoData) =>
        (this.counties = geoData.map((geoDetail) => ({
          label: geoDetail.name,
          value: geoDetail.name,
        })))
    );
  }

  onUpdatePasswordInput(): void {
    const obj = updatePwdArr(
      this.passwordStrengthChecks,
      this.validateForm.controls.password.value,
      this.validateForm.controls.confirmPassword.value
    );
    this.passwordStrengthChecks = obj.arr;
    this.isValidPassword = obj.isPassing;
  }

  onClickContactSupport(): void {
    this.logEvent('contact-support');
    window.open('https://www.dataherald.com/contact', '_blank');
  }

  onClickSignOut(): void {
    this.logEvent('sign-out');
    this.authService.logout();
  }

  onClickAfterVerifyEmail(): void {
    this.logEvent('verify-email');
    this.authService.login();
  }

  ngOnDestroy(): void {
    this.authStateSub.unsubscribe();
  }

  private loadLocations(): void {
    this.geoDataService
      .getStates({ exclude_territories: true })
      .subscribe((geoData) => {
        this.states = geoData.map((geoDetail) => ({
          label: geoDetail.title,
          value: geoDetail,
        }));
      });
    this.organizationService
      .getOne(this.user.organization.id)
      .subscribe(
        (userOrganization) => (this.userOrganization = userOrganization)
      );
  }

  private triggerValidations(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.controls[key];

      if (
        abstractControl instanceof FormGroup ||
        abstractControl instanceof FormArray
      ) {
        this.triggerValidations(abstractControl);
      } else {
        abstractControl.markAsDirty();
        abstractControl.updateValueAndValidity({ onlySelf: true });
      }
    });
  }

  private logEvent(eventName: string, extraProperties = {}): void {
    if (this._backoffice) return;
    this.analyticsService.dhEvent(eventName, 'login', extraProperties);
  }

  private getUserAnalytics(
    user: UserDetails,
    fromFinishSetup: boolean
  ): LoginAnalyticEvent {
    return {
      'from-finish-setup': fromFinishSetup,
      'auth-type': user.auth_type,
      'user-email': user.email,
      'is-trial': user.trial_account,
      user,
    };
  }
}

export type LoginAnalyticEvent = {
  'from-finish-setup': boolean;
  'auth-type': AuthType;
  'user-email': string;
  'is-trial': boolean;
  user: UserDetails;
};

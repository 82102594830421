import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent {
  @Input()
  query: string;

  @Input()
  placeholder = 'Type something...';

  @Input()
  size: 'large' | 'default' | 'small' = 'default';

  @Input()
  color: 'neutral' | 'default' = 'default';

  @Output()
  queryChange = new EventEmitter<string>();

  @Output()
  onKeyUpEnter = new EventEmitter<void>();
}

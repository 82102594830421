<div class="content flex-row" *ngIf="data && data.length > 0">
  <div
    class="smart-tile-wrapper"
    *ngFor="let takeawayTile of data; let i = index"
  >
    <div
      class="smart-tile flex-col"
      [ngStyle]="{
        'box-shadow': takeawayTile.shadow
          ? '0px 2px 32px rgba(188,191,214,0.4)'
          : '0px'
      }"
    >
      <div class="flex-col non-graph">
        <div class="header">{{ takeawayTile.header }}</div>
        <div class="figure" [ngStyle]="{ color: mainColor }">
          {{
            isNumber(takeawayTile.figure)
              ? (takeawayTile.figure | number: "1.0-1":"en-US")
              : (takeawayTile.figure | largevalue)
          }}
        </div>
        <div class="unit">{{ takeawayTile.unit }}</div>
      </div>
      <div class="flex-row between non-graph">
        <div class="subheader">
          {{ takeawayTile.subheader }}
        </div>
        <div
          class="value-change flex-row non-graph"
          *ngIf="takeawayTile.value_change !== null"
          [ngClass]="{
            invert: takeawayTile.invert_color,
            negative: takeawayTile.value_change < 0,
            zero: takeawayTile.value_change == 0
          }"
        >
          <mat-icon matSuffix>
            {{
              takeawayTile.value_change < 0
                ? "arrow_downward"
                : takeawayTile.value_change == 0 ||
                  takeawayTile.value_change == null
                ? "horizontal_rule"
                : "arrow_upward"
            }}
          </mat-icon>
          {{ takeawayTile.value_change }}
          {{ takeawayTile.change_is_percentage ? "%" : "" }}
        </div>
        
      </div>
      <div
        (window:resize)="onResize()"
        class="small-graph graph-{{ i }}"
        [ngStyle]="{
          padding:
            takeawayTile.graph_type == 'percent_pill'
              ? '0px 16px 0px 24px'
              : '0px'
        }"
      ></div>
    </div>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { Block } from 'hg-front-core';
import { CHART_ICONS_DARK } from './../../constants/chart-types';

@Component({
  selector: 'app-tabbed-map-table-template',
  templateUrl: './tabbed-map-table-template.component.html',
  styleUrls: ['./tabbed-map-table-template.component.scss'],
})
export class TabbedMapTableTemplateComponent {
  // templateData structure must have following data structure
  // { graphData: { graph data }, tableData: [ list of table data ] }
  @Input()
  block: Block;

  @Input()
  enableInteractiveDialog = false;

  TAB_OPTIONS = TAB_OPTIONS;
  tabName: Tab = TAB_OPTIONS.CHART;

  CHART_ICONS = CHART_ICONS_DARK;

  setTab(selectedTab: Tab): void {
    this.tabName = selectedTab;
  }
}

enum TAB_OPTIONS {
  CHART = 'chart',
  TABLE = 'table',
}

type Tab = TAB_OPTIONS;

<div [ngSwitch]="controlData.type">
  <!-- DROPDOWN -->
  <div *ngSwitchCase="'dropdown'">
    <!-- MULTI SELECT DROPDOWN -->
    <mat-form-field
      appearance="standard"
      *ngIf="$any(controlData.config).max_choices > 1"
    >
      <mat-label *ngIf="$any(controlData.config).parameter">{{
        $any(controlData.config).title
      }}</mat-label>
      <mat-select
        (selectionChange)="userSelectionChanged()"
        [(ngModel)]="selectedMultiValue"
        floatlLabel="never"
        multiple
      >
        <mat-select-trigger>{{
          $any(controlData.config).title
        }}</mat-select-trigger>
        <mat-option
          *ngFor="let value of $any(controlData.config).options"
          [value]="value"
        >
          {{ value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- SINGLE SELECT DROPDOWN -->
    <mat-form-field
      appearance="standard"
      *ngIf="$any(controlData.config).max_choices == 1"
    >
      <mat-label *ngIf="$any(controlData.config).parameter">{{
        $any(controlData.config).title
      }}</mat-label>
      <mat-select
        (selectionChange)="userSelectionChanged()"
        [(ngModel)]="selectedValue"
        floatlLabel="never"
      >
        <mat-select-trigger>{{
          selectedValue ? selectedValue : $any(controlData.config).title
        }}</mat-select-trigger>
        <mat-option
          *ngFor="let value of $any(controlData.config).options"
          [value]="value"
        >
          {{ value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- NUMERIC SLIDER -->
  <div class="slider_wrapper" *ngSwitchCase="'numeric_slider'">
    <span class="slider_label">{{ $any(controlData.config).title }}</span>
    <nz-slider
      [nzMin]="$any(controlData.config).min"
      [nzMax]="$any(controlData.config).max"
      [nzStep]="$any(controlData.config).step"
      [(ngModel)]="selectedValueNumber"
      (nzOnAfterChange)="userSelectionChanged()"
    ></nz-slider>
  </div>

  <!-- RANGE SLIDER -->
  <div class="slider_wrapper" *ngSwitchCase="'range_slider'">
    <span class="slider_label">{{ $any(controlData.config).title }}</span>
    <nz-slider
      nzRange
      [nzMin]="$any(controlData.config).min"
      [nzMax]="$any(controlData.config).max"
      [nzStep]="$any(controlData.config).step"
      [(ngModel)]="selectedRange"
      (nzOnAfterChange)="userSelectionChanged()"
    ></nz-slider>
  </div>

  <!-- SEARCH BAR -->
  <mat-form-field *ngSwitchCase="'search'" class="search_bar_wrapper">
    <mat-label>{{ $any(controlData.config).title }}</mat-label>
    <input
      matInput
      #searchBox
      [(ngModel)]="selectedFilter"
      (input)="filterOn(searchBox.value)"
    />
    <button
      mat-button
      *ngIf="selectedFilter"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="resetSearch()"
    >
      <i nz-icon nzType="close"></i>
    </button>
  </mat-form-field>
</div>

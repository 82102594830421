import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import {
  AnalyticsService,
  LocalStorageService,
  VisualizationParametersRequest,
  VisualizationService,
} from 'hg-front-core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LOCAL_STORAGE_KEYS } from '../constants/storage-keys';

@Injectable({
  providedIn: 'root',
})
export class FirstLoginDemoFlowGuard implements CanActivate {
  constructor(
    private analytics: AnalyticsService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private visualizationAPIService: VisualizationService
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.localStorageService
      .getItem<VisualizationParametersRequest>(LOCAL_STORAGE_KEYS.VIZ_TO_SAVE)
      .pipe(
        switchMap((vizToSave) => {
          if (vizToSave) {
            const { vizId, parametersValues } = vizToSave;
            return this.visualizationAPIService
              .updateParameters(vizId, parametersValues)
              .pipe(
                switchMap(() => {
                  this.analytics.dhEvent(
                    'create-viz-from-demo',
                    'demo-flow-first-login',
                    {
                      'viz-id': vizId,
                    }
                  );
                  return this.localStorageService
                    .removeItem(LOCAL_STORAGE_KEYS.VIZ_TO_SAVE)
                    .pipe(map(() => this.router.parseUrl('/workspace')));
                })
              );
          }
          return of(true);
        })
      );
  }
}

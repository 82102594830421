import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CacheBuster } from 'ts-cacheable';
import { BACKOFFICE_ORG_IDS } from '../constants/backoffice';
import { visualizationCacheBuster } from '../constants/cache';
import { EnvConfig, EnvConfigService } from '../injectables/env-config';
import { UserDetails } from '../models/user';
import { AnalyticsService } from './analytics.service';
import { AuthUser } from './auth.service';
import { UserPUT, UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedInUserService implements OnDestroy {
  private currentUser: UserDetails = null;
  private currUserSubject = new ReplaySubject<UserDetails>(1);

  user$ = this.currUserSubject.asObservable();

  constructor(
    @Inject(EnvConfigService) private envConfig: EnvConfig,
    private userService: UserService,
    private analytics: AnalyticsService
  ) {}

  @CacheBuster({
    cacheBusterNotifier: visualizationCacheBuster,
  })
  updateUserInfo(userId: string, user: UserPUT): Observable<UserDetails> {
    return this.userService.update(userId, user).pipe(
      catchError(() => {
        this.setUser(null);
        return throwError('handled error');
      }),
      tap((updatedUser) => {
        this.setUser(updatedUser, true);
      })
    );
  }

  updateGoogleUser(
    user: UserDetails,
    authUser: AuthUser
  ): Observable<UserDetails> {
    const payload = {
      ...user,
      email: authUser.email,
      first_name: authUser.given_name,
      last_name: authUser.family_name,
    };
    return this.updateUserInfo(user.id, payload);
  }

  setUser(user?: UserDetails, forceUpdate = false): void {
    if (user) {
      if (user.id === this.currentUser?.id && !forceUpdate) return;
      // identify user for analytics
      if (user.email && this.envConfig.collect_analytics) {
        this.analytics.dhIdentifyUser(user);
      }
    }
    this.currentUser = user;
    this.currUserSubject.next(this.currentUser);
  }

  /**
   * @deprecated we should use the method that return an observable
   */
  isUserLoggedIn(): boolean {
    return !!this.currentUser?.id;
  }

  isLoggedInUser(userId: string): boolean {
    return userId === this.currentUser.id;
  }

  isFromBackoffice(): boolean {
    return this.currentUser
      ? BACKOFFICE_ORG_IDS.includes(this.currentUser.organization.id)
      : false;
  }

  isDemo$(): Observable<boolean> {
    return this.isUserLoggedIn$().pipe(
      map((isUserLoggedIn) => !isUserLoggedIn)
    );
  }

  isUserLoggedIn$(): Observable<boolean> {
    return this.user$.pipe(map((user) => !!user?.id));
  }

  ngOnDestroy(): void {
    this.currUserSubject.complete();
  }
}

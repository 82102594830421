<div class="full-screen" [style.z-index]="zIndex || 1100">
  <div class="container" [style.height]="height" [style.width]="width">
    <ng-container *ngIf="sideCoverImageUrl">
      <div class="side-cover">
        <img
          *ngIf="_appLogo"
          src="shared-assets/icons/higeorge-logo/dataherald-logo-full-white.svg"
          alt=""
        />
        <img class="side-cover-img" [src]="sideCoverImageUrl" alt="" />
      </div>
    </ng-container>
    <div class="ui-modal">
      <div class="header">
        <ng-container *ngIf="titleIcon; else titleRef">
          <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="5px">
            <img [src]="titleIcon" alt="" />
            <ng-container *ngTemplateOutlet="titleRef"></ng-container>
          </div>
        </ng-container>
        <ng-template #titleRef>
          <h1 [class]="titleSize">{{ title }}</h1>
        </ng-template>
        <img
          class="close-btn"
          src="shared-assets/icons/close.svg"
          alt="close modal button"
          (click)="onClickClose()"
        />
      </div>
      <div class="content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>

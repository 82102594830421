
  <svg id="legend"></svg>
<div class="wrapper">

 
  <div *ngFor="let chart of pillData" class="pillRow">
    <div
      class="percent-pill"
      [ngClass]="{ stacked: horizontal, right: orientation == 'right' }"
    >
      <div class="chartLabel">{{ chart.label }}</div>
      <div
        [ngClass]="{
          'percent-bar': true,
          horizontal: horizontal,
          vertical: !horizontal
        }"
      >
        <div
          *ngFor="let seg of chart.data"
          class="segment"
          [ngStyle]="{
            backgroundColor: seg.bgColor,
            height: !horizontal ? seg.height : null,
            width: horizontal ? seg.width : null
          }"
        ></div>
      </div>
      <div *ngIf='!drawLegend'>
      <div
        [ngClass]="{
          'content-list': !horizontal,
          'content-list-horizontal': horizontal
        }"
      >
        <div
          *ngFor="let seg of chart.data"
          [ngClass]="{
            segment: !horizontal,
            segmentH: horizontal,
            stacked: horizontal
          }"
          [ngStyle]="{
            top: !horizontal ? seg.topPos : null,
            left: horizontal ? seg.leftPos : null
          }"
        >
          <mat-icon matSuffix>
            {{
              orientation === "left"
                ? "arrow_left"
                : orientation === "right"
                ? "arrow_right"
                : !isMobile || seg.percent >= 5 || !horizontal
                ? "arrow_drop_up"
                : ""
            }}
          </mat-icon>
          <span
            class="percent"
            [ngStyle]="{
              color: seg.bgColor,
              'font-size': horizontal ? '18.51px' : '15px'
            }"
            >{{
              !isMobile || seg.percent >= 5 || !horizontal
                ? seg.percent + "%"
                : ""
            }}</span
          >
          <span class="text">{{
            !isMobile || seg.percent >= 5 || !horizontal ? seg.category : ""
          }}</span>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>


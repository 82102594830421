import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import BLOCK_ERRORS from './../constants/block-errors';

@Injectable({
  providedIn: 'root',
})
export class BlocksErrorService implements OnDestroy {
  private errorSubject = new BehaviorSubject<BLOCK_ERRORS>(null);
  error$ = this.errorSubject.asObservable();

  publish(error: BLOCK_ERRORS): void {
    this.errorSubject.next(error);
  }

  ngOnDestroy(): void {
    this.errorSubject.complete();
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ResizedEvent } from 'angular-resize-event';
import { GraphSize } from '../../../services/app.service';

@Component({
  selector: 'app-graph-block',
  templateUrl: './graph-block.component.html',
  styleUrls: ['./graph-block.component.scss'],
})
export class GraphBlockComponent implements OnInit {
  allData: any;
  dataObj: any;
  size: GraphSize = null;

  @Input()
  set graphData(data: any) {
    this.getData(data);
  }

  constructor() {}

  ngOnInit(): void {}

  getData(chartData: any) {
    if (chartData) {
      this.dataObj = chartData;
    }
  }

  onResized(event: ResizedEvent) {
    const { width: newWidth, height: newHeight } = event.newRect;

    if (
      !this.size ||
      this.size.width !== newWidth ||
      this.size.height !== newHeight
    ) {
      this.size = {
        width: newWidth,
        height: newHeight,
      };
    }
  }
}

import { isPlatformBrowser } from '@angular/common';
import * as d3 from 'd3';
import {
  Component,
  OnInit,
  Input,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { AppService, GraphSize } from '../../../services/app.service';
import { PLATFORM_ID, Inject } from '@angular/core';
@Component({
  selector: 'app-heatmap-grid-block',
  templateUrl: './heatmap-grid-block.component.html',
  styleUrls: ['./heatmap-grid-block.component.scss'],
})
export class HeatmapGridBlockComponent implements OnInit, AfterViewInit {
  @Input() chartData: any;
  @Input() size: GraphSize = null;
  data: any[] = [];
  theme: any = {};
  width: number;
  height: number;
  containerBox;
  margins = { top: 35, right: 30, bottom: 20, left: 30 };
  isMobile: boolean = null;
  heightRef;
  widthRef;

  constructor(
    private container: ElementRef,
    private appService: AppService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    this.appService.getIsMobile().subscribe((isMobile) => {
      if (this.isMobile === null) {
        this.isMobile = isMobile;
      } else {
        this.isMobile = isMobile;
      }
    });
  }

  ngAfterViewInit(): void {
    const columnTable = this.container.nativeElement.closest(
      '.column-table-template'
    );
    const count = this.chartData.count;
    this.width = this.size.width;
    this.height = this.size.height;
    this.drawHeatMapGrid();
  }

  onResize() {
    if (isPlatformBrowser(this.platformId)) {
      const containerBox = document.getElementById('geovizcontainer');
      this.width = this.size.width;
      this.height = this.size.height;
      this.drawHeatMapGrid();
    }
  }

  drawHeatMapGrid() {
    d3.select(this.container.nativeElement).selectAll('#SVG > *').remove();
    const isMobile = this.isMobile;
    const margins = this.margins;
    const chartWidth = this.width - margins.left - margins.right;
    const chartHeight = this.height - margins.top - margins.bottom;
    const svg = d3
      .select(this.container.nativeElement)
      .select('svg')
      .attr('width', chartWidth + margins['left'] + margins['right'])
      .attr('height', chartHeight + margins['top'] + margins['bottom'])
      .append('g')
      .attr(
        'transform',
        'translate(' + margins['left'] + ',' + margins['top'] + ')'
      )
      .attr('margin', 'auto')
      .attr('class', 'svg-g');

    const data = this.chartData.data;
    const titleText = this.chartData.title;
    const xValues = d3.keys(this.chartData.data);

    const orderedArrayX = this.chartData.orderedArrayX;
    const orderedArrayY = this.chartData.orderedArrayY;
    const yValues = d3.keys(this.chartData.data[xValues[0]]);
    const gridSize = chartWidth / xValues.length;
    const yLength = yValues.length + 2; //height + legend, title
    const gridHeight = chartHeight / yLength;

    const yScaleFactor = gridSize / (chartHeight / yValues.length);

    const flatData = [];

    orderedArrayX.forEach(function (d, i) {
      yValues.forEach(function (e, j) {
        flatData.push({
          key: d,
          hour: e,
          val: data[d][e],
          num1: i,
          num2: j,
        });
      });
    });

    const vals = flatData.map(function (d) {
      return d.val;
    });

    const hg = [
      '#EFEFFC',
      '#E8E8FC',
      '#D1CFF6',
      '#B5B2F1',
      '#9C99EB',
      '#6B65E2',
      '#3931D8',
    ];
    const colorScale = d3
      .scaleQuantile<string>()
      .domain(d3.extent(vals))
      .range(hg);

    svg
      .selectAll('.grid')
      .data(flatData)
      .enter()
      .append('rect')
      .attr('x', function (d) {
        return d.num1 * gridSize;
      })
      .attr('y', function (d) {
        return (d.num2 + 1) * gridHeight;
      })
      .attr('width', gridSize)
      .attr('height', gridHeight)
      .style('fill', function (d) {
        if (d.val == undefined) {
          d.val = 0;
        }
        return colorScale(+d.val);
      });

    svg
      .selectAll('.dayLabel')
      .data(orderedArrayX)
      .enter()
      .append('text')
      .text(function (d) {
        return d as any;
      })
      .attr('x', function (d, i) {
        return i * gridSize;
      })
      .attr('y', gridHeight * (yValues.length + 1))
      .style('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .attr('font-size', isMobile ? '8px' : '11px')
      .attr('font-family', 'Lato, sans-serif')
      .attr('fill', '#A3A3A3')
      .attr('transform', 'translate(' + gridSize / 2 + ', 0)');

    svg
      .selectAll('.timeLabel')
      .data(orderedArrayY)
      .enter()
      .append('text')
      .text(function (d) {
        return d as any;
      })
      .attr('x', 0)
      .attr('y', function (d, i) {
        return i * gridHeight + gridHeight / 2;
      })
      .style('text-anchor', 'end')
      .attr('font-size', isMobile ? '8px' : '11px')
      .attr('font-family', 'Lato, sans-serif')
      .attr('fill', '#A3A3A3')
      .attr(
        'transform',
        'translate(-6,' + gridSize / (yScaleFactor * 1.5) + ')'
      )
      .attr('class', 'chartLabel')
      .attr('dominant-baseline', 'hanging');

    const legendData = [0].concat(colorScale.quantiles());

    const legend = svg
      .selectAll('.legend')
      .data(legendData, function (d) {
        return d as any;
      })
      .enter()
      .append('g')
      .attr('class', 'legend');

    legend
      .append('rect')
      .attr('x', function (d, i) {
        return gridSize * i;
      })
      .attr('y', 0)
      .attr('width', gridSize)
      .attr('height', gridHeight / 2)
      .style('fill', function (d, i) {
        return hg[i];
      })
      .attr('transform', 'translate(0, ' + -gridHeight + ')');

    legend
      .append('text')
      .text(function (d, i) {
        return Math.round(d) + (i == legendData.length - 1 ? '+' : '');
      })
      .attr('x', function (d, i) {
        return gridSize * i;
      })
      .attr('y', 0)
      .attr('font-size', isMobile ? '8px' : '11px')
      .attr('font-family', 'Lato, sans-serif')
      .attr('fill', '#A3A3A3')
      .attr('text-anchor', 'middle')
      .attr(
        'transform',
        'translate(' + gridSize / 2 + ',' + -gridHeight / 2 + ')'
      )
      .attr('dominant-baseline', 'hanging');

    svg
      .append('text')
      .text(titleText)
      .attr('y', gridHeight)
      .attr('x', chartWidth / 2)
      .attr('text-anchor', 'middle')
      .attr(
        'transform',
        'translate(0,' + (isMobile ? -gridHeight * 0.5 : -6) + ')'
      )
      .attr('fill', '#202058')
      .attr('font-family', 'Lato, sans-serif')
      .attr('font-size', isMobile ? '10px' : '14px')
      .attr('font-weight', 'bold')
      .attr('alignment-baseline', 'auto');
  }
}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ui-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
  @Input() text = '';
  @Input() image_url?;
  @Input() isChecked = false;
  @Input() canInteract? = false;

  @Output() onToggle? = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onClick = () => {
    this.onToggle.emit();
  };
}

<ui-modal
  title="Source suggestion"
  width="600px"
  height="auto"
  (onCloseModal)="onCloseModal.emit()"
>
  <div class="ccr-container">
    <div class="modal-copy">
      <h1>Tell us what data sources you’d like to see</h1>
      <p>
        We are constantly adding new data sources to Dataherald and we’d love to learn what is important to you. Please click the button below and complete our short form to share your suggestions with us!
      </p>
    </div>
    <div class="options-buttons">
      <div class="btn-filled-color" (click)="onClickCreateCCR()">
        <p>Suggest a source</p>
      </div>
    </div>
  </div>
</ui-modal>

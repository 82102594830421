import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() modalTitle?: string = 'Confirm';
  @Input() header = 'Are you sure?';
  @Input() subheader = 'This action cannot be reversed';
  @Input() btnYesText?: string = "Yes, I'm sure";
  @Input() btnNoText?: string = 'No, take me back';

  @Output() onYes = new EventEmitter<void>();
  @Output() onNo = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onClickYes(): void {
    this.onYes.emit();
  }

  onClickNo(): void {
    this.onNo.emit();
  }
}

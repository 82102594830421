import {
  AfterViewInit,
  Component,
  Directive,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { AnalyticsService, VisualizationSummary } from 'hg-front-core';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'app-shared-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() data: VisualizationSummary;
  @Input() hideEditReferral = false;
  @Input() hideDataheraldLogo = false;
  @Input() vizEditorUrl: string;
  isMobile = false;

  constructor(
    private analyticsService: AnalyticsService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.appService.getIsMobile().subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  clickSource(src: string): void {
    if (!globalThis) {
      return;
    }

    this.analyticsService.v4EventEmitter({
      eventName: 'Source Click',
      eventCategory: 'click',
      eventAction: 'Source',
      eventValue: 0,
      vizSummary: this.data,
      callAnalytics: true,
    });
  }

  clickHG(): void {
    if (!globalThis) {
      return;
    }

    this.analyticsService.v4EventEmitter({
      eventName: 'Click on Dataherald Logo',
      eventCategory: 'click',
      eventAction: 'Dataherald',
      eventValue: 0,
      vizSummary: this.data,
      callAnalytics: true,
    });
  }

  clickEditViz(): void {
    if (!globalThis) {
      return;
    }
    this.analyticsService.v4EventEmitter({
      eventName: 'Click on Embedded Visualization',
      eventCategory: 'click',
      eventAction: 'EmbedEditVisualization',
      eventValue: 0,
      vizSummary: this.data,
      callAnalytics: true,
    });
  }
}

@Directive({
  selector: '[EllipsisText]',
})
export class EllipsisText implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      if (element.offsetWidth < element.scrollWidth) {
        element.title = element.innerText;
      }
    }, 500);
  }
}

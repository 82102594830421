import { Component, OnInit, SkipSelf } from '@angular/core';
import * as d3 from 'd3';
import { AnalyticsService, BlockAxis } from 'hg-front-core';
import { AxisBlockComponent } from 'projects/HGFrontSharedUI/src/lib/modules/core/components/blocks/axis-block/axis-block.component';
import BLOCK_ERRORS from '../../../constants/block-errors';
import { AppService, GraphSize } from '../../../services/app.service';

@Component({
  selector: 'app-barline-block',
  templateUrl: './barline-block.component.html',
  styleUrls: ['./barline-block.component.scss'],
})
export class BarlineBlockComponent
  extends AxisBlockComponent
  implements OnInit
{
  graphSize: GraphSize;
  margins = { top: 50, right: 30, bottom: 30, left: 35 };

  isMobile: boolean = null;
  constructor(
    private analayticsService: AnalyticsService,
    @SkipSelf() public appService: AppService
  ) {
    super(appService);
  }

  datesAreOnSameDay(first, second): boolean {
    return (
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate()
    );
  }

  drawChart(): void {
    this.config = <BlockAxis>this.config;
    if (
      !this.block ||
      !this.size ||
      this.size.width === 0 ||
      this.size.height === 0
    )
      return;
    d3.selectAll('#SVG > *').remove();
    const self = this;
    const svg = d3.select('#SVG');
    const isMobile = this.isMobile;
    const width = this.size.width;
    const height = this.size.height;
    const barKey = this.config.bar_line_options.bar
      ? this.config.bar_line_options.bar
      : this.block.categories[0];

    const lineKey =
      this.config.bar_line_options.line != null
        ? this.config.bar_line_options.line
        : this.block.categories[1];
    const displayTime = this.config.xaxis.show_time;

    const scheme = this.block.color_scheme;

    const tooltipShown = this.appService.tooltipShown;
    svg.attr('width', width).attr('height', height);

    let bottomMargin = isMobile ? 60 : 30;
    let topMargin = isMobile ? 15 : 30;

    // increase bottom margin in this case because x axis labels are very long if displaying time
    // and the labels are rotated in mobile view, requiring more space to not be cut off
    if (this.config.xaxis.show_time) {
      if (isMobile) {
        if (scheme.type == 'categorical')
          topMargin = this.block.categories.length * 15;
        bottomMargin = 90;
      } else if (width <= 900) {
        bottomMargin = 60;
      }
    }

    const margin = {
      top: topMargin,
      right: isMobile ? 5 : 30,
      bottom: bottomMargin,
      left: 35,
    };

    const colorScheme = this.createColorScheme();

    const lineDataPoints = this.config.data.filter(
      (d) => d.category == lineKey
    );

    const barDataPoints = this.config.data.filter((d) => d.category == barKey);

    const minNum = d3.min(
      [...barDataPoints, ...lineDataPoints],
      (d) => d.value
    );
    const maxNum = d3.max(
      [...barDataPoints, ...lineDataPoints],
      (d) => d.value
    );

    const scale = this.drawYAxis(minNum, maxNum, height, margin, width, true);

    const yAlternate = d3
      .axisLeft(scale)
      .tickSize(0)
      .tickFormat('' as any);

    const x_bandwidth = d3
      .scaleBand<Date>()
      .domain(this.config.data.map((d) => <Date>d['x']))
      .range([margin.left, width - margin.right - margin.left])
      .padding(0.1)
      .bandwidth();

    const xScales = this.drawXAxis(
      margin,
      width - x_bandwidth / 2,
      height,
      x_bandwidth,
      displayTime
    );

    const scaleLine = d3
      .scaleLinear()
      .domain(d3.extent(lineDataPoints, (d) => d.value))
      .range([
        height - margin.bottom,
        scale(d3.max(lineDataPoints, (d) => d.value)),
      ]);

    const line = d3
      .line<any>()
      .x((d) => xScales(d.x) + x_bandwidth / 2)
      .y((d) => scale(d.value));

    const keys = [];

    /*for (let i = 0; i < this.block.categories.length; i++) {
        keys.push(this.block.categories[i]);
      }*/
    keys.push(barKey);
    keys.push(lineKey);

    const tip = this.createToolTip(keys, 'x');

    svg.call(tip);

    const { min } = this.calculateYAxisRange(minNum, maxNum, true);

    const ga = this.analayticsService;
    svg
      .append('g')
      .attr('fill-opacity', 0.8)
      .selectAll('rect')
      .data(barDataPoints)
      .join('rect')
      .attr('fill', (d) => {
        if (scheme.type == 'range') {
          return colorScheme(d.value);
        } else {
          return colorScheme(barKey);
        }
      })
      .attr('width', (d) => x_bandwidth)
      .attr('height', (d) => {
        if (scale(min) - scale(d.value) < 0)
          throw new Error(BLOCK_ERRORS.NEGATIVE_BAR_HEIGHT);
        else return scale(min) - scale(d.value);
      })
      .attr('x', (d, i) => xScales(<Date>d['x']) - x_bandwidth / 2)
      .attr('y', (d) => scale(d.value))
      .on('mouseover', function (d) {
        if (lineKey) {
          d[lineKey] = lineDataPoints.filter((f) =>
            self.datesAreOnSameDay(f.x, d.x)
          )[0].value;
        }

        d[barKey] = d.value;
        const centroid = self.getBoundingBoxCenter(this);
        const width = (svg as any).node().clientWidth;
        const direction = self.getTooltipDirection(
          centroid[0],
          width,
          isMobile
        );
        tip.direction(direction).show(d, this);
        tooltipShown.emit(true);
        d3.select(this).attr('fill', 'lightgray');
        ga.blockEventEmitter(
          'Interaction',
          'interaction',
          globalThis.vizCollection + '/' + globalThis.vizType + '/' + 'barLine',
          null,
          0,
          {
            InteractionType: 'mouseOver',
            Collection: globalThis.vizCollection,
            vizType: globalThis.vizType,
            block: 'barLine',
          },
          true
        );
      })
      .on('mouseout', function (d) {
        tip.hide();
        tooltipShown.emit(false);
        d3.select(this).attr('fill', (d) => {
          if (scheme.type == 'range') {
            return colorScheme((<any>d).value);
          } else {
            return colorScheme(barKey);
          }
        });
      });

    let legendKeys = [];
    if (scheme.type == 'categorical') {
      // bar LINE
      svg
        .append('path')
        .data(lineDataPoints)
        .attr('fill', 'none')
        .attr('stroke', colorScheme(lineKey))
        .attr('stroke-miterlimit', 1)
        .attr('stroke-width', 3)
        .attr('d', (d) => line(lineDataPoints));

      legendKeys = [
        { label: barKey, color: colorScheme(barKey) },
        { label: lineKey, color: colorScheme(lineKey) },
      ];
    } else if (scheme.type == 'divergent') {
      colorScheme.domain().forEach((key) => {
        legendKeys.push({ label: key, color: colorScheme(key) });
      });
    } else if (scheme.type == 'range') {
      this.block.color_scheme.options.range_cutoffs.forEach((range) =>
        legendKeys.push({ label: range.name, color: colorScheme(range.value) })
      );
    }

    this.drawHorizontalLegend(legendKeys, width, false);
  }
}

import { Params, ActivatedRoute, Router } from '@angular/router';

export type UrlUpdate = {
  queryParams: Params;
  router: Router;
  relativeRoute?: ActivatedRoute;
};

export const queryParamsUpdate = ({
  queryParams,
  router,
  relativeRoute,
}: UrlUpdate): void => {
  router.navigate([], {
    ...(relativeRoute ? { relativeTo: relativeRoute } : {}),
    queryParams,
  });
};

export const staticQueryParamsUpdate = ({
  queryParams,
  router,
  relativeRoute,
}: UrlUpdate): void => {
  router.navigate([], {
    ...(relativeRoute ? { relativeTo: relativeRoute } : {}),
    replaceUrl: true,
    queryParams,
  });
};

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AccountIsSetup } from './guards/account-is-setup.guard';
import { FirstLoginDemoFlowGuard } from './guards/first-login-demo-flow.guard';
import { OldRoutesGuard } from './guards/old-routes.guard';

const BACKWARD_COMPATIBILITY_ROUTES = [
  {
    path: 'create-graph',
    canActivate: [OldRoutesGuard],
    loadChildren: () =>
      import('./modules/visualization/visualization.module').then(
        (m) => m.VisualizationModule
      ),
  },
  {
    path: 'editviz/:vizId',
    canActivate: [OldRoutesGuard],
    loadChildren: () =>
      import('./modules/visualization/visualization.module').then(
        (m) => m.VisualizationModule
      ),
  },
];

const routes: Routes = [
  {
    path: '',
    canActivate: [AccountIsSetup],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'demo',
        redirectTo: 'visualization',
        pathMatch: 'full',
      },
      {
        path: 'home',
        canActivate: [AuthGuard, FirstLoginDemoFlowGuard],
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'workspace',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/workspace/workspace.module').then(
            (m) => m.WorkspaceModule
          ),
      },
      {
        path: 'whats-new',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/whats-new/whats-new.module').then(
            (m) => m.WhatsNewModule
          ),
      },
      {
        path: 'visualization',
        loadChildren: () =>
          import('./modules/visualization/visualization.module').then(
            (m) => m.VisualizationModule
          ),
      },
    ],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  ...BACKWARD_COMPATIBILITY_ROUTES,
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

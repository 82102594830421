import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService, AuthStateAndMessage } from 'hg-front-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loading$: Observable<boolean>;

  constructor(private authService: AuthService, private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Dataherald');

    this.loading$ = this.authService.state$.pipe(
      map(
        (authState: AuthStateAndMessage): boolean =>
          authState.state === null || authState.state === 'loading'
      )
    );
  }
}

import { Subscription } from 'rxjs';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AnalyticsService,
  VisualizationData,
  VisualizationSummary,
} from 'hg-front-core';

@Component({
  selector: 'app-graph-view',
  templateUrl: './graph-view.component.html',
  styleUrls: ['./graph-view.component.scss'],
})
export class GraphViewComponent implements OnInit, OnDestroy {
  @Input() vizData: VisualizationData;
  @Input() vizSummary: VisualizationSummary;
  @Output() controlValueChanged = new EventEmitter<any>();

  analyticsSub: Subscription;

  constructor(private analyticsService: AnalyticsService) {}

  userChangedControlValue(queryParamValuePairs: any): void {
    this.controlValueChanged.emit(queryParamValuePairs);
  }

  ngOnInit(): void {
    this.analyticsSub = this.analyticsService.blockEvents$.subscribe(
      (event) => {
        this.analyticsService.v4EventEmitter({
          ...event,
          vizData: this.vizData,
          vizSummary: this.vizSummary,
        });
      }
    );
  }

  ngOnDestroy(): void {
    this.analyticsSub?.unsubscribe();
  }
}

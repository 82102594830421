<div
  class="viz-tile"
  [ngClass]="{ 'cursor-pointer': showHoverActions }"
  (click)="onClickTile()"
>
  <div class="tile-top-container">
    <!-- PREVIEW IMAGE -->
    <img
      class="preview-img"
      *ngIf="previewImg && !isDataFeed"
      src="{{ previewImg }}"
    />
    <img
      class="preview-datafeed-img"
      *ngIf="previewImg && isDataFeed"
      src="{{ previewImg }}"
    />
    <div *ngIf="!previewImg" class="no-preview-img">
      <img class="map-icon" src="shared-assets/icons/map-gray-icon.svg" />
      <p>{{ isLoading ? "Loading..." : "Preview not available" }}</p>
    </div>

    <!-- HOVER ACTIONS -->
    <div class="hover-actions" *ngIf="showHoverActions">
      <div class="hover-actions-top">
        <div class="hover-actions-top-right">
          <div
            nz-dropdown
            class="trash-btn btn"
            nzTrigger="hover"
            [nzDropdownMenu]="menu"
            [nzPlacement]="position"
            (click)="$event.stopPropagation()"
          >
            <i nz-icon nzType="ellipsis" nzTheme="outline" class="ellipses"></i>
          </div>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
              <li nz-menu-item (click)="onClickView()" class="list-items">
                <img
                  src="shared-assets/icons/eye-black.svg"
                  class="icons mr-3"
                />
                Preview Visualization
              </li>
              <li nz-menu-item (click)="onClickLink()" class="list-items">
                <img src="shared-assets/icons/export.svg" class="icons mr-3" />
                Export
              </li>
              <li nz-menu-item (click)="onClickDuplicate()" class="list-items">
                <img
                  src="shared-assets/icons/copy-icon.svg"
                  class="icons mr-3"
                />
                Duplicate
              </li>
              <li nz-menu-item (click)="onClickDelete()" class="list-items">
                <img
                  src="shared-assets/icons/trash-black-icon.svg"
                  class="icons mr-3"
                />
                Delete
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </div>
    </div>

    <!-- LAST UPDATED FLOATING LABEL -->
    <div
      class="lastUpdated {{ isLive || isRecentlyUpdated ? 'live' : 'not-live' }}
            {{ showHoverActions ? 'hide-on-hover' : '' }}
            {{ isNewFeed ? 'new-feed-chip' : '' }}"
      *ngIf="isLive || formattedLastUpdated || isNewFeed"
    >
      <img
        src="shared-assets/icons/{{
          isLive
            ? 'clock-white-icon.svg'
            : isRecentlyUpdated
            ? 'refresh-white-icon.svg'
            : 'clock-gray-icon.svg'
        }}"
      />
      <p>
        {{
          isLive
            ? "Live"
            : isNewFeed
            ? "New Template"
            : isRecentlyUpdated
            ? "Recent Data Update"
            : formattedLastUpdated
        }}
      </p>
    </div>
  </div>

  <!-- TILE INFO -->
  <div class="tile-info">
    <div class="tile-info-top">
      <p class="tile-title">{{ _title }}</p>
      <p class="data-provider text" *ngIf="dataProviderName">
        {{ dataProviderName.substring(0, 40)
        }}{{ dataProviderName.length > 40 ? "..." : "" }}
      </p>
    </div>

    <div class="tile-info-bottom">
      <div class="tile-detail-list">
        <div class="tile-detail" *ngIf="graphType">
          <img [src]="graphTypeIcon" />
          <p class="text" title="{{ graphType }}">
            {{ graphType.substring(0, 15)
            }}{{ graphType.length > 15 ? "..." : "" }}
          </p>
        </div>
        <div class="tile-detail" *ngIf="organization">
          <p class="text">{{ organization }}</p>
        </div>
      </div>

      <div class="tile-detail-right" *ngIf="location">
        <div class="tile-detail">
          <img src="shared-assets/icons/location-icon.svg" />
          <p class="text" title="{{ location }}">
            {{ location.substring(0, 15)
            }}{{ location.length > 15 ? "..." : "" }}
          </p>
        </div>
      </div>

      <div class="tile-detail-right">
        <div class="tile-detail" *ngIf="refreshPeriod">
          <img src="shared-assets/icons/refresh-gray-icon.svg" />
          <p class="text" title="{{ refreshPeriod }}">
            {{ refreshPeriod.substring(0, 10)
            }}{{ refreshPeriod.length > 10 ? "..." : "" }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

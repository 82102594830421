import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-meta-data-item',
  templateUrl: './meta-data-item.component.html',
  styleUrls: ['./meta-data-item.component.scss'],
})
export class MetaDataItemComponent implements OnInit {
  @Input()
  item: MetaDataItem;

  constructor() {}

  ngOnInit(): void {}
}

export type MetaDataItem = {
  text: string;
  iconUrl: string;
};

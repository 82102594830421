import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.scss'],
})
export class PageContentComponent {
  @Input()
  width: PageContent = 'full';
}

export type PageContent =
  | 'x-narrow'
  | 'narrow'
  | 'regular'
  | 'wide'
  | 'x-wide'
  | 'full';

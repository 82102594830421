export const CHART_ICONS_DARK = {
  geoviz: 'shared-assets/icons/charts/dark/map.svg',
  point_map: 'shared-assets/icons/charts/dark/map.svg',
  multi_line: 'shared-assets/icons/charts/dark/line.svg',
  stacked_bar: 'shared-assets/icons/charts/dark/bar.svg',
  grouped_bar: 'shared-assets/icons/charts/dark/grouped-bar.svg',
  horizontal_bar: 'shared-assets/icons/charts/dark/bar-h.svg',
  pie_chart: 'shared-assets/icons/charts/dark/pie-chart.svg',
  percent_pill: 'shared-assets/icons/charts/dark/percent-pill.svg',
  bar_line: 'shared-assets/icons/charts/dark/bar-line.svg',
};

export const CHART_ICONS_COLOR = {
  geoviz: 'shared-assets/icons/charts/color/map.svg',
  point_map: 'shared-assets/icons/charts/color/map.svg',
  multi_line: 'shared-assets/icons/charts/color/line.svg',
  stacked_bar: 'shared-assets/icons/charts/color/bar.svg',
  grouped_bar: 'shared-assets/icons/charts/color/grouped-bar.svg',
  horizontal_bar: 'shared-assets/icons/charts/color/bar-h.svg',
  pie_chart: 'shared-assets/icons/charts/color/pie-chart.svg',
  percent_pill: 'shared-assets/icons/charts/color/percent-pill.svg',
  bar_line: 'shared-assets/icons/charts/color/bar-line.svg',
};

export const CHART_LABELS = {
  geoviz: 'GeoViz',
  point_map: 'Point map',
  multi_line: 'Multi line',
  stacked_bar: 'Stacked Bar',
  grouped_bar: 'Grouped Bar',
  horizontal_bar: 'Horizontal bar',
  pie_chart: 'Pie chart',
  percent_pill: 'Percent pill',
  bar_line: 'Bar line',
  multipartite_graph: 'Multipartite graph',
  simple_line: 'Simple line',
};

<ng-container *ngIf="!actionClicked; else creating">
  <div
    class="wrapper"
    fxLayout="column"
    fxLayoutAlign="space-between center"
    fxLayoutGap="20px"
  >
    <ng-container *ngIf="!error; else errorRef">
      <ng-container *ngIf="visualizationId; else loading">
        <ng-container *ngIf="vizData$ | async as vizData; else loading">
          <ng-container *ngIf="vizSummary$ | async as vizSummary; else loading">
            <div id="content" fxLayout="column" fxLayoutGap="15px">
              <div id="graph-preview">
                <app-graph-view
                  [vizData]="vizData"
                  [vizSummary]="vizSummary"
                  (controlValueChanged)="updateGraph($event)"
                ></app-graph-view>
              </div>
              <div
                id="source"
                fxLayout="row"
                fxLayoutAlign="space-evenly start"
                fxLayoutGap="5px"
              >
                <div
                  id="source-item"
                  fxFlex="33"
                  fxLayout="column"
                  fxLayoutAlign="start center"
                  fxLayoutGap="10px"
                >
                  <div fxLayout="row" fxLayoutGap="10px">
                    <img src="shared-assets/icons/database.svg" alt="" />
                    <span>Data source</span>
                  </div>
                  <h3
                    *ngIf="!vizSummary.source.image_url; else dataSourceLogoRef"
                  >
                    {{ vizSummary.source.text }}
                  </h3>
                  <ng-template #dataSourceLogoRef>
                    <img
                      *ngIf="vizSummary.source.image_url"
                      class="data-source-logo"
                      ngClass.xs="small"
                      alt="data source logo"
                      [src]="vizSummary.source.image_url"
                    />
                  </ng-template>
                </div>
                <div
                  id="source-item"
                  fxFlex="33"
                  fxLayout="column"
                  fxLayoutAlign="start center"
                  fxLayoutGap="10px"
                >
                  <div fxLayout="row" fxLayoutGap="5px">
                    <img src="shared-assets/icons/clock.svg" alt="" />
                    <span>Last update</span>
                  </div>
                  <h3>
                    {{
                      vizSummary.source.data_last_updated
                        | dateDistanceToNow
                        | capitalizeFirst
                    }}
                  </h3>
                </div>
                <div
                  id="source-item"
                  fxFlex="33"
                  fxLayout="column"
                  fxLayoutAlign="start center"
                  fxLayoutGap="10px"
                >
                  <div fxLayout="row" fxLayoutGap="10px">
                    <img src="shared-assets/icons/refresh.svg" alt="" />
                    <span>Update interval</span>
                  </div>
                  <h3>
                    {{ vizSummary.source.update_frequency }}
                  </h3>
                </div>
              </div>
            </div>
            <div
              id="footer"
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutAlign.gt-sm="end center"
              fxLayoutGap="15px"
              fxFlexAlign="end"
            >
              <button
                fxHide.lt-sm
                *ngIf="mode === PREVIEW_MODE.EDIT"
                class="btn-outline-white"
                (click)="duplicate(vizSummary)"
              >
                <img
                  src="shared-assets/icons/copy-icon.svg"
                  alt="duplicate visualization button icon"
                />
                Duplicate
              </button>
              <button
                *ngIf="mode === PREVIEW_MODE.EDIT"
                class="btn-outline-white"
                (click)="openExportModal(vizSummary)"
              >
                <img
                  src="shared-assets/icons/export.svg"
                  alt="export visualization button icon"
                />
                Export
              </button>
              <button class="btn-outline-white" (click)="onActionClick()">
                <img
                  [src]="
                    mode === PREVIEW_MODE.EDIT
                      ? 'shared-assets/icons/edit-black-icon.svg'
                      : 'shared-assets/icons/copy-icon.svg'
                  "
                  alt="customize visualization button icon"
                />
                {{ actionLabel }}
              </button>
            </div>
            <ng-container *ngIf="isExportModalOpen">
              <app-viz-export-modal
                [vizSummary]="vizSummary"
                [zIndex]="1400"
                [opened]="isExportModalOpen"
                (openedChange)="isExportModalOpen = false"
              ></app-viz-export-modal>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #errorRef>
  <div fxFlex>
    <ui-image-message></ui-image-message>
  </div>
  <div id="footer" fxFlexAlign="end">
    <a class="btn-outline" (click)="goToEditWithError()">
      Customize and fix it!
    </a>
  </div>
</ng-template>

<ng-template #loading>
  <nz-spin
    class="container-centered"
    nzSimple
    nzTip="Loading visualization..."
  ></nz-spin>
</ng-template>

<ng-template #creating>
  <nz-spin
    class="container-centered"
    nzSimple
    nzTip="Creating visualization..."
  ></nz-spin>
</ng-template>

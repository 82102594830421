import { Component, OnInit, SkipSelf } from '@angular/core';
import * as d3 from 'd3';
import { BlockSegment } from 'hg-front-core';
import { AppService } from '../../../services/app.service';
import { SegmentBlockComponent } from '../segment-block/segment-block.component';

@Component({
  selector: 'app-pie-chart-block',
  templateUrl: './pie-chart-block.component.html',
  styleUrls: ['./pie-chart-block.component.scss'],
})
export class PieChartBlockComponent
  extends SegmentBlockComponent
  implements OnInit
{
  margins = { top: 50, right: 0, bottom: 30, left: 200 }; // doesn't do anything

  isMobile: boolean = null;

  constructor(@SkipSelf() public appService: AppService) {
    super(appService);
  }

  calculateRadius(
    numCharts: number,
    margin: any,
    legendHeight: number
  ): number {
    // multiplies margin between charts by a constant if multiple charts
    const marginMultiple = numCharts > 1 ? 2 : 1;

    const width = this.size.width;
    const height = this.size.height;
    const isMobile = this.isMobile;

    const maxWidth =
      (width - (margin.right + margin.left) * marginMultiple) /
      (isMobile ? 1 : numCharts);

    // the isMobile check is to add some margin between the charts when they're stacked vertically
    const maxHeight = height - legendHeight - (isMobile ? 10 : 0);

    return Math.min(maxWidth, maxHeight) / 2;
  }

  drawCategoricalLegend(colorScheme): void {
    const legendKeys = this.block.categories.map((d) => {
      return { label: d, color: colorScheme(d) };
    });

    this.drawHorizontalLegend(legendKeys, this.size.width, false);
  }

  /*
  formula for translating how far right to move each pie chart
  based on how many charts need to be drawn and what the idx
  of the current chart is. Pattern for the formula is as follows:
  one pie chart --> 1/2 * multiplier
  two pie charts --> 1/4 * multiplier, 3/4 * multiplier
  three pie charts --> 1/6 * multiplier, 3/6 * multiplier, 5/6 * multiplier
  ...
  */
  getTranslate(multiplier: number, idx: number, numCharts: number): number {
    return multiplier * ((idx * 2 + 1) / (numCharts * 2));
  }

  drawChart(): void {
    // check if all variables are present
    if (
      !this.block ||
      !this.size ||
      this.size.width === 0 ||
      this.size.height === 0
    ) {
      return;
    }

    this.config = <BlockSegment>this.config;

    const numCharts = this.config.charts.length;

    // variable declarations
    const isMobile = this.isMobile;
    const { width, height } = this.size;
    const donut = this.config.pie_chart_options.donut;
    const colorScheme = this.createColorScheme();
    const margin = {
      top: 20,
      right: 20,
      bottom: 20,
      left: 20,
    };

    // select SVG
    const svg = d3.select('#SVG');
    svg.attr('width', width).attr('height', height);

    // wipe the DOM before drawing to it
    d3.selectAll('#SVG > *').remove();

    // create tooltip
    const tip = this.createToolTip(['text', 'value'], undefined, [
      'Category',
      'Percentage',
    ]);
    svg.call(tip);

    this.drawCategoricalLegend(colorScheme);
    const legendHeight = this.getCategoricalLegendHeight() + 20;

    // create radius of circle -- used in for loop
    const radius = this.calculateRadius(numCharts, margin, legendHeight);
    if (isMobile) {
      svg.attr('height', (2 * radius + margin.top) * numCharts + legendHeight);
    }

    for (let i = 0; i < numCharts; i += 1) {
      // pie chart data
      const curr_data = this.config.charts[i].data;

      // read data into pie chart
      const pie = d3.pie().value(function (d) {
        return (d as any).value;
      });
      const pie_data = pie(curr_data as any);

      // create tool tip data
      const total = curr_data.reduce((a, b) => a + b.value, 0);
      const tipData = curr_data.map((d) => {
        return { text: d.category, value: d3.format('.1%')(d.value / total) };
      });

      const getTranslateX = () => {
        if (isMobile) return width / 2;
        return this.getTranslate(width, i, numCharts);
      };

      const getTranslateY = () => {
        if (!isMobile) return (height - legendHeight) / 2 + legendHeight;

        let regTranslate = this.getTranslate(
          (2 * radius + margin.top) * numCharts,
          i,
          numCharts
        );
        // account for legend on top
        regTranslate += legendHeight;
        return regTranslate;
      };

      // correctly position pie chart within SVG
      const g = svg
        .append('g')
        .attr(
          'transform',
          'translate(' + getTranslateX() + ',' + getTranslateY() + ')'
        );

      const fontSize = isMobile ? 20 : 24;
      g.append('text')
        .style('font-size', fontSize + 'px')
        .attr('font-weight', 'bold')
        .attr('fill', '#3c4064')
        .text(this.config.charts[i].label)
        .attr(
          'transform',
          'translate(' +
            -1 *
              0.5 *
              this.getTextWidth(
                this.config.charts[i].label,
                'Roboto',
                fontSize
              ) +
            ',' +
            10 +
            ')'
        );

      const arcs = g
        .selectAll('arcs')
        .data(pie_data)
        .enter()
        .append('path')
        .attr(
          'd',
          <any>d3
            .arc()
            .innerRadius(donut ? radius / 2 : 0)
            .outerRadius(radius)
        )
        .attr('fill', function (d) {
          return <any>colorScheme((d as any).data.category);
        })
        .attr('stroke', 'white')
        .style('opacity', 0.7)
        .on('mouseover', function (d, i) {
          // modify data for tooltip
          tip.show(tipData[i], this);
        })
        .on('mouseout', function (d, i) {
          tip.hide(tipData[i], this);
        });

      const label = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(donut ? radius * 0.5 : radius * 0.1);
      const textThreshold = Math.PI / 8;

      const labels = g
        .selectAll('.label')
        .data(pie_data)
        .enter()
        .append('g')
        .attr('transform', (v) => `translate(${label.centroid(v as any)})`)
        .style('display', (v) =>
          v.endAngle - v.startAngle > textThreshold ? 'inline' : 'none'
        )
        .attr('text-anchor', 'middle')
        .style('fill', 'white')
        .attr('font-family', 'Lato, sans-serif')
        .attr('font-size', isMobile ? '13px' : '19px');

      labels
        .append('text')
        .attr('dy', '1.2em')
        .style('font-size', '90%')
        .text((v, i) => tipData[i].value);
    }
  }
}

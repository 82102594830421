<div
  id="top-bar"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  [ngClass]="{ white: _whiteBG }"
>
  <div fxLayout="row">
    <img
      *ngIf="_backEnabled"
      src="shared-assets/icons/arrow-left.svg"
      alt="go back"
      class="pointer mr-3"
      (click)="goBack()"
    />
    <h1 ngClass.xs="small-title">
      <ng-content select="[title]"></ng-content>
    </h1>
  </div>
  <ng-container *ngIf="_appLogo">
    <img
      class="brand-logo"
      src="shared-assets/icons/higeorge-logo/dataherald-logo-full-color.svg"
      alt=""
    />
  </ng-container>
  <div>
    <ng-content select="[action]"></ng-content>
  </div>
</div>

<h3 mat-dialog-title>{{title}}</h3>

<div mat-dialog-content style="height:80px">
  <p>{{errorBody}}</p>


<div mat-dialog-actions>
    <div *ngIf="continueMode; else closeMode" >
        <button class="mat-raised-button"(click)="close()">Cancel</button>
        <button class="mat-raised-button"(click)="continue()">Continue</button>
   </div>
   <ng-template #closeMode>
      <button mat-raised-button (click)="close()">Close</button>
   </ng-template>
</div>
</div>


import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  SkipSelf,
} from '@angular/core';
import * as d3 from 'd3';
import { FeatureCollection } from 'geojson';
import {
  AnalyticsService,
  BlockGeoviz,
  ColorSchemeCategorical,
  ColorSchemeDivergent,
  ColorSchemeGraduated,
} from 'hg-front-core';
import * as topojson from 'topojson-client';
import { AppService, GraphSize } from '../../../services/app.service';
import { LeafletService } from '../../../services/leaflet.service';
import { BaseChartBlockComponent } from '../../blocks/base-chart-block/base-chart-block.component';
import { is } from 'date-fns/locale';

@Component({
  selector: 'app-geoviz-block',
  templateUrl: './geoviz-block.component.html',
  styleUrls: ['./geoviz-block.component.scss'],
})
export class GeovizBlockComponent
  extends BaseChartBlockComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input()
  size: GraphSize;

  basemap: string = null;
  map;

  constructor(
    @SkipSelf() private appService: AppService,
    private googleAnaytics: AnalyticsService,
    private leafletService: LeafletService
  ) {
    super(appService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.size && !changes.size.firstChange) {
      this.drawChart();
    }
  }

  ngOnInit(): void {
    this.config = <BlockGeoviz>this.config;
    this.basemap = this.config.basemap;
    this.isMobile = this.appService.isMobile;

    //If screen size change
    this.appService
      .getIsMobile()
      .subscribe((isMobile) => (this.isMobile = isMobile));
  }

  ngAfterViewInit(): void {
    this.drawChart();
    if (this.map) {
      this.map.invalidateSize(true);
    }
  }

  drawChart(): void {
    /*
     * NEED a keys list of everything available
     *
     * */
    this.config = <BlockGeoviz>this.config;
    d3.selectAll('#SVG > *').remove();
    d3.selectAll('.d3-tip').remove();
    let svg;
    let map;
    const self = this; // This is used bc "this" is not accessible in the D3 callback function
    if (this.map) {
      this.map.remove();
    }

    if (!this.size || !this.size.width || this.size.height <= 50) {
      return;
    }

    const width = this.size.width;
    const height = this.size.height - 50;

    const isMobile = this.isMobile;
    const ga = this.googleAnaytics;
    const margin = { top: 20, right: 0, bottom: isMobile ? 0 : 30, left: 0 };
    const scaleAdjust = 1;
    const toolTipKeys = this.config.tooltips;
    let bounds;
    let clickFlag = false;

    //this is a hack to make the drought map work
    if (this.config.shapes.type == 'drought_polygons') {
      bounds = [
        [
          this.config.shapes.bounds['nw_latitude'],
          this.config.shapes.bounds['nw_longitude'],
        ],
        [
          this.config.shapes.bounds['se_latitude'],
          this.config.shapes.bounds['se_longitude'],
        ],
      ];
    } else {
      bounds = [
        [this.config.data.bbox[3], this.config.data.bbox[0]],
        [this.config.data.bbox[1], this.config.data.bbox[2]],
      ];
    }

    const scheme = this.block.color_scheme.type;
    const coloring = this.block.color_scheme;
    const showLabels = this.config.show_labels;
    const basemap = this.config.basemap;
    const tooltipShown = this.appService.tooltipShown;

    // objects found in this map
    const map_components: string[] = Object.keys(this.config.data.objects);

    const featureCollection: FeatureCollection = (<FeatureCollection>(
      topojson.feature(
        this.config.data,
        this.config.data.objects[map_components[0]]
      )
    )) as FeatureCollection;

    if (basemap && basemap !== 'none') {
      this.leafletService.L.Control.prototype._refocusOnMap =
        function _refocusOnMap(ev) {
          if (this._map && ev && ev.screenX > 0 && ev.screenY > 0) {
            this._map.getContainer().focus({ preventScroll: true });
          }
        };

      map = this.leafletService.L.map('mapcontainer', {
        scrollWheelZoom: true,
        zoomControl: true,
        keyboard: false,
      }).fitBounds(bounds);

      // CASE 1: special case where basemap is natural landmark
      if (basemap == 'natural_landmark') {
        this.leafletService.L.tileLayer(
          'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
          {
            attribution:
              '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
          }
        ).addTo(map);
      }

      // CASE 2
      /*
      if schema says to show map labels on top of overlay tiles,
      then create two separate tile layers: one with the map w/o
      labels, and one layer of just labels. The layer label is pushed
      to the very top.
      */
      else if (this.config.labels_on_top) {
        // add map without labels layer
        this.leafletService.L.tileLayer(
          'https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png',
          {
            attribution: '©OpenStreetMap, ©CartoDB',
          }
        ).addTo(map);

        // create only labels layer and push to top
        map.createPane('labels');
        map.getPane('labels').style.zIndex = 650;
        map.getPane('labels').style.pointerEvents = 'none';

        // add this layer to the map
        this.leafletService.L.tileLayer(
          'https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png',
          {
            attribution: '©OpenStreetMap, ©CartoDB',
            pane: 'labels',
          }
        ).addTo(map);
      }

      // CASE 3: regular case: not a landmark, nor show labels on top
      else {
        this.leafletService.L.tileLayer(
          'http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
          {
            attribution:
              "Map data © <a href='http://openstreetmap.org'>OpenStreetMap</a>",
          }
        ).addTo(map);
      }

      this.leafletService.L.svg({ clickable: true }).addTo(map);
      const overlay = d3.select(map.getPanes().overlayPane);
      svg = overlay.select('svg').attr('pointer-events', 'auto');

      // adjust opacity of overlayed, colored tiles based on value in schema
      overlay
        .select('svg')
        .style('opacity', this.config.opacity ? this.config.opacity : 0.85);

      map.invalidateSize();
      this.map = map;

      if (this.config.data.length == 0) {
        this.drawUnavailableMap(map);
        return;
      }
    } else {
      svg = d3.select('#SVG');
    }

    const projectPoint = function (x, y) {
      const point = map.latLngToLayerPoint(
        new self.leafletService.L.LatLng(y, x)
      );
      this.stream.point(point.x, point.y);
    };

    let projection;

    if (basemap && basemap !== 'none') {
      projection = d3.geoTransform({ point: projectPoint });
    } else {
      projection = d3
        .geoIdentity()
        .scale(scaleAdjust)
        .reflectY(this.config.flip_y);
      projection.fitExtent(
        [
          [margin.left, margin.top],
          [width - margin.right, height - margin.bottom],
        ],
        featureCollection
      );
    }

    const normalized = false;

    const path = d3.geoPath().projection(projection);
    let values = featureCollection.features.reduce(function (filtered, d) {
      if (d.properties.value) {
        filtered.push(d.properties.value);
      }
      return filtered;
    }, []);

    // change these two
    let middleColor, middleValue;
    if (scheme == 'divergent') {
      middleColor =
        (<ColorSchemeDivergent>this.block.color_scheme.config).middle_color ||
        'white';
      middleValue =
        this.block.color_scheme.options.divergent_middle_value == null
          ? d3.median(values)
          : this.block.color_scheme.options.divergent_middle_value;
    }

    const colorScheme = this.createColorScheme(values);

    values = values.sort(function (a, b) {
      return a - b;
    });

    const tip = this.createMapTooltip(toolTipKeys, 'name');

    svg.call(tip);

    const g = svg
      .append('g')
      .attr('width', width)
      .attr('height', height)
      .attr('class', 'leaflet-zoom-hide');

    const areaPaths = g
      .selectAll('path')
      .data(featureCollection.features)
      .enter()
      .append('path')
      .attr('d', path)
      .attr('pointer-events', 'auto')
      .style('pointer-events', 'auto')
      .attr('stroke', this.config.stroke_color)
      .attr('class', 'state')
      .attr('fill', function (d, i) {
        if (d.properties.value == null) {
          return 'white';
        }

        return colorScheme(d.properties.value);
      })
      .attr('fill-opacity', basemap && basemap !== 'none' ? 0.85 : 1)
      .on('mouseover', function (d) {
        if ((<BlockGeoviz>self.config).show_tooltips) {
          if (!clickFlag) {
            const centroid = self.getBoundingBoxCenter(this);
            const direction = self.getTooltipDirection(
              centroid[0],
              width,
              isMobile
            );

            tip.direction(direction).show(d.properties, this);
            tooltipShown.emit(true);
          }

          d3.select(this)
            .style('fill', '#808080')
            .classed('hover-county', true);
          ga.blockEventEmitter(
            'Interaction',
            'interaction',
            globalThis.vizCollection +
              '/' +
              globalThis.vizType +
              '/' +
              'geoviz',
            null,
            0,
            {
              InteractionType: 'mouseOver',
              Collection: globalThis.vizCollection,
              vizType: globalThis.vizType,
              block: 'geoviz',
            },
            true
          );
        }
      })
      .on('mouseout', function (d, i) {
        if ((<BlockGeoviz>self.config).show_tooltips) {
          d3.select(this)
            .style('fill', () => {
              if (d.properties.value == null) {
                return 'white';
              }
              return colorScheme(d.properties.value);
            })
            .classed('hover-county', false);
          if (!clickFlag) {
            tip.hide();
            tooltipShown.emit(false);
          }
        }
      })
      .on('click', function (d) {
        if (clickFlag) {
          clickFlag = false;
          tip.hide();
        } else {
          clickFlag = true;
          const centroid = self.getBoundingBoxCenter(this);
          const direction = self.getTooltipDirection(
            centroid[0],
            width,
            isMobile
          );

          tip.direction(direction).show(d.properties, this);
          tooltipShown.emit(true);
        }
      });

    if (!isMobile) {
      svg.on('click', function (d) {
        const outside = svg.selectAll('*').filter(function () {
          return this == d3.event.target;
        });
        if (outside.empty()) {
          clickFlag = false;
          tip.hide();
          areaPaths.style('fill', function (d) {
            if (d.properties.value == null) {
              return 'white';
            }
            return colorScheme(d.properties.value);
          });
        }
      });
    }

    if (isMobile && basemap !== 'null' && basemap !== 'none') {
      areaPaths.on('click', function (d) {
        if ((<BlockGeoviz>self.config).show_tooltips) {
          tooltipShown.emit(true);
          const centroid = self.getBoundingBoxCenter(this);
          const direction = self.getTooltipDirection(
            centroid[0],
            width,
            isMobile
          );
          tip.direction(direction).show(d.properties, this);
        }
      });

      svg.on('click', function (d) {
        if ((<BlockGeoviz>self.config).show_tooltips) {
          if (d3.select(d3.event.target).classed('state')) {
            areaPaths.style('fill', function (d) {
              if (d.properties.value == null) {
                return 'white';
              }
              return colorScheme(d.properties.value);
            });
            d3.select(d3.event.target).style('fill', '#808080');
          }
        } else {
          if ((<BlockGeoviz>self.config).show_tooltips) {
            tooltipShown.emit(false);
            tip.hide();
            areaPaths.style('fill', function (d) {
              if (d.properties.value == null) {
                return 'white';
              }
              return colorScheme(d.properties.value);
            });
          }
        }
      });

      // remove tooltip if on mobile and click outside of graph
      d3.select('body').on('click', function () {
        const outside = svg.selectAll('*').filter(function () {
          return this == d3.event.target;
        });
        if (outside.empty()) {
          tip.hide();
          areaPaths.style('fill', function (d) {
            if (d.properties.value == null) {
              return 'white';
            }
            return colorScheme(d.properties.value);
          });
        }
      });
    }
    if (showLabels) {
      const labels = g
        .selectAll('text')
        .data(featureCollection.features)
        .enter()
        .append('text')
        .attr('class', 'zip-labels')
        .text(function (d) {
          return d['id'];
        })
        .attr('x', function (d) {
          return path.centroid(d as any)[0];
        })
        .attr('y', function (d) {
          return path.centroid(d as any)[1];
        })
        .attr('text-anchor', 'middle')
        .style('font-family', 'Lato sans-serif')
        .attr(
          'visibility',
          basemap && basemap !== 'null' && basemap !== 'none'
            ? map.getZoom() > 11
              ? 'visible'
              : 'hidden'
            : 'visible'
        );
    }

    function update() {
      areaPaths.attr('d', path);

      if (showLabels) {
        const labels = d3.selectAll('.zip-labels');
        if (map.getZoom() > 11) {
          labels
            .attr('visibility', 'visible')
            .attr('x', function (d) {
              return path.centroid(d as any)[0];
            })
            .attr('y', function (d) {
              return path.centroid(d as any)[1];
            });
        } else {
          labels.attr('visibility', 'hidden');
        }
      }
    }

    if (this.map) {
      map.on('zoom', update);
    }
    if (scheme == 'categorical') {
      // extract and format legend keys from schema
      const legendKeys = this.block.categories.map(function (d, i) {
        return {
          label: d,
          color: (<ColorSchemeCategorical>self.block.color_scheme.config)
            .colors[i],
        };
      });

      // draw horizontal legend onto SVG
      this.drawHorizontalLegend(legendKeys, width, true);

      // get legend height + add 20 pixels for spacing on bottom
      const legendHeight = this.getCategoricalLegendHeight() + 20;

      /*
      apply legend height to container. Without this, the map container
      will cut off the legend
      */
      d3.select('#legend').style('height', legendHeight);

      /*
      apply legend height to map container. Without this, the map
      container will overflow it's parent and be cutoff on bottom
      */
      d3.select('#mapcontainer').style(
        'height',
        'calc(100% - ' + legendHeight + 'px)'
      );
    } else {
      this.drawColorLegend(
        width,
        values,
        colorScheme,
        this.config.legend.percent
      );
    }
  }
}

<ui-modal
  title="Logout"
  width="450px"
  height="auto"
  (onCloseModal)="onClickClose()"
>
  <div class="wrapper">
    <div class="modal-copy">
      <h2>Are you sure you want to logout?</h2>
      <p>You will have to sign in again to use Dataherald.</p>
    </div>
    <div class="options-buttons">
      <div class="btn-filled" (click)="onClickLogout()">
        <p>Yes, I'm sure</p>
      </div>
      <div class="btn-outline" (click)="onClickClose()">
        <p>Take me back</p>
      </div>
    </div>
  </div>
</ui-modal>

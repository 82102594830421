<div id="app">
  <ng-container *ngIf="loading$ | async; else loaded">
    <div class="loading-container">
      <nz-spin nzSize="large"></nz-spin>
    </div>
  </ng-container>
  <ng-template #loaded>
    <router-outlet></router-outlet>
  </ng-template>
</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { visualizationCacheBuster } from '../constants/cache';
import { EnvConfig, EnvConfigService } from '../injectables/env-config';
import { Parameter, Parameters, ParameterType } from '../models/parameter';
import {
  Visualization,
  VisualizationData,
  VisualizationList,
  VisualizationListItem,
  VisualizationSummary,
} from '../models/Visualization';

@Injectable({
  providedIn: 'root',
})
export class VisualizationService {
  CONTROLLER_URL: string;

  constructor(
    private http: HttpClient,
    @Inject(EnvConfigService) private envConfig: EnvConfig
  ) {
    this.CONTROLLER_URL = `${this.envConfig.api_URL}/viz`;
  }

  @Cacheable({
    cacheBusterObserver: visualizationCacheBuster,
  })
  getList(
    queryParams: VisualizationListRequestParams
  ): Observable<VisualizationResponse> {
    const url = `${this.CONTROLLER_URL}/list`;
    const params = new HttpParams({
      fromObject: queryParams,
    });
    return this.http.get<VisualizationResponse>(url, { params });
  }

  @Cacheable({
    cacheBusterObserver: visualizationCacheBuster,
  })
  getFeaturedList(): Observable<VisualizationListItem> {
    const url = `${this.CONTROLLER_URL}/featured`;
    return this.http.get<VisualizationListItem>(url);
  }

  @Cacheable({
    cacheBusterObserver: visualizationCacheBuster,
  })
  getSummary(
    vizId: string,
    bypassCache = false
  ): Observable<VisualizationSummary> {
    const url = `${this.CONTROLLER_URL}/${vizId}/summary`;
    const params = bypassCache ? { nocache: 1 } : {};
    return this.http.get<VisualizationSummary>(url, { params });
  }

  @Cacheable({
    cacheBusterObserver: visualizationCacheBuster,
  })
  getParameters(vizId: string): Observable<Parameters> {
    const url = `${this.CONTROLLER_URL}/${vizId}/parameters`;
    return this.http.get<Parameter[]>(url);
  }

  @Cacheable({
    cacheBusterObserver: visualizationCacheBuster,
  })
  getData(vizId: string, bypassCache = false): Observable<VisualizationData> {
    const url = `${this.CONTROLLER_URL}/${vizId}/data`;
    const params = bypassCache ? { nocache: 1 } : {};
    return this.http.get<VisualizationData>(url, { params });
  }

  @Cacheable({
    cacheBusterObserver: visualizationCacheBuster,
  })
  getCSV(vizId: string): Observable<string> {
    const url = `${this.CONTROLLER_URL}/${vizId}/csv`;
    return this.http.get(url, { responseType: 'text' });
  }

  /**
   * @param vizId
   * @param parametersValues data parameters that changed to determine the new categories for the viz
   * @returns list of viz current categories for the parameter value changes provided.
   */
  getDataCategories(
    vizId: string,
    parametersValues: VisualizationParametersValues
  ): Observable<string[]> {
    const url = `${this.CONTROLLER_URL}/${vizId}/categories`;
    return this.http.patch<string[]>(url, parametersValues);
  }

  getPNG(vizID: string, width: number, height: number): string {
    return (
      `${this.envConfig.api_v2_URL}/screenshot?` +
      `size=${width}x${height}&url=${this.envConfig.embed_client_URL}/viz/${vizID}?hideInfoDialog=true`
    );
  }

  isDemo(vizId: string): Observable<boolean> {
    const url = `${this.CONTROLLER_URL}/${vizId}/is-demo`;
    return this.http.get<boolean>(url);
  }

  patchParameters(
    vizId: string,
    parametersValues: VisualizationParametersValues
  ): Observable<Visualization> {
    const url = `${this.CONTROLLER_URL}/${vizId}/parameters`;
    return this.http.patch<Visualization>(url, parametersValues);
  }

  @CacheBuster({
    cacheBusterNotifier: visualizationCacheBuster,
  })
  updateParameters(
    vizId: string,
    parametersValues: VisualizationParametersValues = {}
  ): Observable<Visualization> {
    const url = `${this.CONTROLLER_URL}/${vizId}/parameters`;
    return this.http.put<Visualization>(url, parametersValues);
  }

  @CacheBuster({
    cacheBusterNotifier: visualizationCacheBuster,
  })
  createFromDataFeed(
    dataFeedConfig: DataFeedParametersValues
  ): Observable<{ id: string }> {
    const { dataFeedId, parametersValues = {} } = dataFeedConfig;
    const url = `${this.CONTROLLER_URL}/create/${dataFeedId}`;
    return this.http.post<{ id: string }>(url, parametersValues);
  }

  @CacheBuster({
    cacheBusterNotifier: visualizationCacheBuster,
  })
  duplicate(visualizationId: string, body = {}): Observable<{ id: string }> {
    const url = `${this.CONTROLLER_URL}/${visualizationId}/duplicate`;
    return this.http.post<{ id: string }>(url, body);
  }

  @CacheBuster({
    cacheBusterNotifier: visualizationCacheBuster,
  })
  delete(vizID: string): Observable<void> {
    const url = `${this.CONTROLLER_URL}/${vizID}`;
    return this.http.delete<void>(url);
  }
}

export type DataFeedParametersValues = {
  dataFeedId: string;
  parametersValues?: VisualizationParametersValues;
};

export type VisualizationParametersRequest = {
  vizId: string;
  parametersValues: VisualizationParametersValues;
};

export type VisualizationParametersValues = {
  [parameterName: string]: ParameterType;
};

export type VisualizationListRequestParams = {
  creator?: string;
  organization?: string;
  offset?: number;
  query?: string;
  sort?: string;
  topic?: string;
  source?: string;
};

export type VisualizationResponse = {
  topics: CountByTopic;
  visualizations: VisualizationList;
};

export type CountByTopic = {
  [topicName: string]: number;
};

<div
  id="search-input"
  class="{{ size }} {{ color }}"
  [ngClass]="{ searching: !!query }"
>
  <nz-input-group
    [nzSize]="size"
    [nzSuffix]="!query ? suffixIconSearch : suffixIconClear"
  >
    <input
      type="text"
      nz-input
      [nzSize]="size"
      [placeholder]="placeholder"
      (keyup.enter)="query && onKeyUpEnter.emit()"
      [(ngModel)]="query"
      (ngModelChange)="queryChange.emit($event)"
    />
  </nz-input-group>
  <ng-template #suffixIconSearch>
    <img src="shared-assets/icons/search-black-icon.svg" alt="" />
  </ng-template>
  <ng-template #suffixIconClear>
    <img
      class="pointer"
      src="shared-assets/icons/close.svg"
      alt=""
      (click)="query = null; queryChange.emit(null)"
    />
  </ng-template>
</div>

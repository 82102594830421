import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvConfig, EnvConfigService } from '../injectables/env-config';
import { UserDetails } from '../models/user';
import { AuthType } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  CONTROLLER_URL: string;

  constructor(
    @Inject(EnvConfigService) private envConfig: EnvConfig,
    private http: HttpClient
  ) {
    this.CONTROLLER_URL = `${this.envConfig.api_URL}/user`;
  }

  login(): Observable<UserDetails> {
    const url = `${this.CONTROLLER_URL}/login`;
    return this.http.post<UserDetails>(url, {});
  }

  createUserInOrganization(
    organizationId: string,
    payload: UserInOrganizationPOST
  ): Observable<UserDetails> {
    const url = `${this.CONTROLLER_URL}/new/${organizationId}`;
    return this.http.post<UserDetails>(url, payload);
  }

  createUserTrial(payload: UserTrialPOST): Observable<UserDetails> {
    const url = `${this.CONTROLLER_URL}/trial`;
    return this.http.post<UserDetails>(url, payload);
  }

  /**
   * Collects the email address of mobile users
   * @param email the email entered by the user
   * @param navigationUrl the full URL the user was trying to navigate to
   */
  collectEmail(email: string, navigationUrl: string): Observable<void> {
    const url = `${this.CONTROLLER_URL}/mobile_email`;
    return this.http.post<void>(url, { email, url: navigationUrl });
  }

  update(userId: string, payload: UserPUT): Observable<UserDetails> {
    const url = `${this.CONTROLLER_URL}/${userId}`;
    return this.http.put<UserDetails>(url, payload);
  }

  delete(userId: string): Observable<void> {
    const url = `${this.CONTROLLER_URL}/${userId}`;
    return this.http.delete<void>(url);
  }
}

export type UserInOrganizationPOST = {
  auth_type: AuthType;
  email: string;
  first_name?: string;
  image_url?: string;
  last_name?: string;
  password?: string;
};

export type UserTrialPOST = {
  email: string;
  newsletter: boolean;
};

export type UserPUT = {
  auth_type?: AuthType;
  finished_setup?: boolean;
  first_name?: string;
  image_url?: string;
  last_name?: string;
  notifications_by_default?: boolean;
  org_id?: string;
  password?: string;
};

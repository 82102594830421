import { Component, OnInit, Input } from '@angular/core';
import { AppService, GraphSize } from '../../../services/app.service';
import { AnalyticsService } from 'hg-front-core';

export interface Orientation {
  left: boolean;
  right: boolean;
  top: boolean;
  bottom: boolean;
}

@Component({
  selector: 'app-avatar-block',
  templateUrl: './avatar-block.component.html',
  styleUrls: ['./avatar-block.component.scss'],
})
export class AvatarBlockComponent implements OnInit {
  @Input() blockData: any;
  orientation = 'left';

  url: string;
  name: string;
  subText: string;
  verticalAlign;
  textColor = 'blue';
  subTextColor = 'gray';
  nameFont = 20;
  subtitleFont = 38;
  defaultUrl = 'assets/images/no-image.png';
  isMobile: boolean = null;

  constructor(
    private analyticsService: AnalyticsService,
    private appService: AppService
  ) {}

  ngOnInit() {
    this.appService.getIsMobile().subscribe((isMobile) => {
      if (this.isMobile === null) {
        this.isMobile = isMobile;
      } else {
        this.isMobile = isMobile;
      }
    });

    this.url = this.blockData.imageUrl;
    this.orientation = this.blockData.orientation.toLowerCase();
    // this.orientationCanvas.left = this.blockData.orientation == "Left" ? true : false;
    //this.orientationCanvas.right = !this.orientationCanvas.left;

    this.name = this.blockData.title.text;

    this.subText = this.blockData.subtitle?.text;

    this.verticalAlign = this.blockData.title.verticalAlign || 'center';

    const colorRef = this.blockData.title.color;
    const subColorRef = this.blockData.subtitle?.color;
    this.textColor = this.blockData.theme[colorRef];
    this.subTextColor = this.blockData.theme[subColorRef];

    if (this.isMobile) {
      this.verticalAlign = 'center';
    }
    //     if (window.innerWidth < 750) {
    //       if (window.innerWidth < 500) {
    //         this.nameFont = this.nameFont * 0.6
    //         this.subtitleFont = this.subtitleFont * 0.6
    //       } else {
    //         this.nameFont = this.nameFont * 0.8
    //         this.subtitleFont = this.subtitleFont * 0.8
    //       }
    //     }
    // console.debug('in avatar block')
  }

  hoverAvatar(): void {
    this.analyticsService.blockEventEmitter(
      'Avatar',
      'hover',
      'Avatar',
      null,
      0,
      { Collection: globalThis.vizCollection, vizType: globalThis.vizType },
      true
    );
  }

  get styles(): any {
    return {
      'background-image': `url(${this.url || this.defaultUrl})`,
    };
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import {
  AnalyticsService,
  VisualizationService,
  VisualizationSummary,
} from 'hg-front-core';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  getEmbedLink,
  getIframe,
} from '../../../visualization/utils/embed-visualization.utils';

const getDownloadTitle = (vizSummary: VisualizationSummary) =>
  vizSummary.title.replace(/\s/g, '-').replace(/\./g, '');

const ALERT_TIMEOUT = 2000;

@Component({
  selector: 'app-viz-export-modal',
  templateUrl: './viz-export-modal.component.html',
  styleUrls: ['./viz-export-modal.component.scss'],
})
export class VizExportModalComponent implements OnChanges {
  @Input()
  vizSummary: VisualizationSummary;

  @Input()
  zIndex: number;

  @Input()
  set opened(value: boolean) {
    this._opened = value;
  }

  @Output()
  openedChange = new EventEmitter<void>();

  _opened = false;

  EXPORT_TABS = EXPORT_TABS;
  activeTab = EXPORT_TABS.EMBED;

  sizePNG = {
    width: 900,
    height: 700,
  };
  previewLink: string;
  embedCode: string;

  previewCopied = false;
  embedCopied = false;
  exportingPNG = false;
  exportingCSV = false;

  constructor(
    public mediaObserver: MediaObserver,
    private analytics: AnalyticsService,
    private messageService: NzMessageService,
    private visualizationAPIService: VisualizationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.vizSummary?.currentValue) {
      this.previewLink = getEmbedLink(this.vizSummary.id);
      this.embedCode = getIframe(this.vizSummary);
    }
  }

  onClickClose(): void {
    this.openedChange.emit();
  }

  updateTab(selectedTabIndex: number): void {
    this.activeTab = selectedTabIndex;
  }

  mobilePreviewCopied(): void {
    this.messageService.success('Preview link copied to clipboard!');
  }

  previewLinkCopied(): void {
    this.previewCopied = true;
    const timer = setTimeout(() => {
      this.previewCopied = false;
      clearTimeout(timer);
    }, ALERT_TIMEOUT);
    this.analytics.v4EventEmitter({
      eventName: 'preview-link-copied',
      vizSummary: this.vizSummary,
      extraProperties: {
        'copied-string': this.previewLink,
      },
    });
  }

  mobileEmbedCodeCopied(): void {
    this.messageService.success('Embed code copied to clipboard!');
  }

  embedCodeCopied(): void {
    this.embedCopied = true;
    const timer = setTimeout(() => {
      this.embedCopied = false;
      clearTimeout(timer);
    }, ALERT_TIMEOUT);
    this.analytics.v4EventEmitter({
      eventName: 'embed-code-copied',
      vizSummary: this.vizSummary,
      extraProperties: {
        'copied-string': this.embedCode,
      },
    });
  }

  async downloadPNG() {
    if (this.exportingPNG) return;
    this.exportingPNG = true;
    const imgURL = this.visualizationAPIService.getPNG(
      this.vizSummary.id,
      this.sizePNG.width,
      this.sizePNG.height
    );
    const title = getDownloadTitle(this.vizSummary);
    const image = await fetch(imgURL);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement('a');
    link.href = imageURL;
    link.download = `${title}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.exportingPNG = false;
    this.messageService.success(
      'Visualization PNG image successfully downloaded!'
    );

    this.analytics.v4EventEmitter({
      eventName: 'export-viz',
      vizSummary: this.vizSummary,
      extraProperties: {
        type: 'image',
        extension: 'png',
        width: this.sizePNG.width,
        height: this.sizePNG.height,
      },
    });
  }

  downloadCSV(): void {
    if (this.exportingCSV) return;
    this.exportingCSV = true;
    const title = getDownloadTitle(this.vizSummary);
    this.visualizationAPIService
      .getCSV(this.vizSummary.id)
      .subscribe((csvData: string) => {
        const anchor = document.createElement('a');
        anchor.href = `data:attachment/csv;charset=utf-8,${encodeURI(csvData)}`;
        anchor.target = '_blank';
        anchor.download = `${title}.csv`;
        anchor.click();
        this.exportingCSV = false;
        this.messageService.success(
          'Visualization CSV data successfully downloaded!'
        );
        this.analytics.v4EventEmitter({
          eventName: 'export-viz',
          vizSummary: this.vizSummary,
          extraProperties: {
            type: 'data',
            extension: 'csv',
          },
        });
      });
  }
}

enum EXPORT_TABS {
  EMBED = 0,
  IMAGE = 1,
  DATA = 2,
}

import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  AfterViewChecked,
} from '@angular/core';

@Component({
  selector: 'app-pill-block',
  templateUrl: './pill-block.component.html',
  styleUrls: ['./pill-block.component.scss'],
})
export class PillBlockComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  @ViewChild('pillBlock', { read: ElementRef }) pillBlock: ElementRef;
  @Input() blockData: any;
  data: any = [];
  totalWidth = '100%';

  constructor() {}

  ngOnInit(): void {
    const { max, theme, data } = this.blockData;
    const total = data.reduce((sum, d) => sum + d.value, 0) || 1;

    const getJustifyContent = (i) => {
      if (i === 0) return 'flex-start';
      if (i === data.length - 1) return 'flex-end';
      return 'center';
    };

    this.totalWidth = max <= 0 ? '100%' : `${(100 * total) / max}%`;
    let left = 0;
    this.data = data.map((segment, i) => {
      const leftPos = `${left}%`;
      const width = (100 * segment.value) / total;
      left += width;
      return {
        ...segment,
        style: {
          left: leftPos,
          width: `${width}%`,
          backgroundColor:
            theme[segment.color] || theme.accents[Number(segment.color)],
          justifyContent: getJustifyContent(i),
        },
      };
    });
  }

  ngAfterViewInit(): void {}

  ngAfterViewChecked(): void {
    this.updateClass();
  }

  updateClass() {
    if (this.pillBlock) {
      const elem = this.pillBlock.nativeElement;
      if (!elem) return;

      const width = elem.getClientRects()[0].width;
      const classNames = elem.className.split(' ');

      if (width < 150) {
        if (!classNames.includes('no-text')) {
          elem.className = [...classNames, 'no-text'].join(' ');
        }
      } else if (width < 250) {
        const cName =
          this.data[0].value > this.data[1].value ? 'left-text' : 'right-text';
        if (!classNames.includes(cName)) {
          elem.className = [
            ...classNames.filter((c) => c !== 'no-text'),
            cName,
          ].join(' ');
        }
      } else {
        elem.className = classNames
          .filter((c) => !['no-text', 'left-text', 'right-text'].includes(c))
          .join(' ');
      }
    }
  }
}

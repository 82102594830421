<ng-container *ngIf="vizData && vizSummary">
  <div class="wrapper">
    <app-shared-header [header]="vizSummary"></app-shared-header>

    <ng-container *ngIf="vizSummary.takeaway_tiles_count > 0">
      <app-smart-tiles [data]="vizData.takeaway_tiles"></app-smart-tiles>
    </ng-container>

    <ng-container
      *ngIf="vizSummary.ui_control_exists && vizData.ui_controls?.length > 0"
    >
      <app-uicontrol
        class="text-center"
        [controlData]="vizData.ui_controls"
        (onControlChange)="userChangedControlValue($event)"
      ></app-uicontrol>
    </ng-container>

    <main class="col mainContent">
      <app-tabbed-map-table-template [block]="vizData.block" class="height100">
      </app-tabbed-map-table-template>
      <ng-container
        *ngIf="vizSummary.takeaway_box_exists && vizData.takeaway_box"
      >
        <app-takeaway-box [data]="vizData.takeaway_box"></app-takeaway-box>
      </ng-container>
    </main>

    <app-shared-footer
      [data]="vizSummary"
      [hideEditReferral]="true"
    ></app-shared-footer>
  </div>
</ng-container>

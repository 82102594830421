<div class="checkbox-container">
  <div
    class="selection-circle"
    [ngClass]="
      isChecked ? 'selection-circle-checked' : 'selection-circle-not-checked'
    "
    [style.cursor]="canInteract ? 'pointer' : 'auto'"
    (click)="onClick()"
  >
    <img
      [src]="
        'shared-assets/icons/' +
        (isChecked ? 'check-toggle-white-icon' : 'plus-black-icon') +
        '.svg'
      "
    />
  </div>
  <img *ngIf="image_url" [src]="image_url" />
  <p>{{ text }}</p>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input()
  title: string;

  @Input()
  titleSize: 'default' | 'small' = 'default';

  @Input()
  titleIcon: string;

  @Input()
  height = '400px';

  @Input()
  width = '400px';

  @Input()
  zIndex: number;

  @Input()
  sideCoverImageUrl: string;

  @Input()
  set appLogo(value: boolean) {
    if (value !== undefined && value !== false) {
      this._appLogo = true;
    } else {
      this._appLogo = value;
    }
  }

  @Output()
  onCloseModal? = new EventEmitter<void>();

  _appLogo = false;

  constructor() {}

  onClickClose(): void {
    this.onCloseModal.emit();
  }
}

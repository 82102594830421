import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvConfig, EnvConfigService } from '../injectables/env-config';

@Injectable({
  providedIn: 'root',
})
export class OrganizationAnalyticsService {
  CONTROLLER_URL: string;

  constructor(
    private http: HttpClient,
    @Inject(EnvConfigService) private envConfig: EnvConfig
  ) {
    this.CONTROLLER_URL = `${this.envConfig.api_URL}/analytics`;
  }

  /**
   * @returns Statistics for the number of overall visualizations deployed over the last 30 days for all API versions.
   */
  getOverallDeployedVizStats(
    queryParams: {
      date?: string;
    } = {}
  ): Observable<DeployedVizStats> {
    const url = `${this.CONTROLLER_URL}/deployed_viz_stats`;
    const params = new HttpParams({
      fromObject: queryParams,
    });
    return this.http.get<DeployedVizStats>(url, { params });
  }

  /**
   * @returns Statistics for the number of visualizations deployed over the last 30 days for all API versions for a specific organization
   */
  getDeployedVizStats(
    organizationId: string,
    queryParams: {
      date?: string;
    } = {}
  ): Observable<DeployedVizStats> {
    const url = `${this.CONTROLLER_URL}/deployed_viz_stats/${organizationId}`;
    const params = new HttpParams({
      fromObject: queryParams,
    });
    return this.http.get<DeployedVizStats>(url, { params });
  }

  /**
   * Downloads a CSV file summarizing the number of visualizations deployed by organization for the last 30 days
   */
  getDeployedVizCSV(): void {
    const date = new Date();
    const title =
      'total-deployments-monthof' +
      (date.getMonth() + 1) +
      '-' +
      date.getDate() +
      '-' +
      date.getFullYear();
    const url = `${this.CONTROLLER_URL}/deployed_viz_csv`;
    // const token = 'my JWT';
    // const headers = new HttpHeaders().set('authorization', 'Bearer ' + token);
    // .get(url, { headers, responseType: 'blob' as 'json' })
    this.http
      .get(url, { responseType: 'text' })
      .subscribe((csvData: string) => {
        const anchor = document.createElement('a');
        anchor.href = `data:attachment/csv;charset=utf-8,${encodeURI(csvData)}`;
        anchor.target = '_blank';
        anchor.download = `${title}.csv`;
        anchor.click();
      });
  }
}

export type DeployedVizStats = {
  date: string;
  days: number;
  new: VizStats;
  total: VizStats;
};

export type VizStats = {
  all: number;
  v1: number | null;
  v2: number | null;
  v3: number | null;
  v4: number | null;
};

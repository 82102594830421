import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from 'hg-front-core';
import AppSettings from '../../../../settings/app.settings';

@Component({
  selector: 'app-unsupported-device',
  templateUrl: './unsupported-device.component.html',
  styleUrls: ['./unsupported-device.component.scss'],
})
export class UnsupportedDeviceComponent implements OnInit {
  @Input()
  page: 'workspace' | 'discover' | "what's new" = 'discover';

  WEBSITE_URL = AppSettings.website_url;

  constructor(private analytics: AnalyticsService, private router: Router) {}

  goToBuild(): void {
    this.analytics.dhEvent('go-to-create-graph-button', this.page);
    this.router.navigate(['/visualization/create']);
  }

  ngOnInit(): void {}
}

<div
  class="figure-container" [ngClass] = "{'centered': blockData.centerMobile}"
  [ngStyle]="{
    width: this.sizeCanvas.width + 'px',
    height: this.sizeCanvas.height + 'px'
  }"
  [style.text-align]="'justify'"
>
<div class="figure-info">
  <p
        class="figure-text"
        [style.font-size]="this.nameFont + 'px'"
        [ngStyle]="{ color: this.textColor }"
      >
      {{ figureText }}
    </p>
  <p
    class="figure-subtext"
    [style.font-size]="this.subtitleFont + 'px'"
    [ngStyle]="{ color: this.subTextColor }"
    *ngIf="subText !== '' && subText != null"
  >
    {{ subText }}
  </p>
  </div>
</div>

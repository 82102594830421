import { Component, OnInit, SkipSelf } from '@angular/core';
import * as d3 from 'd3';
import { AnalyticsService, BlockAxis } from 'hg-front-core';
import BLOCK_ERRORS from '../../../constants/block-errors';
import { AppService } from '../../../services/app.service';
import { AxisBlockComponent } from '../axis-block/axis-block.component';
@Component({
  selector: 'app-stacked-barchart-block',
  templateUrl: './stacked-barchart-block.component.html',
  styleUrls: ['./stacked-barchart-block.component.scss'],
})
export class StackedBarchartBlockComponent
  extends AxisBlockComponent
  implements OnInit
{
  constructor(
    @SkipSelf() public appService: AppService,
    private googleAnalayticsService: AnalyticsService
  ) {
    super(appService);
  }

  drawChart(): void {
    if (
      !this.block ||
      !this.size ||
      this.size.width === 0 ||
      this.size.height === 0
    )
      return;
    d3.selectAll('#SVG > *').remove();

    this.config = <BlockAxis>this.block.config;
    const self = this;
    const svg = d3.select('#SVG');
    const isMobile = this.isMobile;
    const width = this.size.width;
    const height = this.size.height;
    svg.attr('width', width).attr('height', height);
    const margin = {
      top: 55,
      right: isMobile ? 15 : 30,
      bottom: 40,
      left: 45,
    };
    const timeseries = this.config.xaxis.timeseries;
    const barKeys = this.block.categories;
    const rowBasedToolTip = this.config.row_based_tooltip;
    const scheme = this.block.color_scheme;

    const axisColor = 'black';

    const colorScheme = this.createColorScheme();

    let xScales;
    let x_bandwidth;
    let series;
    let xAxis;
    const stackSeries = this.convertDataPoints(this.config.data);

    if (!timeseries) {
      xScales = this.drawCategoricalXAxis(margin, width, height);
      x_bandwidth = xScales.bandwidth();

      svg
        .append('text') //x axis label for categorical
        .attr(
          'transform',
          'translate(' + width / 2 + ' ,' + (height + 25 - margin.bottom) + ')'
        )
        .style('text-anchor', 'middle')
        .attr('class', 'axis')
        .style('fill', '#62678B')
        .text(this.config.xaxis.label);
    } else {
      series = d3.stack().keys(barKeys)(<any>stackSeries);

      x_bandwidth = d3
        .scaleBand<Date>()
        .domain(this.config.data.map((d) => <Date>d.x))
        .range([margin.left, width - margin.right])
        .padding(0.1)
        .bandwidth();
      xScales = this.drawXAxis(
        margin,
        width - x_bandwidth / 2,
        height,
        x_bandwidth
      );
    }

    const maxNum = d3.max(series, (d) => d3.max(d as any, (d) => d[1]));
    const scale = this.drawYAxis(0, maxNum, height, margin, width, true);

    let tip; // add createToolTip
    const multirow = false;

    if (!rowBasedToolTip) {
      tip = this.createToolTip(this.block.categories, 'x');
    } else {
      tip = this.createRowBasedTooltip(this.block.categories, 'x');
    }
    svg.call(tip);

    if (isMobile && !timeseries) {
      d3.selectAll('.x-axis .tick text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', 'rotate(-65)');
    }

    const ga = this.googleAnalayticsService;

    svg
      .append('g')
      .selectAll('g')
      .data(series as any[])
      .join('g')
      .attr('fill', (d, i) => colorScheme(d.key))
      .selectAll('rect')
      .data((d) => d)
      .join('rect')
      .attr(
        'x',
        (d: any, i) =>
          xScales(timeseries ? d.data.x : new Date(d.x)) - x_bandwidth / 2
      )
      .attr('y', (d) => scale(d[1]))
      .attr('height', (d) => {
        if (scale(d[0]) - scale(d[1]) < 0) {
          throw new Error(BLOCK_ERRORS.NEGATIVE_BAR_HEIGHT);
        } else return scale(d[0]) - scale(d[1]) ? scale(d[0]) - scale(d[1]) : 0;
      })
      .attr('width', x_bandwidth)
      .on('mouseover', function (d: any) {
        const centroid = self.getBoundingBoxCenter(this);
        const width = (svg as any).node().clientWidth;
        const direction = self.getTooltipDirection(
          centroid[0],
          width,
          isMobile
        );
        tip.direction(direction).show(d.data, this);
        d3.select(this).attr('fill', 'lightgray');
        ga.blockEventEmitter(
          'Interaction',
          'interaction',
          globalThis.vizCollection +
            '/' +
            globalThis.vizType +
            '/' +
            'stackedBarChart',
          null,
          0,
          {
            InteractionType: 'mouseOver',
            Collection: globalThis.vizCollection,
            vizType: globalThis.vizType,
            block: 'stackedBarChart',
          },
          true
        );
      })
      .on('mouseout', function (d: any) {
        const dataKey = (<any>d3.select((<any>this).parentNode).datum()).key;
        tip.hide(d, this);
        d3.select(this).attr('fill', colorScheme(dataKey));
      });

    const legendKeys = []; //legend
    colorScheme.domain().forEach((key) => {
      legendKeys.push({ label: key, color: colorScheme(key) });
    });
    this.drawHorizontalLegend(legendKeys, width, false);
  }

  convertDataPoints(arr): any[] {
    const data = {};

    for (const d of arr) {
      data[d.x] = data[d.x] ?? {
        x: d.x,
      };
      data[d.x][d.category] = d.value;
    }

    return Object.values(data);
  }
}

import { BoundingBox } from './BoundingBox';

export type PartialOrganizations = PartialOrganization[];

export type PartialOrganization = {
  id: string;
  name: string;
  date_created: string;
  type: OrganizationType;
  subtype: OrganizationSubtype;
};

export type OrganizationDetails = {
  id: string;
  name: string;
  datafeeds: string[];
  date_created: string;
  default_values: OrganizationDefaultValues;
  subscription_tier: SubscriptionTier;
  type: OrganizationType;
  subtype: OrganizationSubtype;
};

export type OrganizationDefaultValues = {
  bounding_box: BoundingBox;
  categorical_colors: string[];
  city: string;
  county: string;
  high_color: string;
  high_gradient_color: string;
  high_mid_color: string;
  highlight_color: string;
  highlight_default_color: string;
  low_color: string;
  low_gradient_color: string;
  low_mid_color: string;
  middle_color: string;
  primary_color: string;
  state: string;
};

export type OrganizationType = 'organization' | 'individual';

export type OrganizationSubtype = 'newsroom' | 'internal' | 'other';

export type SubscriptionTier = {
  id: string;
  name: string;
  admin_access: boolean;
  datafeeds: string[];
  full_access: boolean;
};

export const isNewsroom = (
  organization: OrganizationDetails | PartialOrganization
): boolean => organization.subtype === 'newsroom';

<div class="footer {{ data?.footnote ? 'align-start' : 'align-center' }}">
  <div class="sources">
    <div class="source" *ngIf="data?.source" [style.display]="'flex'">
      <span EllipsisText *ngIf="data?.footnote" class="notes">
        <span>Note:</span>
        <span> {{ data.footnote }} </span>
        <span class="right"></span>
        <!--
        <span *ngFor="let source of data.sources">
          <span class="footer-nolink" *ngIf="source.text && !source.url">
            {{ source.text }}
          </span>
          <a
            (click)="clickSource(source.url)"
            class="footer-link"
            *ngIf="source.text && source?.url"
            [href]="source.url"
            target="_blank"
          >
            {{ source.text }}
          </a>
        </span>
      -->
      </span>
    </div>

    <div class="partner" *ngIf="data?.source?.image_url; else normalSource">
      <span class="label">With</span>
      <span class="logo">
        <a
          target="_blank"
          [href]="data?.source?.url"
          (click)="clickSource(data?.source?.url)"
        >
          <img
            class="partnerLogo"
            [src]="data.source?.image_url"
            [title]="data.source?.text"
          />
        </a>
      </span>

      <span class="right"></span>
    </div>

    <ng-template #normalSource>
      <div class="partner">
        <span class="label">Source:&nbsp;</span>
        <span class="singlePartner">
          <span
            class="footer-nolink source-text"
            *ngIf="data?.source?.text && !data.source?.url"
          >
            {{ data.source.text }}
          </span>
          <a
            (click)="clickSource(data.source.url)"
            class="footer-link source-text"
            *ngIf="data?.source?.text && data.source?.url"
            [href]="data?.source.url"
            target="_blank"
          >
            {{ data.source.text }}
          </a>
        </span>

        <span class="right"></span>
      </div>
    </ng-template>
  </div>

  <div>
    <div *ngIf="!hideDataheraldLogo" class="dataherald-logo" fxLayout="row" fxLayoutAlign="center center">
      <div>By</div>
      <img
        src="shared-assets/icons/higeorge-logo/dataherald-logo-symbol-color.svg"
        alt="dataherald logo"
      />
      <a target="_blank" href="https://dataherald.com/" (click)="clickHG()">
        Dataherald
      </a>
    </div>
    <a
      *ngIf="!hideEditReferral"
      class="edit-viz-link"
      [href]="vizEditorUrl"
      target="_blank"
      (click)="clickEditViz()"
    >
      <div>Explore this data</div>
    </a>
  </div>
</div>

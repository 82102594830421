import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  AnalyticsService,
  LoggedInUserService,
  UserDetails,
} from 'hg-front-core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss'],
})
export class SettingsModalComponent implements OnInit {
  @Output() onCloseModal? = new EventEmitter<void>();

  user: UserDetails;
  passwordVisible = false;

  constructor(
    private analytics: AnalyticsService,
    private loggedInUserService: LoggedInUserService
  ) {}

  ngOnInit(): void {
    this.analytics.dhEvent('open-settings-modal', 'settings-modal');
    this.loggedInUserService.user$.subscribe((user) => {
      this.user = user;
    });
  }

  onClickClose(): void {
    this.analytics.dhEvent('close-settings-modal', 'settings-modal');
    this.onCloseModal.emit();
  }

  onClickSave(): void {
    this.analytics.dhEvent('update-settings', 'settings-modal');
    this.loggedInUserService
      .updateUserInfo(this.user.id, this.user)
      .pipe(take(1))
      .subscribe(() => {
        this.onCloseModal.emit();
      });
  }
}

<div
  class="meta-data-item"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="5px"
>
  <img [src]="item.iconUrl" alt="" />
  <div>
    {{ item.text }}
  </div>
</div>

import { Directive, Input } from '@angular/core';
import { Block, BlockSegment } from 'hg-front-core';
import { GraphSize } from '../../../services/app.service';
import { BaseChartBlockComponent } from '../base-chart-block/base-chart-block.component';

@Directive()
export abstract class SegmentBlockComponent extends BaseChartBlockComponent {
  @Input()
  set block(data: Block) {
    this._block = data;
    this.config = <BlockSegment>this._block.config;

    this.drawChart();
  }

  get block(): Block {
    return this._block;
  }

  _size: GraphSize;
  @Input()
  set size(size: GraphSize) {
    this._size = size;
    this.drawChart();
  }
  get size(): GraphSize {
    return this._size;
  }
}

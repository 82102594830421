import * as d3 from 'd3';

export interface Range {
  min: number;
  max: number;
}

/*
Calculate the min and max values for the y axis. d3 will fill in the values in between
*/
export function calculateYAxisRange(
  annotation: string,
  minNum: number,
  maxNum: number,
  minZero: boolean
): Range {
  const padding = (maxNum - minNum) * 0.05;
  let ymax, ymin;

  if (annotation) {
    ymax = maxNum + 20; //axis padding (election graphs require larger padding for annotation)
    ymin = minNum - 20;
  } else {
    ymax = maxNum + padding;
    ymin = minNum - padding;
  }

  ymin = minZero ? 0 : ymin;

  return { min: minNum < 0 ? minNum - padding : ymin, max: ymax };
}

/*
Return the width in pixels of a text string given font and size.
*/
export function getTextWidth(
  text: string,
  fontFamily: string,
  fontSize: number
): number {
  // create element
  const el = document.createElement('text');

  // add styles passed by user
  el.style.font = fontFamily;
  el.style.fontSize = fontSize + 'px';
  el.innerHTML = text;

  // other styles
  el.style.height = 'auto';
  el.style.width = 'auto';
  el.style.position = 'absolute';
  el.style.whiteSpace = 'no-wrap';
  el.style.visibility = 'hidden';

  // retrieve width
  document.body.appendChild(el);
  const width = el.clientWidth;
  document.body.removeChild(el);

  return width;
}

/*
Return the longest string in an array of strings
*/
export function longestStrInArr(arr: string[]): string {
  let currLong = '';

  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i].length > currLong.length) {
      currLong = arr[i];
    }
  }

  return currLong;
}

/*
Format the ticks on the y-axis, mainly used if should append
stuff like $ sign or % to the number.
*/
export function calcYTickFormat(
  isDollar: boolean,
  isPercent: boolean,
  significantDigitShown = 2
) {
  if (isDollar)
    return (d) => (d >= 1 ? d3.format('$,~s')(d) : d3.format('$.2f')(d));
  else if (isPercent) return (d) => d + '%';
  else
    return (d) =>
      d >= 1 || d == 0
        ? d3.format(`,.${significantDigitShown}s`)(d)
        : d3.format(`.2f`)(d);
}

/*
Get the format of the legend ticks
*/
export function getLegendTickFormat(
  min: number,
  max: number,
  isDollar: boolean,
  isPercent: boolean,
  wholeNumber = false
): string {
  let format;
  if (wholeNumber) {
    return '';
  }
  if (
    Math.floor(max).toString().length > 5 ||
    Math.ceil(min).toString().length > 5
  ) {
    format = d3.format('.3s');
  } else if (max - min < 10) {
    format = d3.format('.2f');
  } else {
    format = function (v) {
      if (Math.abs(v) < 1) return d3.format('.2')(v);
      else return d3.format('.0f')(v);
    };
  }

  if (isPercent) {
    if (max - min < 0.05) {
      format = d3.format('.1%');
    } else {
      format = d3.format('.0%');
    }
  }

  return format;
}

/*
Returns the direction of the tooltip based on position
*/
export function getTooltipDirection(
  posX: number,
  width: number,
  isMobile: boolean
) {
  let direction = 'n';
  const leftBound = width * (isMobile ? 1 / 4 : 1 / 8);
  const rightBound = width * (isMobile ? 3 / 4 : 7 / 8);
  if (posX < leftBound) direction = 'e';
  else if (posX > rightBound) direction = 'w';

  return direction;
}

/*
Calculate and return the center of the bounding box
of a given element inside the SVG
*/
export function getBoundingBoxCenter(element): number[] {
  const bbox = element.getBBox();
  return [bbox.x + bbox.width / 2, bbox.y + bbox.height / 2];
}

/*
These interfaces and the function are used to calculate the font size
and x translate of the the legend title for color legends. This is necessary
because the x translate changes based on the legend length and sometimes
the font size must be reduced in order to make longer legend titles fit.
 */
export interface colorLegendTitleInputs {
  text: string;
  fontSize: number;
  width: number;
  maxLegendWidth: number;
  padding: number;
  fontFamily: string;
}

export interface colorLegendTitleOutputs {
  fontSize: number;
  translateX: number;
}

export function getColorLegendTitleConfig(
  inputs: colorLegendTitleInputs
): colorLegendTitleOutputs {
  // maximum width of the legend text -- used to scale down font size if necessary
  const maxLegendWidth = Math.min(
    inputs.maxLegendWidth,
    inputs.width - inputs.padding
  );

  // calculate initial width of text
  let currFontSize = inputs.fontSize;
  let currWidth = getTextWidth(inputs.text, inputs.fontFamily, currFontSize);

  // if width too large, reduce font size until it fits
  while (currWidth > maxLegendWidth) {
    currFontSize -= 1;
    currWidth = getTextWidth(inputs.text, inputs.fontFamily, currFontSize);
  }

  /*
  calculate the extra space between the screen width and legend width.
  divide this by two to obtain the amount that the text should be
  translated to the right to account for this extra space
  */
  const screenPaddingLeft = (inputs.width - maxLegendWidth) / 2;

  /*
  calculate the extra space between the maxLegendWidth and the current
  text width. Divide that by two to obtain the amount that the text
  should be translated right to account for this extra space.
  */
  const containerPaddingLeft = (maxLegendWidth - currWidth) / 2;

  return {
    fontSize: currFontSize,
    translateX: screenPaddingLeft + containerPaddingLeft,
  };
}

export function Legend(
  color,
  {
    title = null,
    tickSize = 2,
    width = 320,
    height = 44 + tickSize,
    marginTop = 25,
    marginRight = 0,
    marginBottom = 16 + tickSize,
    marginLeft = 0,
    ticks = width / 64,
    tickFormat = null,
    tickValues = null,
    screenWidth = null,
  } = {}
) {
  const legendTitleConfig: colorLegendTitleOutputs = getColorLegendTitleConfig({
    fontFamily: 'Lato, sans-serif',
    fontSize: 13,
    text: title,
    width: <number>screenWidth,
    maxLegendWidth: 400,
    padding: 48,
  });

  function ramp(color, n = 256) {
    const canvas = document.createElement('canvas');
    canvas.width = n;
    canvas.height = 1;
    const context = canvas.getContext('2d');
    for (let i = 0; i < n; ++i) {
      context.fillStyle = color(i / (n - 1));
      context.fillRect(i, 0, 1, 1);
    }
    return canvas;
  }
  let alignOffset;
  const svg = d3.select('#legend');
  const nodeWidth = (d) => d.getBBox().width;
  let tickAdjust = (g) =>
    g
      .selectAll('.tick line')
      .attr('y1', marginTop + marginBottom - height)
      .attr('stroke', 'white');
  let x;

  // Continuous
  if (color.interpolate) {
    const n = Math.min(color.domain().length, color.range().length);

    x = color
      .copy()
      .rangeRound(
        d3.quantize(d3.interpolate(marginLeft, width - marginRight), n)
      );

    svg
      .append('image')
      .attr('x', marginLeft)
      .attr('y', marginTop)
      .attr('width', width - marginLeft - marginRight)
      .attr('height', height - marginTop - marginBottom)
      .attr('preserveAspectRatio', 'none')
      .attr('transform', function () {
        alignOffset = (screenWidth - nodeWidth(this)) / 2;
        return `translate(${alignOffset},0)`;
      })
      .attr(
        'xlink:href',
        ramp(
          color.copy().domain(d3.quantize(d3.interpolate(0, 1), n))
        ).toDataURL()
      );
  }

  // Sequential
  else if (color.interpolator) {
    x = Object.assign(
      color
        .copy()
        .interpolator(d3.interpolateRound(marginLeft, width - marginRight)),
      {
        range() {
          return [marginLeft, width - marginRight];
        },
      }
    );
    svg
      .append('image')
      .attr('x', marginLeft)
      .attr('y', marginTop)
      .attr('width', width - marginLeft - marginRight)
      .attr('height', 6)
      .attr('preserveAspectRatio', 'none')
      .attr('transform', function () {
        alignOffset = (screenWidth - width) / 2;
        return `translate(${alignOffset},0)`;
      })
      .attr('xlink:href', ramp(color.interpolator()).toDataURL());

    // scaleSequentialQuantile doesn’t implement ticks or tickFormat.
    if (!x.ticks) {
      if (tickValues === undefined) {
        const n = Math.round(ticks + 1);
        tickValues = d3
          .range(n)
          .map((i) => d3.quantile(color.domain(), i / (n - 1)));
      }
      if (typeof tickFormat !== 'function') {
        tickFormat = d3.format(tickFormat === undefined ? ',f' : tickFormat);
      }
    }
  }

  // Threshold
  else if (color.invertExtent) {
    const thresholds = color.thresholds
      ? color.thresholds() // scaleQuantize
      : color.quantiles
      ? color.quantiles() // scaleQuantile
      : color.domain(); // scaleThreshold

    const thresholdFormat =
      tickFormat === undefined
        ? (d) => d
        : typeof tickFormat === 'string'
        ? d3.format(tickFormat)
        : tickFormat;

    x = d3
      .scaleLinear()
      .domain([-1, color.range().length - 1])
      .rangeRound([marginLeft, width - marginRight]);

    svg
      .append('g')
      .selectAll('rect')
      .data(color.range())
      .join('rect')
      .attr('x', (d, i) => x(i - 1))
      .attr('y', marginTop)
      .attr('width', (d, i) => x(i) - x(i - 1))
      .attr('height', height - marginTop - marginBottom)
      .attr('fill', (d) => <any>d)
      .attr('transform', function () {
        alignOffset = (screenWidth - width) / 2;
        return `translate(${alignOffset},0)`;
      });

    tickValues = d3.range(thresholds.length);
    tickFormat = (i) => thresholdFormat(thresholds[i], i);
  }

  // Ordinal
  else {
    x = d3
      .scaleBand()
      .domain(color.domain())
      .rangeRound([marginLeft, width - marginRight]);

    svg
      .append('g')
      .selectAll('rect')
      .data(color.domain())
      .join('rect')
      .attr('x', x)
      .attr('y', marginTop)
      .attr('width', Math.max(0, x.bandwidth() - 1))
      .attr('height', height - marginTop - marginBottom)
      .attr('fill', color);

    tickAdjust = () => {};
  }

  svg
    .append('g')
    .attr('transform', `translate(${alignOffset},${height - marginBottom})`)
    .call(
      d3
        .axisBottom(x)
        .ticks(ticks, typeof tickFormat === 'string' ? tickFormat : undefined)
        .tickFormat(typeof tickFormat === 'function' ? tickFormat : undefined)
        .tickSize(tickSize)
        .tickValues(tickValues)
    )
    .call(tickAdjust)
    .call((g) => g.select('.domain').remove())
    .call((g) =>
      g
        .append('text')
        .attr('x', marginLeft)
        .attr('y', marginTop + marginBottom - height - 6)
        .attr('fill', '#404864')
        .style('font-size', 13 + 'px')
        .attr('text-anchor', 'start')
        .style('font-family', 'Lato, sans-serif')
        .attr('class', 'title')
        .attr(
          'transform',
          `translate(${legendTitleConfig.translateX - alignOffset},0)`
        )
        .text(title)
    );

  return svg.node();
}

export function legend({ color, ...options }) {
  return Legend(color, options);
}

<lib-block
  *ngIf="!block.table; else tabbedMapTable"
  [block]="block"
  [enableInteractiveDialog]="enableInteractiveDialog"
></lib-block>

<ng-template #tabbedMapTable>
  <div class="tabbed-map-table-template">
    <div class="tabs-container">
      <div
        class="tab"
        [ngClass]="{ active: tabName === TAB_OPTIONS.CHART }"
        (click)="setTab(TAB_OPTIONS.CHART)"
      >
        <div class="thumb">
          <img [src]="CHART_ICONS[block.chart_type]" alt="" />
        </div>
        <span>Graph</span>
      </div>
      <div
        class="tab"
        [ngClass]="{ active: tabName === TAB_OPTIONS.TABLE }"
        (click)="setTab(TAB_OPTIONS.TABLE)"
      >
        <div class="thumb">
          <img src="shared-assets/icons/charts/dark/table.svg" alt="" />
        </div>
        <span>Data Table</span>
      </div>
    </div>

    <div class="graph-container" *ngIf="tabName === TAB_OPTIONS.CHART">
      <lib-block
        [block]="block"
        [enableInteractiveDialog]="enableInteractiveDialog"
      ></lib-block>
    </div>

    <div class="table-list-container" *ngIf="tabName === TAB_OPTIONS.TABLE">
      <app-table-block
        [table]="block.table"
        maxHeight="unset"
        [noHide]="true"
      ></app-table-block>
    </div>
  </div>
</ng-template>

<div class="wrapper">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="UI_ERRORS.PARAMETERS_NEEDED">
      <ng-container *ngTemplateOutlet="parametersNeededRef"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="UI_ERRORS.AREA_TOO_LONG">
      <ng-container *ngTemplateOutlet="areaTooLongRef"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="UI_ERRORS.EMPTY_VIZ_LIST">
      <ng-container *ngTemplateOutlet="emptyVizListRef"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="UI_ERRORS.NEGATIVE_BAR_HEIGHT">
      <ng-container *ngTemplateOutlet="negativeBarHeightRef"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="UI_ERRORS.EMPTY_SEARCH_RESULTS">
      <ng-container *ngTemplateOutlet="emptySearchResultsRef"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="UI_ERRORS.CUSTOM">
      <ng-content select="[image]"></ng-content>
      <p>
        <ng-content select="[text]"></ng-content>
      </p>
    </ng-container>

    <ng-container *ngSwitchCase="UI_ERRORS.UNKNOWN">
      <ng-container *ngTemplateOutlet="unknownRef"></ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="unknownRef"></ng-container>
    </ng-container>
  </ng-container>
  <p class="footer">
    <ng-content select="[footer]"></ng-content>
  </p>
</div>

<ng-template #parametersNeededRef>
  <img
    src="shared-assets/images/empty-dash.png"
    alt="set required parameters image"
  />
  <p>Fill out parameters to generate preview.</p>
</ng-template>

<ng-template #areaTooLongRef>
  <img
    src="shared-assets/images/bounding_box_error.png"
    alt="bounding box area selection too big message"
  />
  <p>Selected map area too large.</p>
  <p>Please, decrease the bouding box size to generate visualization.</p>
</ng-template>

<ng-template #emptyVizListRef>
  <img src="shared-assets/images/empty-dash.png" alt="empty viz list image" />
  <p>Nothing to see here.</p>
  <p>Get started by clicking the button below to build a visualization!</p>
</ng-template>

<ng-template #emptySearchResultsRef>
  <img
    src="shared-assets/images/empty-search-results.png"
    alt="empty search results"
  />
  <p>No results match your search. You can request new data below.</p>
</ng-template>

<ng-template #negativeBarHeightRef>
  <img
    src="shared-assets/images/empty-dash.png"
    alt="negative bar height not supported image"
  />
  <p>The chart type is not supported for the given data.</p>
</ng-template>

<ng-template #unknownRef>
  <img
    class="error"
    src="shared-assets/images/empty-dash.png"
    alt="general server error image"
  />
  <p>Something went wrong.</p>
  <p>Please, try again or contact support.</p>
</ng-template>

import {
  Component,
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { AnalyticsService } from 'hg-front-core';
import { AppService } from '../../../services/app.service';
import * as generalUtils from '../../../utils/url.utils';

export type SortDirection = 'asc' | 'desc' | '';

export interface SortEvent {
  column: string;
  direction: SortDirection;
}
const rotate: { [key: string]: SortDirection } = {
  asc: 'desc',
  desc: '',
  '': 'asc',
};

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': "direction === 'asc'",
    '[class.desc]': "direction === 'desc'",
    '(click)': 'rotate()',
  },
})
export class TableSortableHeader {
  @Input() sortable: string;
  @Input() direction: SortDirection = ''; // probably doesn't need to be an input
  @Output() sort = new EventEmitter<SortEvent>();

  rotate(): void {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}

@Component({
  selector: 'app-table-block',
  templateUrl: './table-block.component.html',
  styleUrls: ['./table-block.component.scss'],
})
export class TableBlockComponent implements OnInit {
  @Input() table: any;
  @Input() noHide = false;

  @ViewChildren(TableSortableHeader) headers: QueryList<TableSortableHeader>;

  elements: any = [];
  sortedElements: any = [];
  _maxHeight: any;
  headElements = [];
  title = 'nothing';
  lastFired: number;
  timeBetweenEvents = 1000;
  isMobile = true;
  columns: string[];

  @Input()
  set maxHeight(value) {
    this._maxHeight = value;
  }

  constructor(private ga: AnalyticsService, private appService: AppService) {}

  ngOnInit(): void {
    this.isMobile = this.appService.isMobile;

    this.appService.getIsMobile().subscribe((isMobile) => {
      if (this.isMobile === null) {
        this.isMobile = isMobile;
      } else {
        this.isMobile = isMobile;
      }
    });

    this.columns = this.table.columns;

    // assuming first element in this.dataObj.data exist

    Array(this.columns.length).fill({
      color: 'textLight', // color doesn't change anything
      text: 'Data Unavailable',
      verticalAlign: 'middle',
    });

    this.elements = this.table.rows;

    this.elements.forEach((row) => {
      this.columns.forEach(
        (column) => (row[column] = this.linkify(row[column]))
      );
    });

    this.sortedElements = this.elements;
    this.lastFired = new Date().getTime();
  }

  ngAfterViewChecked(): void {
    const tableLinks = document.querySelectorAll('.table-link');
    tableLinks.forEach((e: HTMLElement) => {
      e.onclick = () => {
        this.ga.blockEventEmitter(
          'Table Link Click',
          'click',
          globalThis.vizCollection + '/' + globalThis.vizType + '/' + 'table',
          null,
          0,
          {
            InteractionType: 'click',
            Collection: globalThis.vizCollection,
            vizType: globalThis.vizType,
            block: 'table',
          },
          true
        );
      };
    });
  }

  onSort({ column, direction }: SortEvent): void {
    // resets other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === '') {
      this.sortedElements = this.elements;
    } else {
      this.sortedElements = [...this.elements].sort((a, b) => {
        const res = this.compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  // if the backend only passes in number, this will change
  compare(a, b): number {
    if (typeof a === 'number' && typeof b === 'number') {
      return a < b ? -1 : a > b ? 1 : 0;
    } else {
      let valA;
      let valB;
      if (
        !isNaN(a.replace(/(^\$|,|\%$)/g, '')) &&
        !isNaN(b.replace(/(^\$|,|\%$)/g, ''))
      ) {
        // convert to number
        valA = Number(a.replace(/(^\$|,|\%$)/g, ''));
        valB = Number(b.replace(/(^\$|,|\%$)/g, ''));
      } else {
        valA = a;
        valB = b;
      }
      return valA < valB ? -1 : valA > valB ? 1 : 0;
    }
  }

  scrollAnalytics(): void {
    const currentTime = new Date().getTime();
    if (this.lastFired + this.timeBetweenEvents < currentTime) {
      this.lastFired = currentTime;
      this.ga.blockEventEmitter(
        'Interaction',
        'interaction',
        globalThis.vizCollection + '/' + globalThis.vizType + '/' + 'table',
        null,
        0,
        {
          InteractionType: 'scroll',
          Collection: globalThis.vizCollection,
          vizType: globalThis.vizType,
          block: 'table',
        },
        true
      );
    }
  }

  private linkify(inputString: string): string {
    if (!inputString) return;
    if (generalUtils.isValidHttpUrl(inputString.toLocaleString())) {
      return `<td ><a style='text-decoration: underline;' target="_blank" href="${inputString.toLocaleString()}">${inputString}</a></td>`;
    } else {
      return inputString;
    }
  }
}

<ng-container *ngIf="header">
  <div class="title formatted">
    {{ header.title }}
  </div>
  <ng-container *ngIf="header.subtitle">
    <div class="info">
      <div class="left">
        <span class="subtitle formatted">
          {{ header.subtitle }}
        </span>
      </div>
    </div>
  </ng-container>
</ng-container>

import { ErrorHandler, Injectable } from '@angular/core';
import BLOCK_ERRORS from '../constants/block-errors';
import { BlocksErrorService } from '../services/block-errors.service';

@Injectable()
export class UIErrorHandler implements ErrorHandler {
  constructor(private blockErrorService: BlocksErrorService) {}

  handleError(error: Error): void {
    console.error(error);
    const { message } = error;
    const BLOCK_ERROR = Object.values(BLOCK_ERRORS).find((e) => e === message);
    if (BLOCK_ERROR) {
      this.blockErrorService.publish(BLOCK_ERROR);
    }
  }
}

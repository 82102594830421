import { Component, OnInit, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent implements OnInit {
  title = 'Oops!';
  continueMode: boolean; // if this is set to true we only show close. If it is false we show Continue and Cancel buttons. Default is false if not set
  errorBody: string;
  constructor(
    private dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.errorBody = data.body;

    if (data.title) {
      this.title = data.title;
    }
  }

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }

  //We pass data object noting that user has selected to continue
  continue() {
    this.dialogRef.close({ continue: true });
  }
}

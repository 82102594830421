import { Component, OnChanges, OnInit, SkipSelf } from '@angular/core';
import * as d3 from 'd3';
import {
  AnalyticsService,
  BlockAxis,
  ColorSchemeCategorical,
  ColorSchemeHighlight,
} from 'hg-front-core';
import { AxisBlockComponent } from 'projects/HGFrontSharedUI/src/lib/modules/core/components/blocks/axis-block/axis-block.component';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'app-multiline-block',
  templateUrl: './multiline-block.component.html',
  styleUrls: ['./multiline-block.component.scss'],
})
export class MultilineBlockComponent
  extends AxisBlockComponent
  implements OnInit, OnChanges
{
  margins = { top: 50, right: 50, bottom: 30, left: 50 };
  drawn: boolean = null;

  constructor(
    private analyticsService: AnalyticsService,
    @SkipSelf() public appService: AppService
  ) {
    super(appService);
  }

  drawChart(): void {
    this.config = <BlockAxis>this.config;
    this.clearOldData();

    if (
      !this.block ||
      !this.size ||
      this.size.width === 0 ||
      this.size.height === 0
    )
      return;

    const svg = d3.select('#SVG');
    const width = this.size.width;
    const height = this.size.height;
    svg.attr('width', width).attr('height', height);

    this.data = this.config.data.sort(function (a, b) {
      return <any>a.x - <any>b.x;
    });

    const self = this;
    const isMobile = this.isMobile;
    const interpolate = this.config.multi_line_options.interpolate;
    const points = this.config.multi_line_options.show_points;
    const percent = this.config.yaxis.percent !== false;
    const dollars = this.config.yaxis.dollars;
    const lineLabels = this.config.multi_line_options.line_labels;
    const gridLines = this.config.yaxis.gridlines;
    const individualTooltip = this.config.multi_line_options.individual_tooltip;
    const keyLabel = (<BlockAxis>this.block.config).category_label;
    const chart_color_scheme = this.block.color_scheme;

    const tooltipShown = this.appService.tooltipShown;

    const sumstat = this.rollupData();

    const keys = this.block.categories;

    let colorValue, defaultColor, highlightColor;
    if (chart_color_scheme.type == 'categorical') {
      colorValue = (chart_color_scheme.config as ColorSchemeCategorical).colors;
    } else if (chart_color_scheme.type == 'highlight') {
      colorValue = chart_color_scheme.options.highlight_value;
      defaultColor = (<ColorSchemeHighlight>chart_color_scheme.config)
        .default_color;
      highlightColor = (<ColorSchemeHighlight>chart_color_scheme.config)
        .highlight_color;
    } else {
      console.error('Invalid color scheme in multiline block');
    }
    // use colorParams
    const colorScheme = this.createColorScheme();
    let showLegend;
    if (lineLabels && typeof colorValue == 'string') showLegend = false;
    else showLegend = true;

    const margin = this.calculateMargins(
      width,
      chart_color_scheme,
      keys,
      showLegend
    );

    const minNum = d3.min(sumstat, (k) => d3.min(k.values, (d) => d.value));
    const maxNum = d3.max(sumstat, (k) => d3.max(k.values, (d) => d.value));
    const extent = maxNum - minNum;
    const yLabel = this.config.yaxis.label;
    const xScales = this.drawXAxis(margin, width, height);
    const scale = this.drawYAxis(minNum, maxNum, height, margin, width, false);

    const w = width - margin.left - margin.right;
    const h = height - margin.top - margin.bottom;

    const tipIndividual = this.createToolTip(['key', 'value'], 'x', [
      keyLabel ? keyLabel : '',
      yLabel,
    ]);
    let tip;
    if (chart_color_scheme.type == 'highlight') {
      tip = this.createToolTip(['key', 'value'], 'x', [
        keyLabel ? keyLabel : '',
        yLabel,
      ]);
    } else {
      tip = this.createToolTip(['value'], 'category', ['Date', yLabel]);
    }
    svg.call(tip);
    svg.call(tipIndividual);

    let curveCall;
    if (interpolate) {
      curveCall = d3.curveBasis;
    } else {
      curveCall = d3.curveMonotoneX;
    }

    const linePaths = svg
      .selectAll('.line')
      .data(sumstat)
      .enter()
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', (d) => colorScheme((d as any).key))
      .style('mix-blend-mode', function (d) {
        // not sure what this does
        if (
          (d as any).key == colorValue ||
          colorValue.includes((d as any).key)
        ) {
          return 'normal';
        }
        return 'multiply';
      })
      .attr('stroke-width', 2)
      .attr('stroke-width', function (d) {
        if (
          (d as any).key == colorValue ||
          colorValue.includes((d as any).key)
        ) {
          d3.select(this).raise();
          return 3;
        }
        return 2;
      })
      .attr('class', 'lineChart')
      .attr('d', function (d) {
        const selfPath = this;
        (d as any).values = (d as any).values.map(function (v) {
          v['line'] = selfPath;
          return v;
        });
        return d3
          .line<any>()
          .x(function (d) {
            return xScales(d.x);
          })
          .y(function (d) {
            return scale(+d['value']);
          })
          .curve(curveCall)((<any>d).values);
      });

    const ga = this.analyticsService;
    const flatData = sumstat.map(function (d) {
      return d.values;
    });

    for (let i = 0; i < flatData.length; i++) {
      for (let j = 0; j < flatData[i].length; j++) {
        flatData[i][j]['key'] = keys[i];
      }
    }

    if (points) {
      svg
        .selectAll('circles')
        .data((flatData as any).flat())
        .enter()
        .append('circle')
        .attr('cx', function (d) {
          return xScales((<any>d).x);
        })
        .attr('cy', function (d) {
          return scale(+d['value']);
        })
        .style('fill', function (d) {
          return <any>colorScheme((d as any).key);
        })
        .attr('r', 3)
        .style('opacity', function (d) {
          if (lineLabels) {
            return 0.3;
          }
          return 0.6;
        });
    }

    const circles = svg
      .selectAll('circles') //for individual tooltip
      .data((flatData as any).flat())
      .enter()
      .append('circle')
      .attr('cx', function (d) {
        return xScales((<any>d).x);
      })
      .attr('cy', function (d) {
        return scale(+d['value']);
      })
      .attr('r', 3)
      .attr('id', function (d) {
        return (+(d as any).x).toString() + '_' + (d as any).key;
      })
      .style('opacity', 0);

    //Since the circles are small we will add an opaque one around them to make mouseover easier.
    const circles2 = svg
      .selectAll('circles2') //for individual tooltip
      .data((flatData as any).flat())
      .enter()
      .append('circle')
      .attr('cx', function (d) {
        return xScales((<any>d).x);
      })
      .attr('cy', function (d) {
        return scale(+d['value']);
      })
      .attr('r', 8)
      .attr('id', function (d) {
        return (+(d as any).x).toString() + '_' + (d as any).key;
      })
      .style('opacity', 0)
      .on('mouseover', function (d) {
        if (individualTooltip) {
          const position = d3.mouse((svg as any).node());
          const width = (svg as any).node().clientWidth;
          const direction = self.getTooltipDirection(
            position[0],
            width,
            isMobile
          );
          tipIndividual.direction(direction).show(d, this);
          tooltipShown.emit(true);
          self.analyticsService.blockEventEmitter(
            'Interaction',
            'interaction',
            globalThis.vizCollection +
              '/' +
              globalThis.vizType +
              '/' +
              'multiline',
            null,
            0,
            {
              InteractionType: 'mouseOver',
              Collection: globalThis.vizCollection,
              vizType: globalThis.vizType,
              block: 'multiline',
            },
            true
          );
        }
      })
      .on('mouseout', function (d) {
        tooltipShown.emit(false);
        if (individualTooltip) {
          tipIndividual.hide(d, this);
        }
      });

    const maxDate = d3.max(this.config.data, (d) => <Date>d.x);

    const filtered = {};
    //ensures that filtered always has every key necessary

    if (lineLabels) {
      var [labels, topLabel, bottomLabel] = this.drawLineLabels(
        sumstat,
        xScales,
        scale,
        colorScheme,
        chart_color_scheme,
        height
      );
    }

    if (lineLabels) {
      //highlight line graphs

      const voronoi = d3
        .voronoi()
        .x((d) => xScales((d as any).x))
        .y((d) => scale(+(d as any).value))
        .extent([
          [0, 0],
          [width, height],
        ]);

      const voronoiGroup = svg.append('g').attr('class', 'voronoi');

      voronoiGroup
        .selectAll('path')
        .data(voronoi.polygons(d3.merge(sumstat.map((d) => (d as any).values))))
        .enter()
        .append('path')
        .attr('d', function (d) {
          return d ? 'M' + d.join('L') + 'Z' : null;
        })
        .style('fill', 'none')
        .attr('pointer-events', 'all')
        .on('mouseover', function (d) {
          self.analyticsService.blockEventEmitter(
            'Interaction',
            'interaction',
            globalThis.vizCollection +
              '/' +
              globalThis.vizType +
              '/' +
              'highlightMultiline',
            null,
            0,
            {
              InteractionType: 'mouseOver',
              Collection: globalThis.vizCollection,
              vizType: globalThis.vizType,
              block: 'highlightMultiline',
            },
            true
          );
          highlightPaths(d);
        })
        .on('mouseout', isMobile ? null : mouseout);

      const focus = svg
        .append('g')
        .attr('transform', 'translate(-100,-100)')
        .attr('class', 'focus');

      const focusCircle = focus
        .append('circle')
        .attr('class', 'focus')
        .attr('r', 3)
        .style('fill', 'none')
        .attr('pointer-events', 'none')
        .style('stroke-width', 1);

      function highlightPaths(d) {
        const circleSelector = (+d.data.x).toString() + '_' + d.data.key;
        const labelSelector = 'label_' + d.key;
        const circle = document.getElementById(circleSelector);
        const label = document.getElementById(labelSelector);
        linePaths.attr('stroke', function (v) {
          if (typeof colorValue != 'string') {
            if (
              (v as any).key == d.key ||
              colorValue.includes((v as any).key)
            ) {
              return <any>colorScheme((v as any).key);
            }
            return defaultColor;
          } else {
            if (
              (v as any).key !== d.data.key &&
              (v as any).key !== colorValue
            ) {
              return defaultColor;
            }
            return highlightColor;
          }
        });
        if (!isMobile) {
          (<any>labels).attr('opacity', function (v) {
            if (!v) return;
            if (
              (v as any).key == topLabel ||
              (v as any).key == bottomLabel ||
              (v as any).key == colorValue ||
              (v as any).key == d.data.key ||
              colorValue.includes((v as any).key)
            ) {
              return 1;
            }
            return 1;
          });
        }
        focus.attr(
          'transform',
          'translate(' + xScales(d.data.x) + ',' + scale(d.data.value) + ')'
        );
        focusCircle.style('fill', highlightColor);
        focus.select('text').text(d.data.key);
        const position = d3.mouse((svg as any).node());
        const width = (svg as any).node().clientWidth;
        const direction = self.getTooltipDirection(
          position[0],
          width,
          isMobile
        );
        tip.direction(direction).show(d.data, circle);
      }

      function mouseout(d) {
        focus.attr('transform', 'translate(-100,-100)');
        tip.hide();
      }

      if (isMobile) {
        d3.select('#SVG').on('mouseleave', function (d) {
          focus.attr('transform', 'translate(-100,-100)');
          tip.hide();
        });
      }
    }

    function updateTooltip(mouse, data, k) {
      d3.select('#hoverLine').style('opacity', '1');
      const sortedVals = [];
      data.map((d) => {
        const xDate = xScales.invert(mouse[0]);
        const bisect = d3.bisector(function (d) {
          return (d as any).x;
        }).left;
        const idx = bisect((d as any).values, xDate);
        sortedVals.push({
          key: (d as any).values[idx].key,
          value: (d as any).values[idx].category,
          date: (d as any).values[idx].x,
          desc: (d as any).values[idx].desc,
        });
      });
      sortedVals.sort(function (x, y) {
        return d3.descending(x.value, y.value);
      });

      const direction = this.getTooltipDirection(mouse[0], width, isMobile);
      let offsetX;
      let offsetY;
      if (direction == 'e') {
        offsetX = 10;
      } else if (direction == 'w') {
        offsetX = -10;
      }
      if (mouse[1] <= height / 2) {
        offsetY = height / 4;
      } else if (mouse[1] <= 0.75 * height) {
        offsetY = height / 2;
      } else {
        offsetY = 0.75 * height;
      }
      tip
        .html(function () {
          let contents =
            '<div>' +
            "<span style='color:black; font-weight:600'>" +
            new Intl.DateTimeFormat('en-US', {
              month: 'long',
              day: 'numeric',
            }).format(sortedVals[0].date) +
            '</span><br/>';
          for (let i = 0; i < keys.length; i++) {
            contents =
              contents +
              "<span style='color:black'>" +
              sortedVals[i].key +
              `: </span><span style='color:` +
              colorScheme(sortedVals[i].key) +
              `'>`;
            if (dollars) {
              contents += '$';
            }
            contents += sortedVals[i].value.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            });
            if (percent) {
              contents += '%';
            }
            contents += '</span><br/>';
          }

          return contents;
        })
        .direction(direction)
        .offset([-height / 2 + offsetY, offsetX]);

      const rectTracker = document.getElementById('hoverLine');

      tip.show(this, rectTracker);
    }

    let legendKeys; //add legend
    if (!lineLabels || typeof colorValue !== 'string') {
      legendKeys = this.block.categories.map(function (d) {
        return { label: d, color: colorScheme(d) };
      });

      this.drawHorizontalLegend(legendKeys, width, false);
    }
    this.drawn = true;
  }

  //helper methods
  calculateMargins(width, coloring, keys, showLegend) {
    const svg = d3.select('#SVG');
    const isMobile = this.isMobile;
    const lineLabels = (<BlockAxis>this.block.config).multi_line_options
      .line_labels;
    const copy = [...keys];
    const longest = copy.sort(function (a, b) {
      return b.length - a.length;
    })[0];

    let margin;
    if (!showLegend) {
      margin = {
        top: 0,
        right: isMobile ? 20 : 30,
        bottom: 45,
        left: isMobile ? 30 : width / 30,
      };
    } else {
      let currentRowWidth = 0;
      let rowsCount = 1;
      const SM_MARGIN_PX = 15;
      const MD_MARGIN_PX = 45;
      const LG_MARGIN_PX = 60;
      const ROW_HEIGHT = 17;
      const CHAR_WIDTH = 7;
      const CIRCLE_WIDTH = 10;
      const LEGEND_SPACING = 30;
      const ROW_SPACING = 3;
      keys.forEach((currentLegend) => {
        const CURRENT_LEGEND_TEXT_WIDTH = currentLegend.length * CHAR_WIDTH;
        const CURRENT_LEGEND_WIDTH =
          CURRENT_LEGEND_TEXT_WIDTH + CIRCLE_WIDTH + LEGEND_SPACING;
        currentRowWidth += CURRENT_LEGEND_WIDTH;
        if (currentRowWidth > width - SM_MARGIN_PX * 2) {
          rowsCount++;
          currentRowWidth = CURRENT_LEGEND_WIDTH;
        }
      });
      const LEGEND_CONTAINER_HEIGHT =
        rowsCount * ROW_HEIGHT + (rowsCount - 2) * ROW_SPACING;
      margin = {
        top: LEGEND_CONTAINER_HEIGHT,
        right: isMobile ? SM_MARGIN_PX : MD_MARGIN_PX,
        bottom: MD_MARGIN_PX,
        left: LG_MARGIN_PX,
      };
    }

    if (lineLabels) {
      const textDummy = svg
        .append('text')
        .attr('font-family', 'Lato, sans-serif')
        .attr('font-size', coloring ? '14px' : '19px')
        .attr('font-weight', 'bold')
        .text(longest);

      if (!isMobile) {
        margin.right = textDummy.node().getComputedTextLength() + 20;
      }
      textDummy.remove();

      if (this.data.length == 1) {
        margin.right = margin.left;
      }
    }
    return margin;
  }

  rollupData() {
    const keys = this.block.categories;
    const tempData = (<BlockAxis>this.block.config).data;
    let sumstat = keys.map(function (id) {
      return {
        key: id,
        values: tempData.filter((datapoint) => datapoint.category === id),
      };
    });

    sumstat = sumstat.filter(function (d) {
      return d.values.length > 0;
    });

    return sumstat;
  }

  drawLineLabels(sumstat, xScales, scale, colorScheme, coloring, height) {
    const svg = d3.select('#SVG');
    const isMobile = this.isMobile;
    const lineLabels = (<BlockAxis>this.block.config).multi_line_options
      .line_labels;
    let colorValue, highlightColor, defaultColor;
    if (coloring.type == 'categorical') {
      // rename colorValue
      colorValue = coloring.categories;
    } else if (coloring.type == 'highlight') {
      colorValue = coloring.options.highlight_value;
      defaultColor = coloring.config.default_color;
      highlightColor = coloring.config.highlight_color;
    } else {
      console.error('Invalid color scheme in multiline block');
    }
    if (!isMobile || lineLabels) {
      const lastVals = sumstat.map((keyVal) => keyVal.values.slice(-1)[0]);

      const topLabel = lastVals.reduce(function (prev, current) {
        return prev.value > current.value ? prev : current;
      }); //returns object
      const bottomLabel = lastVals.reduce(function (prev, current) {
        return prev.value < current.value ? prev : current;
      }); //returns object

      const selectedVal = lastVals.find((key) => key.category == colorValue);

      const highlights = [selectedVal, topLabel, bottomLabel];

      const labels = svg
        .selectAll('.label')
        .data(highlights)
        .enter()
        .append('text')
        .attr('id', function (d) {
          if (d) return 'label_' + (d as any).key;
        })
        .attr('x', topLabel ? xScales(topLabel.x) + 10 : 0)
        .attr('y', function (d) {
          if (d) return scale(+(d as any).value);
        })
        .attr('font-family', 'Lato, sans-serif')
        .text(function (d) {
          if (d) return (d as any).key;
        })
        .style('fill', function (d) {
          return highlightColor;
        })
        .attr('font-weight', 'bold')
        .attr('text-anchor', isMobile ? 'end' : 'start')
        .attr('dominant-baseline', 'central')
        .attr('opacity', 1);

      if (!isMobile) return [labels, topLabel, bottomLabel];
      else return [' ', ' ', ' '];
    }
  }
}

import { Component, OnInit, SkipSelf } from '@angular/core';
import { BlockSegment } from 'hg-front-core';
import { SegmentBlockComponent } from '../segment-block/segment-block.component';
import { AppService } from '../../../services/app.service';

export interface Orientation {
  left: boolean;
  right: boolean;
  top: boolean;
  bottom: boolean;
}

export type PillData = {
  label: string;
  data: any[];
};

@Component({
  selector: 'app-percent-pill-block',
  templateUrl: './percent-pill-block.component.html',
  styleUrls: ['./percent-pill-block.component.scss'],
})
export class PercentPillBlockComponent
  extends SegmentBlockComponent
  implements OnInit
{
  orientation = 'horizontal';
  totalValue = 1;
  horizontal = true;
  pillData: PillData[];
  drawLegend = false;

  isMobile: boolean = null;

  constructor(@SkipSelf() public appService: AppService) {
    super(appService);
  }

  drawChart(): void {
    if (
      !this.block ||
      !this.size ||
      this.size.width === 0 ||
      this.size.height === 0
    ) {
      return;
    }
    const coloring = this.block.color_scheme;
    const horizontal = true;

    const colorScheme = this.createColorScheme();
    let totalValue = 0;
    let baseMeasure = 0,
      i = 0;
    this.pillData = [];
    if (this.block.categories.length > 4) {
      this.drawLegend = true;
    }
    for (const chart of (<BlockSegment>this.config).charts) {
      this.pillData.push({ label: chart.label, data: [] });

      totalValue = chart.data.reduce((sum, el) => sum + el.value, 0) || 1;
      baseMeasure = 0;
      for (const segment of chart.data) {
        let color;
        if (coloring.type == 'categorical') {
          color = colorScheme(segment.category);
        } else {
          console.error('invalid color scheme');
        }
        if (segment.value === 0) continue;
        const percent = (100 * segment.value) / totalValue;
        if (horizontal) {
          this.pillData[i].data.push({
            ...segment,
            bgColor: color,
            percent: Math.round(percent),
            width: `${percent}%`,
            leftPos: `${baseMeasure + percent / 2}%`,
          });
        } else {
          this.pillData[i].data.push({
            ...segment,
            bgColor: color,
            percent: Math.round(percent),
            height: `${percent}%`,
            topPos: `${baseMeasure + percent / 2}%`,
          });
        }

        baseMeasure += percent;
      }
      i++;
    }
    if (this.drawLegend) {
      this.drawCategoricalLegend(colorScheme);
    }
  }

  drawCategoricalLegend(colorScheme): void {
    const legendKeys = this.block.categories.map((d) => {
      return { label: d, color: colorScheme(d) };
    });
    this.drawHorizontalLegend(legendKeys, this.size.width, true);
  }
}

import { VisualizationSummary } from 'hg-front-core';
import { TAKEAWAY_BOX_HEIGHT } from 'hg-front-shared-ui';
import AppSettings from '../../../settings/app.settings';

export const getIframe = (vizSummary: VisualizationSummary): string => {
  const url = getEmbedLink(vizSummary.id);
  const height = calculateHeight(vizSummary);
  return `<iframe style="display:block;" width="100%" height="${height}px" src="${url}" class="hg-data-interactive" frameborder="0" scrolling="no"></iframe>`;
};

export const getEmbedLink = (vizId: string): string =>
  `${AppSettings.embed_url}/viz/${vizId}`;

export const calculateHeight = (vizSummary: VisualizationSummary): number =>
  isTakeawayBoxesActive(vizSummary)
    ? vizSummary.embed_height + TAKEAWAY_BOX_HEIGHT
    : vizSummary.embed_height;

export const isTakeawayBoxesActive = (
  vizSummary: VisualizationSummary
): boolean => vizSummary.takeaway_tiles_count > 0;

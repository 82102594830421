import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  UIControl,
  UIControlDropdown,
  UIControlNumericSlider,
  UIControlRangeSlider,
  UIControlSearch,
} from 'hg-front-core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ErrorDialogComponent } from '../../components/error-dialog/error-dialog.component';

@Component({
  selector: 'app-uicontrol',
  templateUrl: './uicontrol.component.html',
  styleUrls: ['./uicontrol.component.scss'],
})
export class UIControlComponent implements OnInit {
  _controlData: any;
  @Input() passParametersInQuery = true;
  @Input()
  set controlData(controlData: UIControl) {
    if (!controlData) {
      return;
    }
    this._controlData = controlData[0];
    if (this._controlData.type == 'numeric_slider') {
      this.selectedValueNumber = Number(this._controlData.value);
    } else if (this._controlData.type == 'range_slider') {
      this.selectedRange = [
        Number(this._controlData.config.low_value),
        Number(this._controlData.config.high_value),
      ];
    } else if (
      this._controlData.type == 'dropdown' &&
      this._controlData.config.max_choices == 1
    ) {
      /*
      If no selected value, get current value of parameter from URL and set dropdown
      to that value.
      */
      if (!this.selectedValue) {
        const currValue = this._controlData.config.value;
        this.selectedValue = currValue
          ? currValue
          : this._controlData.config.value;
      }
    } else if (
      this._controlData.type == 'dropdown' &&
      this._controlData.config.max_choices > 1
    ) {
      // deals with deprecation: some vizs pass in control data as long string, others as array of options
      this.selectedMultiValue = this._controlData.config.value;

      this.selectedOld = this.selectedMultiValue;
    } else if (this._controlData.type == 'search') {
      this.selectedFilter = this._controlData.config.value;
    }
  }

  get controlData() {
    return this._controlData;
  }
  @Output() onControlChange = new EventEmitter<any>();

  selectedValue: string;
  selectedMultiValue: string[];
  selectedValueNumber: number;
  selectedRange: number[];
  selectedFilter: string;
  selectedOld: string[];
  private searchTerms = new Subject<string>();

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    if (this.controlData.type == 'search') {
      this.listenOnUserSearchValueChanged();
    }
  }

  userSelectionChanged(): void {
    let queryParams = {};
    switch (this.controlData.type) {
      case 'numeric_slider': {
        queryParams = this.getNumericSliderQueryParams();
        break;
      }
      case 'range_slider': {
        queryParams = this.getRangeSliderQueryParams();
        break;
      }
      case 'dropdown': {
        queryParams = this.getDropDownQueryParams();
        break;
      }
    }

    this.setQueryParams(queryParams);
  }

  setQueryParams(queryParams): void {
    this.onControlChange.emit(queryParams);
  }

  listenOnUserSearchValueChanged(): void {
    const queryParams = {};
    this.searchTerms
      .pipe(
        // wait 300ms after each keystroke before considering the term
        debounceTime(300),

        // ignore new term if same as previous term
        distinctUntilChanged(),

        // switch to new search observable each time the term changes
        tap((term: string) => {
          queryParams[(<UIControlSearch>this.controlData.config).parameter] =
            term;

          this.onControlChange.emit(queryParams);
        })
      )
      .subscribe();
  }

  getNumericSliderQueryParams(): any {
    const queryParams = {};

    queryParams[(<UIControlNumericSlider>this.controlData.config).parameter] =
      this.selectedValueNumber.toString();

    return queryParams;
  }

  getRangeSliderQueryParams(): any {
    const queryParams = {};

    this.controlData.config = <UIControlRangeSlider>this.controlData.config;
    queryParams[this.controlData.config.high_parameter] =
      this.selectedRange[1].toString();
    queryParams[this.controlData.config.low_parameter] =
      this.selectedRange[0].toString();

    return queryParams;
  }

  getDropDownQueryParams(): any {
    this.controlData.config = <UIControlDropdown>this.controlData.config;
    const queryParams = {};
    if ((<UIControlDropdown>this.controlData.config).max_choices > 1) {
      if (this.selectedMultiValue.length == 0) {
        queryParams[this.controlData.config.parameter] = this.selectedOld;
        this.openErrorDialog(
          'At least one option should be chosen.',
          true,
          queryParams
        );
        queryParams[this.controlData.config.parameter] = this.selectedOld;
      } else {
        if (
          this.selectedMultiValue.length >
          (<UIControlDropdown>this.controlData.config).max_choices
        ) {
          queryParams[this.controlData.config.parameter] = this.selectedOld;
          this.openErrorDialog(
            'You are only allowed to select up to ' +
              (<UIControlDropdown>this.controlData.config).max_choices +
              ' choices.',
            true,
            queryParams
          );
        } else {
          this.selectedOld = this.selectedMultiValue;
          queryParams[this.controlData.config.parameter] =
            this.selectedMultiValue;
        }
      }
    } else {
      queryParams[this.controlData.config.parameter] = this.selectedValue;
    }
    return queryParams;
  }

  //Opens a Material Dialog with ErrorDialogComponent being the dialog body
  openErrorDialog(
    errorString: string,
    resetSelectedValues: boolean,
    queryParams: any
  ): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      body: errorString,
      height: '400px',
    };
    const dialogRef = this.dialog.open(ErrorDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((data) => {
      if (resetSelectedValues) {
        this.selectedMultiValue = this.selectedOld;
        this.setQueryParams(queryParams);
      }
    });
  }

  resetSearch(): void {
    this.selectedFilter = '';
    this.searchTerms.next(this.selectedFilter);
  }

  filterOn(term: string): void {
    this.selectedFilter = term;
    this.searchTerms.next(term);
  }
}

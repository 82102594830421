import { AfterViewInit, Component, Input } from '@angular/core';
import { TakeawayTile } from 'hg-front-core';

@Component({
  selector: 'app-smart-tiles',
  templateUrl: './smart-tiles.component.html',
  styleUrls: ['./smart-tiles.component.scss'],
})
export class SmartTilesComponent implements AfterViewInit {
  @Input() data: TakeawayTile[] = [];
  @Input() mainColor = '#202058';

  ngAfterViewInit(): void {
    //this.drawSmallChart();
  }

  onResize() {
    //this.drawSmallChart();
  }

  /**
  drawSmallChart() {
    for (let i = 0; i < this.data.length; i++) {
      if (!this.data[i].graph_type) {
        continue;
      }
      const graph_data = this.data[i].graph_data;
      d3.select('.graph-' + i)
        .select('svg')
        .remove();
      const width = +d3
        .select('.graph-' + i)
        .style('width')
        .slice(0, -2);
      // height of .graph-i changes after drawSmallChart
      const height = +d3
        .select('.graph-' + i)
        .style('height')
        .slice(0, -2);
      const svg = d3
        .select('.graph-' + i)
        .append('svg')
        .attr('width', width);

      if (this.data[i].graph_type == 'percent_pill') {
        const pillHeight = 8;
        const pillWidth = width - 24 - 16; // padding
        //svg.attr('height', pillHeight)
        // this attempts to move the percentpill graph down to the bottom of the smartTile
        //.attr("transform", "translate(0, " + (height < pillHeight ? 0 : (height-pillHeight)) + ")")

        const xScale = d3
          .scaleLinear()
          .domain([0, graph_data.max_value])
          .range([0, pillWidth]);

        const pill = svg.append('g').attr('class', 'percent-pill');

        //create clip path for rounded corners
        pill
          .append('defs')
          .append('clipPath')
          .attr('id', 'rounded-clip')
          .append('rect')
          .attr('width', pillWidth)
          .attr('height', pillHeight)
          .attr('rx', pillHeight / 2);

        pill
          .append('rect')
          .attr('width', xScale(graph_data.max_value))
          .attr('height', pillHeight)
          .attr('fill', graph_data.secondary_color || '#DBDEF2')
          .attr('clip-path', 'url(#rounded-clip)');

        pill
          .append('rect')
          .attr('width', xScale(graph_data.value))
          .attr('height', pillHeight)
          .attr('fill', graph_data.primary_color || '#656C83')
          .attr('clip-path', 'url(#rounded-clip)');
      } else if (this.data[i].graph_type == 'area_chart') {
        const stroke = 1;

        const xScale = d3
          .scaleTime()
          .domain(d3.extent(graph_data.line_data, (d) => d.date))
          .range([0, width - stroke * 2]);

        let [ymin, ymax] = d3.extent(graph_data.line_data, (d) => d.value);

        const extent = ymax - ymin;
        ymin =
          ymin < 0 ? ymin - extent * 0.05 : Math.max(ymin - extent * 0.05, 0);
        ymax = ymax + extent * 0.05;
        const yScale = d3
          .scaleLinear()
          .domain([ymin, ymax])
          .range([height - stroke * 2, 0]);

        const area = d3
          .area()
          .x((d: any) => xScale(d.date))
          .y0(yScale(ymin))
          .y1((d: any) => yScale(d.value));

        // ISSUE: graph overflow
        svg
          .append('g')
          .attr('transform', 'translate(' + stroke + ', ' + stroke + ')') // to make the stroke visible
          .append('path')
          .datum(graph_data.line_data)
          .attr('fill', graph_data.secondary_color || '#A6ACCE')
          .attr('stroke', graph_data.primary_color || '#DBDEF2')
          .attr('stroke-width', stroke)
          .attr('d', area as any);
      }
    }
  }
**/
  isNumber(inputString: any) {
    return !isNaN(inputString);
  }
}

<ui-modal
  title="Account Settings"
  height="fit-content"
  (onCloseModal)="onClickClose()"
>
  <div class="settings-container" #Profile>
    <div class="top-section">
      <div class="info-section">
        <p>First name</p>
        <nz-input-group>
          <input
            nz-input
            placeholder="First name"
            [(ngModel)]="user.first_name"
          />
        </nz-input-group>
      </div>
      <div class="info-section">
        <p>Last name</p>
        <nz-input-group>
          <input
            nz-input
            placeholder="Last name"
            [(ngModel)]="user.last_name"
          />
        </nz-input-group>
      </div>
    </div>

    <div class="btn-wrapper">
      <div (click)="onClickSave()" class="btn-filled">
        <p>Save</p>
      </div>
    </div>
  </div>
</ui-modal>

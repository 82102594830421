import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss'],
})
export class DividerComponent {
  @Input()
  color: 'primary' | 'secondary' | 'light' = 'light';

  @Input()
  width: 'full' | 'medium' | 'small' = 'full';
}

<div class="row"
    >
  <div class="pill-bar-label"
            [ngStyle]="{
        height: this.sizeOfSVG.height + 'px'
      }"
      >
      <p class="pillbar-header" [style.font-size]="this.titleFontSize+ 'px'"  [ngStyle]="{'color': this.titleColor}">{{this.title}}</p>
      <p class="pillbar-subtitle" [style.font-size]="this.subitleFontSize+ 'px'"  [ngStyle]="{'color': this.titleColor}">{{this.subtitle}}</p>
  </div>
  <mat-icon matSuffix>
    arrow_right
  </mat-icon>
  <div class="pill-bar-svg-container">
    <svg
      id="SVG"
      [ngStyle]="{
        width: this.sizeOfSVG.width + 'px',
        height: this.sizeOfSVG.height + 'px'
      }"
    ></svg>
  </div>
</div>

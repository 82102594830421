import {
  Component,
  OnChanges,
  OnInit,
  Input,
  SkipSelf,
  SimpleChanges,
} from '@angular/core';
import * as d3 from 'd3';
import { startCase } from 'lodash';
import {
  BlockMultipartiteGraph,
  BlockEdge,
  BlockNode,
  MultipartiteLevelConfig,
  MultipartiteTooltipConfig,
} from 'hg-front-core';
import { AppService, GraphSize } from '../../../services/app.service';
import { BaseChartBlockComponent } from '../../blocks/base-chart-block/base-chart-block.component';
import * as utils from 'projects/HGFrontSharedUI/src/lib/modules/core/components/blocks/base-chart-block/base-chart-utils';
import { sv } from 'date-fns/locale';

@Component({
  selector: 'app-multipartite-graph-block',
  templateUrl: './multipartite-graph-block.component.html',
  styleUrls: ['./multipartite-graph-block.component.scss'],
})
export class MultipartiteGraphBlockComponent
  extends BaseChartBlockComponent
  implements OnInit, OnChanges
{
  @Input()
  size: GraphSize;
  isMobile: boolean = null;
  levelConfig: MultipartiteLevelConfig[];
  edges: BlockEdge[];
  nodes: BlockNode[];
  legendLabel: string;
  tooltip_config: MultipartiteTooltipConfig;
  tooltips: string[];
  highlightedNode: string | null;

  constructor(@SkipSelf() private appService: AppService) {
    super(appService);
  }

  ngOnInit(): void {
    this.config = <BlockMultipartiteGraph>this.config;
    this.isMobile = this.appService.isMobile;
    this.levelConfig = this.config.level_config;
    this.legendLabel = this.config.legend_label;
    this.tooltip_config = this.config.tooltip_config;
    this.edges = this.config.edges;
    this.nodes = this.config.nodes;
    this.tooltips = this.config.tooltips;
    this.highlightedNode = this.config.highlighted_node;

    //If screen size change
    this.appService
      .getIsMobile()
      .subscribe((isMobile) => (this.isMobile = isMobile));

    this.drawChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.size) {
      if (changes.size.currentValue.width <= 575) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      this.drawChart();
    }
  }

  drawChart(): void {
    if (!this.size || !this.size.width || this.size.height <= 50) {
      return;
    }
    const self = this;
    this.config = <BlockMultipartiteGraph>this.config;
    const isMobile = this.isMobile;

    const width = this.size.width;
    const height = this.size.height;
    const legendOffsetY = 40;
    this.levelConfig = this.config.level_config;
    this.legendLabel = this.config.legend_label;
    this.tooltip_config = this.config.tooltip_config;
    this.edges = this.config.edges;
    this.nodes = this.config.nodes;
    this.tooltips = this.config.tooltips;
    this.highlightedNode = this.config.highlighted_node;

    const markerBoxWidth = 10;
    const markerBoxHeight = 10;
    const refX = markerBoxWidth / 2 + 3;
    const refY = 0;

    const titleFontSize = 20;
    const subtitleFontSize = 16;
    const fontFamily = 'Montserrat, Lato, sans-serif';
    const fontSize = 18;
    const fontColor = '#1D316C';
    const linkColor = '#7c7d7c';

    const rectWidth = width / this.levelConfig.length;
    const rectHeight = 40;

    const top_padding = rectHeight + legendOffsetY + 30;

    let clickFlag = false;

    const svg = d3.select('#SVG');
    d3.selectAll('#SVG > *').remove();
    svg.attr('width', width).attr('height', height);

    const tip = this.createToolTip(
      this.tooltips,
      'name',
      this.tooltips.map((key) => startCase(key)),
      null,
      'hyperlink',
      'color'
    );
    svg.call(tip);

    const chart = svg
      .append('g')
      .attr('class', 'chart')
      .attr('transform', `translate(0,${legendOffsetY})`);

    const node_dict = {};
    this.nodes.forEach((node, i) => {
      node_dict[node.name] = node;
      node_dict[node.name].className = `node${i}`;
      node_dict[node.name].predecessors = [];
      node_dict[node.name].successors = [];
    });

    const verEdges = [];
    const horEdges = [];
    // sort into verticale and horizontal edges
    this.edges.forEach((edge) => {
      if (node_dict[edge.source].level != node_dict[edge.target].level) {
        horEdges.push(edge);
      } else {
        verEdges.push(edge);
      }
      node_dict[edge.source].successors.push(edge.target);
      node_dict[edge.target].predecessors.push(edge.source);
    });

    const node_list = [];
    const level_dict = {};
    this.levelConfig.forEach((level, index) => {
      node_list.push([]);
      level_dict[level.name] = index;
    });

    for (const key in node_dict) {
      const node = node_dict[key];
      node.name = key;
      node_dict[key].col_index =
        node_list[level_dict[node_dict[key].level]].length;
      node_list[level_dict[node_dict[key].level]].push(node_dict[key]);
    }

    const maxColCount = Math.max(
      ...node_list.map((level: BlockNode[]) => level.length)
    );

    const maxRadius =
      Math.min(
        (height - top_padding - maxColCount * fontSize) / (maxColCount + 1),
        legendOffsetY - 10
      ) / 2;

    this.tooltip_config.dollars.forEach((key) => {
      node_list.forEach((levelNodes: BlockNode[]) => {
        levelNodes.map((node) => {
          node[key] = node[key].toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
          });
        });
      });
    });

    const maxWeight = Math.max(...this.nodes.map((node) => node.weight));

    drawLegend(this.legendLabel, legendOffsetY, width, isMobile);

    // draw the level tabs and legend at the top
    chart
      .selectAll('.levelTab')
      .data(this.levelConfig)
      .enter()
      .append('rect')
      .attr('x', (d, i) => i * rectWidth)
      .attr('y', 0)
      .attr('width', rectWidth)
      .attr('height', rectHeight)
      .attr('stroke', 'white')
      .attr('stroke-width', 2)
      .style('fill', (d) => d.color);

    chart
      .selectAll('.title')
      .data(this.levelConfig)
      .enter()
      .append('text')
      .attr('x', (d, i) => i * rectWidth + rectWidth / 2)
      .attr('y', () => rectHeight / 2)
      .attr('font-size', titleFontSize)
      .attr('font-weight', 'bold')
      .attr('font-family', fontFamily)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .text((d) => d.name)
      .style('fill', 'white');

    chart
      .selectAll('.subtitle')
      .data(this.levelConfig)
      .enter()
      .append('text')
      .attr('x', (d, i) => i * rectWidth + rectWidth / 2)
      .attr('y', (d, i) => rectHeight + 15)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .attr('font-size', subtitleFontSize)
      .attr('font-family', fontFamily)
      .style('fill', fontColor)
      .attr('font-weight', 'bold')
      .text((d) =>
        getEllipsisText(getSubtitle(d), fontFamily, subtitleFontSize, rectWidth)
      );

    chart
      .append('defs')
      .append('marker')
      .attr('id', 'arrow')
      .attr('viewBox', `0 -5 ${markerBoxWidth} ${markerBoxHeight}`)
      .attr('refX', refX)
      .attr('refY', refY)
      .attr('markerWidth', markerBoxWidth)
      .attr('markerHeight', markerBoxHeight)
      .attr('orient', 'auto')
      .append('path')
      .attr('d', 'M0,-4L8,0L0,4')
      .attr('fill', linkColor)
      .attr('stroke', 'none');

    const y_spacing = (height - top_padding) / (maxColCount + 1);
    node_list.forEach((levelNodes: BlockNode[], index) => {
      levelNodes.forEach((node, i) => {
        if (node.name == this.highlightedNode) {
          const node = node_dict[this.highlightedNode];
          chart
            .append('rect')
            .attr('x', index * rectWidth)
            .attr('y', top_padding + i * y_spacing - 20)
            .attr('width', rectWidth)
            .attr('height', y_spacing)
            .attr('fill', this.levelConfig[level_dict[node.level]].color);
        }
      });

      const chart_nodes = chart.selectAll('.node').data(levelNodes).enter();

      chart_nodes
        .append('circle')
        .attr('class', (d) => {
          const predecessors = node_dict[d.name].predecessors.map(
            (name) => 'pred-' + node_dict[name].className
          );
          const successors = node_dict[d.name].successors.map(
            (name) => 'succ-' + node_dict[name].className
          );
          const classes = `node-circle ${
            node_dict[d.name].className
          } ${predecessors.join(' ')} ${successors.join(' ')}`;
          return classes;
        })
        .attr('cx', (d) => {
          const cx = index * rectWidth + rectWidth / 2;
          node_dict[d.name].x = cx;
          return cx;
        })
        .attr('cy', (d, i) => {
          const cy =
            top_padding +
            i * y_spacing +
            calculateRadius(d.weight, maxWeight, maxRadius) +
            10;
          node_dict[d.name].y = cy;
          return cy;
        })
        .attr('r', (d) => calculateRadius(d.weight, maxWeight, maxRadius))
        .attr('fill', (d) =>
          d.name == this.highlightedNode
            ? 'white'
            : this.levelConfig[index].color
        )
        .on('mouseover', function (d) {
          const direction = self.getTooltipDirection(
            node_dict[d.name].x,
            width,
            isMobile
          );
          tip.direction(direction).show({...d, "color": self.levelConfig[index].color }, this);
          showRelationships(d, node_dict);
        })
        .on('mouseout', function (d) {
          if (!clickFlag) {
            tip.hide(d, this);
          }
          hideRelationships(d, node_dict);
        })
        .on('click', function (d) {
          if (!isMobile) {
            clickFlag = true;
          }
        });

      chart_nodes
        .append('text')
        .attr('x', () => index * rectWidth + rectWidth / 2)
        .attr('y', (d, i) => top_padding + i * y_spacing)
        .text((d) => getEllipsisText(d.name, fontFamily, fontSize, rectWidth))
        .attr('text-anchor', 'middle')
        .attr('font-size', isMobile ? fontSize - 2 : fontSize)
        .attr('font-family', fontFamily)
        .style('fill', fontColor);
    });

    svg.on('click', function () {
      if (!isMobile && !d3.select(d3.event.target).classed('node-circle')) {
        tip.hide();
        clickFlag = !clickFlag;
      }
    });

    // draw all arrows, add class according to the source and destination nodes
    chart
      .selectAll('.link')
      .data(horEdges)
      .enter()
      .append('path')
      .attr(
        'class',
        (e) =>
          `${node_dict[e.target].className} ${node_dict[e.source].className}`
      )
      .attr('d', (e) => {
        return d3
          .linkHorizontal()
          .x((d) => d[0])
          .y((d) => d[1])({
          source: [
            node_dict[e.source].x +
              calculateRadius(node_dict[e.source].weight, maxWeight, maxRadius),
            node_dict[e.source].y,
          ],
          target: [
            node_dict[e.target].x -
              calculateRadius(node_dict[e.target].weight, maxWeight, maxRadius),
            node_dict[e.target].y,
          ],
        });
      })
      .attr('marker-end', 'url(#arrow)')
      .attr('stroke', linkColor)
      .attr('fill', 'none')
      .attr('opacity', 0);

    chart
      .selectAll('.link')
      .data(verEdges)
      .enter()
      .append('path')
      .attr(
        'class',
        (e) =>
          `${node_dict[e.target].className} ${node_dict[e.source].className}`
      )
      .attr('d', (e) => {
        const dx = node_dict[e.target].x - node_dict[e.source].x;
        const dy = node_dict[e.target].y - node_dict[e.source].y;
        const tx = node_dict[e.target].x;

        const tr = calculateRadius(
          node_dict[e.target].weight,
          maxWeight,
          maxRadius
        );
        const ty = node_dict[e.target].y;
        const sx = node_dict[e.target].x;
        const sr = calculateRadius(
          node_dict[e.source].weight,
          maxWeight,
          maxRadius
        );
        const sy = node_dict[e.source].y;
        const dr = Math.sqrt(dx * dx + dy * dy);

        const level_difference =
          node_dict[e.target].col_index - node_dict[e.source].col_index;
        if (level_difference > 0) {
          return (
            'M' +
            (sx + sr) +
            ',' +
            sy +
            'A' +
            dr +
            ',' +
            dr +
            ' 0 0,1 ' +
            (tx + tr) +
            ',' +
            ty
          );
        } else {
          return (
            'M' +
            (sx - sr) +
            ',' +
            sy +
            'A' +
            dr +
            ',' +
            dr +
            ' 0 0,1 ' +
            (tx - tr) +
            ',' +
            ty
          );
        }
      })
      .attr('marker-end', 'url(#arrow)')
      .attr('stroke', linkColor)
      .attr('fill', 'none')
      .attr('opacity', 0);

    function drawLegend(
      legendText: string,
      legendOffsetY: number,
      svgWidth: number,
      isMobile: boolean
    ) {
      const svg = d3.select('#SVG');
      const legend = svg
        .append('g')
        .attr('class', 'legend-container')
        .append('g')
        .attr('class', 'legend')

      legend
        .append('text')
        .text(legendText)
        .attr('y', legendOffsetY/2)
        .attr('x', svgWidth / 2)
        .attr('dominant-baseline', 'central')
        .attr('text-anchor', 'middle')
        .attr('font-size', isMobile ? 12 : 20)
        .attr('font-family', fontFamily)
        .style('fill', fontColor)
    }

    function calculateRadius(weight, maxWeight, maxRadius): number {
      const radiusScale = d3
        .scaleSqrt()
        .domain([0, maxWeight])
        .range([5, maxRadius]);
      return radiusScale(weight);
    }

    function getSubtitle(level: MultipartiteLevelConfig): string {
      const subtitle = level.subtitle;
      const subtitleValue = level.subtitle_value;
      const subtitleDollar = level.subtitle_dollar;
      return (
        (subtitleDollar
          ? subtitleValue.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
            })
          : subtitleValue.toString()) +
        ' ' +
        subtitle
      );
    }

    function showRelationships(node: BlockNode, node_dict) {
      d3.selectAll(`path.${node_dict[node.name].className}`)
        .transition()
        .duration(300)
        .attr('opacity', 0.8);
      d3.selectAll(
        `circle.node-circle:not(.${node_dict[node.name].className}):not(.pred-${
          node_dict[node.name].className
        }):not(.succ-${node_dict[node.name].className})`
      )
        .transition()
        .duration(300)
        .attr('opacity', 0.3);
    }

    function hideRelationships(node: BlockNode, node_dict) {
      d3.selectAll(`path.${node_dict[node.name].className}`)
        .transition()
        .duration(300)
        .attr('opacity', 0);
      d3.selectAll(
        `circle.node-circle:not(.${node_dict[node.name].className}):not(.pred-${
          node_dict[node.name].className
        }):not(.succ-${node_dict[node.name].className})`
      )
        .transition()
        .duration(300)
        .attr('opacity', 1);
    }

    function getEllipsisText(str, fontFamily, fontSize, maxWidth): string {
      const ellipsisWidth = 20;
      const textWidth = utils.getTextWidth(str, fontFamily, fontSize);
      if (textWidth <= maxWidth) {
        return str;
      } else {
        const numChars = Math.floor(
          (maxWidth - ellipsisWidth) / (textWidth / str.length)
        );
        return str.substring(0, numChars) + '...';
      }
    }

    function shortenNumber(number) {
      const million = 1000000;
      const thousand = 1000;

      if (number >= million) {
        return (
          (number / million).toLocaleString(undefined, {
            maximumFractionDigits: 1,
          }) + 'M'
        );
      } else if (number >= thousand) {
        return (
          (number / thousand).toLocaleString(undefined, {
            maximumFractionDigits: 1,
          }) + 'k'
        );
      } else {
        return number.toLocaleString();
      }
    }

    function roundToDigits(number) {
      if (number < 10) return number; // Single digit, no rounding
      const length = Math.floor(Math.log10(number));
      const divisor = Math.pow(10, length);
      return Math.round(number / divisor) * divisor;
    }
  }
}

<div
  class="avatar-container"
  [ngClass]="orientation"
  [ngStyle]="{ 'align-items': this.verticalAlign}"
>
  <div *ngIf="this.url"
    (mouseenter)="this.hoverAvatar()" class="avatar-photo" [ngStyle]="styles"></div>
  <div class="avatar-info">
    <span
      class="avatar-name"
      [style.font-size]="this.nameFont + 'px'"
      [ngStyle]="{ color: this.textColor }"
    >
      {{ name }}
    </span>
    <span
      class="avatar-subtext"
      [style.font-size]="this.subtitleFont + 'px'"
      [ngStyle]="{ color: this.subTextColor }"
      *ngIf="subText !== '' && subText != null"
    >
      {{ subText }}
    </span>
  </div>
</div>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  AnalyticsService,
  isNewsroom,
  LoggedInUserService,
} from 'hg-front-core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss'],
})
export class HelpModalComponent implements OnInit {
  @Output()
  closeModal = new EventEmitter();

  isUserFromNewsroomOrg: boolean;

  constructor(
    private analytics: AnalyticsService,
    private loggedInUserService: LoggedInUserService
  ) {}

  onCloseModal(): void {
    this.analytics.dhEvent('close-help-modal', 'help-modal');
    this.closeModal.emit();
  }

  trackClick(eventName: string): void {
    this.analytics.dhEvent(eventName, 'help-modal');
  }

  ngOnInit(): void {
    this.loggedInUserService.user$
      .pipe(take(1))
      .subscribe(
        (user) => (this.isUserFromNewsroomOrg = isNewsroom(user.organization))
      );
  }
}

import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ResizedEvent } from 'angular-resize-event';
import { Block, SessionStorageService } from 'hg-front-core';
import { GraphSize } from '../../../services/app.service';

const DIALOG_LOCAL_STORAGE_KEY = 'interactive-graph-dialog-enabled';

@Component({
  selector: 'lib-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss'],
})
export class BlockComponent implements OnInit, OnDestroy {
  @Input()
  block: Block;

  size: GraphSize = null;

  @Input()
  enableInteractiveDialog = false;

  showInteractiveGraphDialog = false;

  constructor(
    private sessionStorageService: SessionStorageService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  closeDialog(): void {
    if (this.sessionStorageService.isAvailable()) {
      this.sessionStorageService
        .updateItem(DIALOG_LOCAL_STORAGE_KEY, false)
        .subscribe((newValue) => (this.showInteractiveGraphDialog = newValue));
    }
  }

  onResized(event: ResizedEvent): void {
    const { width: newWidth, height: newHeight } = event.newRect;
    if (
      !this.size ||
      this.size.width !== newWidth ||
      this.size.height !== newHeight
    ) {
      this.size = {
        width: newWidth,
        height: newHeight,
      };
    }
  }

  ngOnInit(): void {
    if (this.enableInteractiveDialog) {
      if (isPlatformBrowser(this.platformId)) {
        if (this.sessionStorageService.isAvailable()) {
          window.addEventListener(
            'storage',
            this.loadDialogState.bind(this),
            false
          );
          this.loadDialogState();
        }
      }
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.loadDialogState, false);
  }

  private loadDialogState(): void {
    this.sessionStorageService
      .getItem<boolean>(DIALOG_LOCAL_STORAGE_KEY)
      .subscribe((showDialog) => {
        this.showInteractiveGraphDialog =
          showDialog === undefined ? true : showDialog;
      });
  }
}

<ng-container *ngIf="mediaObserver.isActive('gt-md'); else mobile">
  <ng-container *ngTemplateOutlet="desktop"></ng-container>
</ng-container>

<ng-template #mobile>
  <div
    class="mobile-container"
    fxFlex
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <ng-container *ngIf="loading; else loginContent">
      <nz-spin nzSize="large"></nz-spin>
    </ng-container>
  </div>
</ng-template>

<ng-template #desktop>
  <div
    class="login-container"
    [ngClass]="loading ? 'background-loading' : 'background-default'"
  >
    <ng-container *ngIf="loading; else loginContent">
      <nz-spin nzSize="large"></nz-spin>
    </ng-container>
  </div>
</ng-template>

<ng-template #loginContent>
  <div
    class="login-modal"
    ngClass.xs="small"
    *ngIf="
      authState === EAuthState.AUTHORIZED || authState === EAuthState.ERROR
    "
  >
    <img
      class="logo-img"
      src="shared-assets/icons/higeorge-logo/dataherald-logo-full-color.svg"
      alt="Dataherald brand logo"
    />

    <div
      *ngIf="
        authState === EAuthState.AUTHORIZED && user.finished_setup === false
      "
    >
      <div class="header-section">
        <h1>Nice to meet you</h1>
        <p>
          Finish setting up your account to continue to {{ applicationName }}.
        </p>
      </div>
      <form
        class="login-form"
        nz-form
        [formGroup]="validateForm"
        (ngSubmit)="onSubmitSetupForm()"
      >
        <ng-container *ngIf="isFormUser">
          <p class="form-section-header">Name</p>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input your first name!">
              <input
                type="text"
                nz-input
                formControlName="firstName"
                placeholder="First name"
              />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input your last name!">
              <input
                type="text"
                nz-input
                formControlName="lastName"
                placeholder="Last name"
              />
            </nz-form-control>
          </nz-form-item>
        </ng-container>
        <ng-container *ngIf="user.trial_account">
          <p class="form-section-header">Job details</p>
          <div formGroupName="job_details">
            <nz-form-item>
              <nz-form-control nzErrorTip="Please select your job industry!">
                <nz-select
                  formControlName="industry"
                  nzShowSearch
                  nzPlaceHolder="Select your job industry"
                  nzSize="large"
                  [nzOptions]="jobIndustries"
                >
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input your company name!">
                <input
                  type="text"
                  nz-input
                  formControlName="company"
                  placeholder="Company name"
                />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input your job title!">
                <input
                  type="text"
                  nz-input
                  formControlName="title"
                  placeholder="Job title"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <p class="form-section-header">Location</p>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please select your state!">
              <nz-select
                formControlName="state"
                nzShowSearch
                nzSize="large"
                nzPlaceHolder="Select your state"
                [nzOptions]="states"
                (ngModelChange)="updateCounties($event)"
              >
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please select your county!">
              <nz-select
                formControlName="county"
                nzShowSearch
                nzSize="large"
                nzPlaceHolder="Select your county"
                [nzDisabled]="!this.validateForm.controls.state.value"
                [nzOptions]="counties"
              >
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </ng-container>
        <ng-container *ngIf="isFormUser">
          <p class="form-section-header">Change password</p>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input your new password!">
              <input
                type="password"
                nz-input
                formControlName="password"
                placeholder="New password"
                (ngModelChange)="onUpdatePasswordInput()"
              />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please input your new password!">
              <input
                type="password"
                nz-input
                formControlName="confirmPassword"
                placeholder="Confirm new password"
                (ngModelChange)="onUpdatePasswordInput()"
              />
            </nz-form-control>
          </nz-form-item>
          <div class="password-strength-container">
            <ui-checkbox
              *ngFor="let check of passwordStrengthChecks"
              [text]="check.name"
              [isChecked]="check.isChecked"
              [canInteract]="false"
              class="ui-checkbox"
            ></ui-checkbox>
          </div>
        </ng-container>
        <button
          class="btn-filled-color"
          nz-button
          style.alignSelf="center"
          nzType="primary"
        >
          Submit
        </button>
      </form>
    </div>

    <div
      *ngIf="
        authState === EAuthState.ERROR &&
        authErrorMessage !== 'email-unverified'
      "
    >
      <div class="header-section">
        <h1>Login failed</h1>
        <p>
          We take all errors seriously. This error has been automatically logged
          for review.
        </p>
      </div>
      <div class="error-card">
        <img
          src="shared-assets/icons/alert-triangle-black.svg"
          alt="alert triangle black for error message"
        />
        <p>
          {{ authErrorMessage }}
        </p>
      </div>
      <div class="error-buttons" (click)="onClickContactSupport()">
        <div class="btn-filled btn">
          <p>Contact support</p>
        </div>
        <div class="btn-outline btn" (click)="onClickSignOut()">
          <p>Sign out</p>
        </div>
      </div>
    </div>

    <div
      *ngIf="
        authState === EAuthState.ERROR &&
        authErrorMessage === 'email-unverified'
      "
    >
      <div class="header-section">
        <h1>Verify your email</h1>
      </div>
      <div class="error-card info-card">
        <img
          src="shared-assets/icons/alert-triangle-black.svg"
          alt="alert triangle black for error message"
        />
        <p>
          Check your inbox for an email from us. Click the verification link in
          the email, then continue by clicking the button below.
        </p>
      </div>
      <div class="btn-filled-color btn" (click)="onClickAfterVerifyEmail()">
        <p>Go to {{ applicationName }}</p>
      </div>
    </div>
  </div>
</ng-template>

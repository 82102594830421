<ui-modal
  title="Help And Support Resources"
  titleIcon="shared-assets/icons/help-circle.svg"
  titleSize="small"
  height="fit-content"
  (onCloseModal)="onCloseModal()"
>
  <div
    class="wrapper"
    fxFlex
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="20px"
  >
    <a
      class="btn-outline-white"
      href="mailto:support@dataherald.com"
      target="_blank"
      (click)="trackClick('contact-us')"
    >
      <img src="shared-assets/icons/users.svg" alt="" />
      <p>Contact us at support@dataherald.com</p>
    </a>
    <ng-container *ngIf="isUserFromNewsroomOrg">
      <a
        class="btn-outline-white"
        href="https://onb.dataherald.com/"
        target="_blank"
        (click)="trackClick('onboarding-site')"
      >
        <img
          class="logo"
          src="shared-assets/icons/higeorge-logo/dataherald-logo-symbol-color.svg"
          alt=""
        />
        <p>Get more resources from our onboarding site</p>
      </a>
    </ng-container>
  </div>
</ui-modal>

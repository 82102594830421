import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { LoggedInUserService, UserDetails } from 'hg-front-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccountIsSetup implements CanActivate {
  constructor(
    private router: Router,
    private loggedInUserService: LoggedInUserService
  ) {}

  /**
   * If no user, it's demo user and we let them continue the routing.
   *
   * User can continue routing if it's a user that already finished the account setup,
   * otherwise, it's redirected to the finish setup page
   */
  canActivate(): Observable<boolean | UrlTree> {
    return this.loggedInUserService.user$.pipe(
      map((user: UserDetails) => {
        return !user || user.finished_setup || this.router.parseUrl('profile');
      })
    );
  }
}

import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, Inject, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

export interface GraphSize {
  width: number;
  height: number;
}

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private _manualMobile = null;
  private isMobileSubject = new BehaviorSubject(this.isMobile);
  tooltipShown = new EventEmitter<any>();

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  get isMobile(): boolean {
    if (typeof this._manualMobile === 'boolean') {
      return this._manualMobile;
    }

    if (isPlatformBrowser(this.platformId))
      return window && window.innerWidth <= 575;
  }

  setIsMobile(value: boolean | null): void {
    this._manualMobile = value;
    this.checkSize();
  }

  getIsMobile(): Observable<boolean> {
    return this.isMobileSubject.asObservable();
  }

  checkSize(): void {
    if (this.isMobile !== this.isMobileSubject.value) {
      this.isMobileSubject.next(this.isMobile);
    }
  }
}

import { AuthConfig } from '@auth0/auth0-angular';
import {
  AUTH_0_MANAGEMENT_API,
  AUTH_CUSTOM_DOMAIN,
  DATAHERALD_CLIENT_ID,
} from 'projects/shared-assets/constants/auth';

export const auth: AuthConfig = {
  domain: AUTH_CUSTOM_DOMAIN,
  clientId: DATAHERALD_CLIENT_ID,

  audience: AUTH_0_MANAGEMENT_API,

  scope: 'read:current_user',

  cacheLocation: 'localstorage',
  useRefreshTokens: true,

  httpInterceptor: {
    allowedList: [
      {
        uriMatcher: (uri) => uri.indexOf('/v4/user/trial') > -1,
        allowAnonymous: true,
      },
      {
        uriMatcher: (uri) => uri.indexOf('/v4/user/mobile_email') > -1,
        allowAnonymous: true,
      },
      {
        uriMatcher: (uri) => uri.indexOf('/v4/feed') > -1,
        allowAnonymous: true,
      },
      {
        uriMatcher: (uri) => uri.indexOf('/v4/viz') > -1,
        allowAnonymous: true,
      },
      {
        uriMatcher: (uri) => uri.indexOf('/v4/geo') > -1,
        allowAnonymous: true,
      },
      {
        uri: 'http://localhost:5000/*',
        tokenOptions: {
          audience: AUTH_0_MANAGEMENT_API,
          scope: 'read : current_user',
        },
      },

      {
        uri: 'https://dev.api.dataherald.com/*',

        tokenOptions: {
          audience: AUTH_0_MANAGEMENT_API,
          scope: 'read : current_user',
        },
      },
      {
        uri: 'https://api.dataherald.com/*',
        tokenOptions: {
          audience: AUTH_0_MANAGEMENT_API,
          scope: 'read : current_user',
        },
      },
    ],
  },
};

export const shared_front_core = {
  collect_analytics: true,
};

<div class="wrapper" (resized)="onResized($event)">
  <ng-container *ngIf="size">
    <div class="graph-viz">
      <ng-container *ngIf="showInteractiveGraphDialog">
        <div class="interactive-info-modal">
          <div
            class="header"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="5px"
          >
            <h5 class="title">Did you know?</h5>
            <img
              class="close-btn"
              src="shared-assets/icons/close.svg"
              alt="close button"
              (click)="closeDialog()"
            />
          </div>
          <p>
            All visualizations are fully interactive - hover and click for even
            deeper insights!
          </p>
        </div>
      </ng-container>

      <ng-container [ngSwitch]="block.type">
        <ng-container *ngSwitchCase="'geoviz'">
          <app-geoviz-block [block]="block" [size]="size"></app-geoviz-block>
        </ng-container>

        <ng-container *ngSwitchCase="'segment'">
          <ng-container [ngSwitch]="block.chart_type">
            <ng-container *ngSwitchCase="'horizontal_bar'">
              <app-horizontal-barchart-block
                [block]="block"
                [size]="size"
              ></app-horizontal-barchart-block>
            </ng-container>

            <ng-container *ngSwitchCase="'pie_chart'">
              <app-pie-chart-block
                [block]="block"
                [size]="size"
              ></app-pie-chart-block>
            </ng-container>

            <ng-container *ngSwitchCase="'percent_pill'">
              <app-percent-pill-block
                [block]="block"
                [size]="size"
              ></app-percent-pill-block>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'point_map'">
          <app-point-map-block
            [block]="block"
            [size]="size"
          ></app-point-map-block>
        </ng-container>

        <ng-container *ngSwitchCase="'axis'">
          <ng-container [ngSwitch]="block.chart_type">
            <ng-container *ngSwitchCase="'multi_line'">
              <app-multiline-block
                [block]="block"
                [size]="size"
              ></app-multiline-block>
            </ng-container>

            <ng-container *ngSwitchCase="'grouped_bar'">
              <app-grouped-barchart-block
                [block]="block"
                [size]="size"
              ></app-grouped-barchart-block>
            </ng-container>

            <ng-container *ngSwitchCase="'stacked_bar'">
              <app-stacked-barchart-block
                [block]="block"
                [size]="size"
              ></app-stacked-barchart-block>
            </ng-container>

            <ng-container *ngSwitchCase="'bar_line'">
              <app-barline-block
                [block]="block"
                [size]="size"
              ></app-barline-block>
            </ng-container>
            <ng-container *ngSwitchCase="'simple_line'">
              <app-simpleline-block
                [block]="block"
                [size]="size"
              ></app-simpleline-block>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'multipartite_graph'">
          <app-multipartite-graph-block
            [block]="block"
            [size]="size"
          ></app-multipartite-graph-block>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>

import { environment } from '../../environments/environment';

export default {
  api_url: environment.api_URL,
  client_url: environment.client_URL,
  embed_url: environment.embed_client_URL,
  website_url: environment.website_URL,
  org_name: 'Dataherald',
  newFeedsDateCutoff: 14,
};

import { PartialSources } from './../models/PartialSource';
import { formatDate } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cacheable } from 'ts-cacheable';
import { ONE_HOUR } from '../constants/cache';
import { EnvConfig, EnvConfigService } from '../injectables/env-config';
import { ChartTypes } from './../models/ChartType';
import { Parameters, ParameterTypes } from '../models/parameter';
import { PartialFeeds } from './../models/PartialFeed';
import { Topics } from '../models/topic';

@Injectable({
  providedIn: 'root',
})
export class DataFeedService {
  CONTROLLER_URL: string;

  constructor(
    private http: HttpClient,
    @Inject(EnvConfigService) private envConfig: EnvConfig
  ) {
    this.CONTROLLER_URL = `${this.envConfig.api_URL}/feed`;
  }

  @Cacheable({
    maxAge: ONE_HOUR,
  })
  getList(queryParams?: DataFeedListRequestParams): Observable<PartialFeeds> {
    const params = new HttpParams({
      fromObject: queryParams,
    });
    const url = `${this.CONTROLLER_URL}/list`;
    return this.http.get<PartialFeeds>(url, { params });
  }

  @Cacheable({
    maxAge: ONE_HOUR,
  })
  getNewestFeeds(date: Date): Observable<PartialFeeds> {
    const formattedDate = formatDate(date, 'yyyy-MM-dd', 'en-US');
    return this.getList({ created_after: formattedDate });
  }

  @Cacheable({
    maxAge: ONE_HOUR,
  })
  getFeatured(): Observable<PartialFeeds> {
    const url = `${this.CONTROLLER_URL}/featured`;
    return this.http.get<PartialFeeds>(url);
  }

  @Cacheable({
    maxAge: ONE_HOUR,
  })
  getTopics(): Observable<Topics> {
    const url = `${this.CONTROLLER_URL}/topics`;
    return this.http.get<Topics>(url);
  }

  @Cacheable({
    maxAge: ONE_HOUR,
  })
  getChartTypes(): Observable<ChartTypes> {
    const url = `${this.CONTROLLER_URL}/chart_types`;
    return this.http.get<ChartTypes>(url);
  }

  @Cacheable({
    maxAge: ONE_HOUR,
  })
  getParameters(dataFeedId: string): Observable<Parameters> {
    const url = `${this.CONTROLLER_URL}/${dataFeedId}/parameters`;
    return this.http.get<Parameters>(url);
  }

  @Cacheable({
    maxAge: ONE_HOUR,
  })
  getDependantParameterOptions(
    dataFeedId: string,
    dependantParameterName: string,
    valuesDependingOn: ParameterDependingOn
  ): Observable<ParameterTypes> {
    // it's a POST beacuse of the body request
    const url = `${this.CONTROLLER_URL}/${dataFeedId}/parameters/${dependantParameterName}`;
    return this.http.post<ParameterTypes>(url, valuesDependingOn);
  }

  search(searchParams: SearchFeedParams): Observable<SearchFeedResponse> {
    const params = new HttpParams({
      fromObject: searchParams,
    });
    const url = `${this.CONTROLLER_URL}/search`;
    return this.http.get<SearchFeedResponse>(url, { params });
  }

  clearAllDataFeedsCache(): Observable<string> {
    const url = `${this.CONTROLLER_URL}/clear_cache`;
    return this.http.get<string>(url);
  }

  clearDataFeedCache(dataFeedId: string): Observable<string> {
    const url = `${this.CONTROLLER_URL}/${dataFeedId}/clear_cache`;
    return this.http.get<string>(url);
  }
}

export type SearchFeedParams = {
  query?: string;
  topic?: string;
  tags?: string;
  sources?: string;
};

export type SearchFeedResponse = {
  feeds: PartialFeeds;
  sources: PartialSources;
  tags: string[];
};

export type ParameterDependingOn = {
  // the key is the Parameter.name
  [key: string]: string;
};

export type DataFeedListRequestParams = {
  chart_type?: string; // name
  topic?: string;
  source?: string; // source id
  created_after?: string; // datetime
};

import { FormArray, FormGroup } from '@angular/forms';

export const triggerValidations = (group: FormGroup | FormArray): void => {
  Object.keys(group.controls).forEach((key: string) => {
    const abstractControl = group.controls[key];

    if (
      abstractControl instanceof FormGroup ||
      abstractControl instanceof FormArray
    ) {
      triggerValidations(abstractControl);
    } else {
      abstractControl.markAsDirty();
      abstractControl.updateValueAndValidity({ onlySelf: true });
    }
  });
};

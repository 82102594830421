import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import * as Bowser from 'bowser';
import { ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PartialOrganization } from '../models/organization';
import { UserDetails } from '../models/user';
import {
  VisualizationData,
  VisualizationListItem,
  VisualizationSummary,
} from '../models/Visualization';

declare let gtag: Function;
declare let posthog: any;

const flatOrganizationProps = (
  organization: PartialOrganization
): Record<string, unknown> => {
  return {
    organization: organization.name, // legacy object key to fullfill already created amplitude queries
    'org-id': organization.id,
    'org-type': organization.type,
    'org-subtype': organization.subtype,
    'org-date_created': organization.date_created,
  };
};

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private platform: Bowser.Parser.ParsedResult;
  private blockEventSubject = new ReplaySubject<AnalyticsEventEmitter>(1);
  blockEvents$ = this.blockEventSubject.asObservable().pipe(filter((v) => !!v));

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.platform = Bowser.parse(window.navigator.userAgent);
    }
  }

  config(page_path: string): void {
    if (isPlatformBrowser(this.platformId) && gtag) {
      gtag('config', 'UA-167844505-1', { page_path });
      gtag('send', 'pageview');
    }
  }

  v4EventEmitter({
    eventName,
    vizData,
    vizSummary,
    extraProperties,
    callAnalytics = true,
  }: AnalyticsEventEmitter): void {
    return;
    if (isPlatformBrowser(this.platformId)) {
      const url: URL = new URL(this.getParentUrl());
      if (callAnalytics) {
        posthog.capture(eventName, {
          version: 'v4',
          publisher: url.host,
          webpage: url.pathname,
          path: window.location.pathname,
          ...this.getVizDataProperties(vizData),
          ...this.getVizSummaryProperties(vizSummary),
          ...this.getQueryProperties(),
          ...this.getPlatformInfo(),
          ...extraProperties,
        });
      }
    }
  }

  blockEventEmitter(
    eventName: string,
    eventAction: string,
    eventCategory: string,
    eventLabel: string = null,
    eventValue = 0,
    extraProperties: Record<string, unknown> = {},
    callAnalytics = true
  ) {
    this.blockEventSubject.next({
      eventName,
      eventAction,
      eventCategory,
      eventLabel,
      eventValue,
      extraProperties,
      callAnalytics,
    });
  }

  /*
  Data Herald identify user
  */
  dhIdentifyUser(user: UserDetails): void {
    return;
    if (!posthog) return;
    const userProperties = this.getUserProperties(user);
    posthog.identify(
      user.email, // Required. user's unique identifier
      userProperties // $set, optional
    );
  }

  /*
  Data Herald events tracking
  */
  dhEvent(
    eventName: string,
    pageRoute: string,
    extraProperties: any = {}
  ): void {
    return;
    // abort if not in a client browser
    if (!isPlatformBrowser(this.platformId)) return;

    posthog.capture(eventName, {
      pageRoute,
      ...this.getPlatformInfo(),
      ...extraProperties,
    });
  }

  /*
  Extracts the properties we want to track for analytics for certain events that
  relate to vizs (i.e. view viz, edit viz, etc.)
  */
  getVizListItemProperties(
    vizListItem: VisualizationListItem
  ): Record<string, unknown> {
    return {
      'viz-id': vizListItem.id,
      'viz-feedId': vizListItem.feed_id,
      'viz-topic': vizListItem.topic,
      'viz-title': vizListItem.title,
      'viz-sourceId': vizListItem.source_name,
      'viz-creatorId': vizListItem.creator.id,
    };
  }

  getVizSummaryProperties(
    vizSummary: VisualizationSummary = null
  ): Record<string, unknown> {
    return vizSummary
      ? {
          'viz-id': vizSummary.id,
          'viz-feedId': vizSummary.feed_id,
          'viz-orgName': vizSummary.organization.name,
          'viz-topic': vizSummary.topic,
          'viz-title': vizSummary.title,
          'viz-sourceId': vizSummary.source?.id,
          'viz-sourceName': vizSummary.source?.text,
          ...(vizSummary.creator && {
            'viz-creatorId': vizSummary.creator.id,
          }),
          'viz-numSmartTiles': vizSummary.takeaway_tiles_count,
          'viz-numTags': vizSummary.tags.length,
          'graph-type': vizSummary.chart_type,
        }
      : {};
  }

  getVizDataProperties(
    vizData: VisualizationData = null
  ): Record<string, unknown> {
    return vizData
      ? {
          'viz-numControls': vizData.ui_controls.length,
          'viz-controlType': vizData.ui_controls[0]?.type,
        }
      : {};
  }

  private getUserProperties(user: UserDetails): Record<string, unknown> {
    return {
      ...user,
      ...flatOrganizationProps(user.organization),
    };
  }

  private getQueryProperties() {
    const obj = {};
    if (isPlatformBrowser(this.platformId)) {
      new URLSearchParams(window.location.search).forEach((v, k) => {
        obj[`Query {k}`] = v;
      });
    }
    return obj;
  }

  private getParentUrl(): string {
    if (isPlatformBrowser(this.platformId)) {
      const isInIframe = parent !== window;
      let parentUrl = 'https://localhost:4200/';

      if (isInIframe) {
        parentUrl = document.referrer;
      }

      return parentUrl;
    }
  }

  private getPlatformInfo(): any {
    return {
      userAgent: window.navigator.userAgent,
      browser: this.platform.browser.name,
      browserVersion: this.platform.browser.version,
      os: this.platform.os.name,
      osVersion: this.platform.os.version,
      osVersionName: this.platform.os.versionName,
      engine: this.platform.engine.name,
      engineVersion: this.platform.engine.version,
      deviceType: this.platform.platform.type,
      deviceModel: this.platform.platform.model,
      deviceVendor: this.platform.platform.vendor,
    };
  }
}

export type AnalyticsEventEmitter = {
  eventName: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  eventValue?: number;
  vizSummary?: VisualizationSummary;
  vizData?: VisualizationData;
  extraProperties?: Record<string, unknown>;
  callAnalytics?: boolean;
};

import { InjectionToken } from '@angular/core';

export interface EnvConfig {
  api_URL: string;
  api_v2_URL: string;
  embed_client_URL: string;
  collect_analytics: boolean;
}

export const EnvConfigService = new InjectionToken<EnvConfig>('EnvConfig');

export enum UI_ERRORS {
  UNKNOWN = 'unknown',
  CUSTOM = 'custom',
  AREA_TOO_LONG = 'area-too-long',
  EMPTY_VIZ_LIST = 'empty-viz-list',
  PARAMETERS_NEEDED = 'parameters-needed',
  EMPTY_SEARCH_RESULTS = 'empty-search-results',
}

export default UI_ERRORS;

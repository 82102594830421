<ng-container *ngIf="_opened">
  <ng-container *ngIf="mediaObserver.isActive('gt-sm'); else mobile">
    <ui-modal
      title="Export Visualization"
      width="80vw"
      height="70vh"
      [zIndex]="zIndex"
      [sideCoverImageUrl]="'shared-assets/images/viz-export.png'"
      (onCloseModal)="onClickClose()"
    >
      <ng-container *ngTemplateOutlet="exportTabs"></ng-container>
    </ui-modal>
  </ng-container>
  <ng-template #mobile>
    <nz-drawer
      nzPlacement="bottom"
      nzHeight="90vh"
      [nzBodyStyle]="{ padding: 0 }"
      [nzVisible]="true"
      (nzOnClose)="onClickClose()"
    >
      <ng-container *nzDrawerContent>
        <h2>Export Visualization</h2>
        <ng-container *ngTemplateOutlet="exportTabs"></ng-container>
      </ng-container>
    </nz-drawer>
  </ng-template>
</ng-container>

<ng-template #exportTabs>
  <ui-tabs (tabChange)="updateTab($event)">
    <ui-tab title="Link & Embed" [active]="activeTab === EXPORT_TABS.EMBED">
      <div class="export-row">
        <h3>Full Preview Link</h3>
        <ng-container
          *ngIf="mediaObserver.isActive('gt-xs'); else mobilePreviewLink"
        >
          <div
            class="copy-link"
            fxLayout="row"
            fxLayoutAlign="space-between start"
            fxLayoutGap="20px"
          >
            <input
              fxFlex="70"
              readonly
              type="text"
              name="previewLink"
              nz-input
              [value]="this.previewLink"
            />
            <ng-container *ngIf="!previewCopied; else linkCopiedBtn">
              <button
                class="btn-outline-white"
                [cdkCopyToClipboard]="this.previewLink"
                (click)="previewLinkCopied()"
              >
                <img
                  src="shared-assets/icons/copy-link-black-icon.svg"
                  alt=""
                />
                <p>Copy link</p>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="export-row">
        <h3>Embed Code</h3>
        <ng-container
          *ngIf="mediaObserver.isActive('gt-xs'); else mobileEmbedCode"
        >
          <div
            class="copy-link"
            fxLayout="row"
            fxLayoutAlign="space-between start"
            fxLayoutGap="20px"
          >
            <textarea
              fxFlex="70"
              name="embedCode"
              readonly
              rows="6"
              nz-input
              [ngModel]="this.embedCode"
            ></textarea>
            <ng-container *ngIf="!embedCopied; else linkCopiedBtn">
              <button
                class="btn-outline-white"
                [cdkCopyToClipboard]="this.embedCode"
                (click)="embedCodeCopied()"
              >
                <img
                  src="shared-assets/icons/copy-link-black-icon.svg"
                  alt=""
                />
                <p>Copy code</p>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ui-tab>
    <ui-tab title="Image" [active]="activeTab === EXPORT_TABS.IMAGE">
      <div class="export-row">
        <h3>PNG</h3>
        <div
          ngClass.xs="small-size-option"
          fxLayout="column"
          fxLayout.gt-xs="row"
          fxLayoutAlign.gt-xs="space-between end"
          fxLayoutGap="20px"
        >
          <div fxLayout="row" fxLayoutGap="10px">
            <div fxLayout="column" fxLayoutGap="10px">
              <p>Width</p>
              <input
                nz-input
                [placeholder]="sizePNG.width"
                [(ngModel)]="sizePNG.width"
              />
            </div>
            <div fxLayout="column" fxLayoutGap="10px">
              <p>Height</p>
              <input
                nz-input
                [placeholder]="sizePNG.height"
                [(ngModel)]="sizePNG.height"
              />
            </div>
          </div>
          <ng-container *ngIf="!exportingPNG; else exportingBtn">
            <button class="btn-outline-white" (click)="downloadPNG()">
              <img src="shared-assets/icons/image.svg" alt="" />
              <p>Export PNG</p>
            </button>
          </ng-container>
        </div>
      </div>
    </ui-tab>
    <ui-tab title="Data" [active]="activeTab === EXPORT_TABS.DATA">
      <div class="export-row">
        <div
          fxLayout="column"
          fxLayout.gt-xs="row"
          fxLayoutAlign.gt-xs="space-between start"
          fxLayoutGap="20px"
        >
          <div fxLayout="column" fxLayoutGap="5px">
            <h3>CSV</h3>
            <p>Downloads the data powering the graph</p>
          </div>
          <ng-container *ngIf="!exportingCSV; else exportingBtn">
            <button class="btn-outline-white" (click)="downloadCSV()">
              <img src="shared-assets/icons/sheet.svg" alt="" />
              <p>Export CSV</p>
            </button>
          </ng-container>
        </div>
      </div>
    </ui-tab>
  </ui-tabs>
</ng-template>

<ng-template #loadingRef>
  <nz-spin class="container-centered" nzSimple nzTip="Exporting..."></nz-spin>
</ng-template>

<ng-template #exportingBtn>
  <button class="btn-exporting" disabled>
    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px">
      <nz-spin nzSimple nzSize="small"></nz-spin>
      <p>Exporting!</p>
    </div>
  </button>
</ng-template>

<ng-template #linkCopiedBtn>
  <button class="btn-link-copied" disabled>
    <img src="shared-assets/icons/check-toggle-white-icon.svg" alt="" />
    Link Copied!
  </button>
</ng-template>

<ng-template #mobilePreviewLink>
  <div
    class="copy-link"
    [cdkCopyToClipboard]="this.previewLink"
    (click)="mobilePreviewCopied()"
  >
    <nz-input-group [nzSuffix]="copyIcon">
      <input
        readonly
        type="text"
        name="previewLink"
        nz-input
        nzSize="large"
        [value]="this.previewLink"
      />
    </nz-input-group>
  </div>
</ng-template>

<ng-template #mobileEmbedCode>
  <div
    class="copy-link"
    [cdkCopyToClipboard]="this.embedCode"
    (click)="mobileEmbedCodeCopied()"
  >
    <nz-input-group [nzSuffix]="copyIcon">
      <input
        readonly
        type="text"
        name="embedCode"
        nz-input
        nzSize="large"
        [value]="this.embedCode"
      />
    </nz-input-group>
  </div>
</ng-template>

<ng-template #copyIcon>
  <img src="shared-assets/icons/copy-link-black-icon.svg" alt="" />
</ng-template>

import { Component, OnInit, SkipSelf } from '@angular/core';
import * as d3 from 'd3';
import { AxisBlockComponent } from 'projects/HGFrontSharedUI/src/lib/modules/core/components/blocks/axis-block/axis-block.component';
import { AppService } from '../../../services/app.service';
import { BlockAxis } from 'hg-front-core';

@Component({
  selector: 'app-simpleline-block',
  templateUrl: './simpleline-block.component.html',
  styleUrls: ['./simpleline-block.component.scss'],
})
export class SimplelineBlockComponent
  extends AxisBlockComponent
  implements OnInit
{
  constructor(@SkipSelf() public appService: AppService) {
    super(appService);
  }

  drawChart(): void {
    this.config = <BlockAxis>this.config;
    if (
      !this.block ||
      !this.size ||
      this.size.width === 0 ||
      this.size.height === 0
    )
      return;
    d3.selectAll('#SVG > *').remove();
    const svg = d3.select('#SVG');
    const isMobile = this.isMobile;
    const width = this.size.width;
    const height = this.size.height;
    const margin = {
      top: 50,
      right: 70,
      bottom: 30,
      left: isMobile ? 40 : 70,
    };
    const w = width - margin.left - margin.right;
    const h = height - margin.top - margin.bottom;

    svg.attr('width', width).attr('height', height);

    const showDifference = this.config.simple_line_options.show_difference;
    const xAxis = this.config.xaxis;
    const yAxis = this.config.yaxis;

    const defaultFontSize = isMobile ? 12 : 14;
    const lineColor = '#1D316C';
    const axisLabelFontSize = isMobile ? 10 : 12;
    const differenceLabelColor = '#D39516';
    const labelColor = '#448187';
    const labelFontSize = isMobile ? 12 : 14;
    const valueColor = '#1D316C';

    const x = d3.scaleLinear().range([0, w]);
    const y = d3.scaleLinear().range([h, 0]);

    svg
      .append('defs')
      .append('marker')
      .attr('id', 'arrowhead')
      .attr('viewBox', '-0 -5 10 10')
      .attr('refX', 5)
      .attr('refY', 0)
      .attr('markerWidth', 4)
      .attr('markerHeight', 4)
      .attr('orient', 'auto')
      .append('path')
      .attr('d', 'M0,-5L10,0L0,5')
      .attr('fill', lineColor)
      .attr('class', 'arrowHead');

    const chart = svg
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
      .attr('width', w)
      .attr('height', h);

    const points = [];
    this.config.data.forEach((datapoint, i) => {
      points.push({
        x: 1 + i * (isMobile ? 0.5 : 1),
        y: 1 + i,
        value: datapoint.value,
        category: datapoint.category,
      });
    });

    x.domain([0, 1 + this.config.data.length]);
    y.domain([0, 1 + this.config.data.length]);

    // create axis without ticks
    chart
      .append('g')
      .attr('transform', 'translate(0,' + h + ')')
      .call(
        d3
          .axisBottom(x)
          .tickSize(0)
          .tickFormat(() => '')
      )
      .append('text')
      .attr('fill', '#000')
      .attr('x', w)
      .attr('dy', '-0.5em')
      .attr('text-anchor', 'end')
      .attr('font-size', axisLabelFontSize)
      .text(xAxis.label);

    chart
      .append('g')
      .call(
        d3
          .axisLeft(y)
          .tickSize(0)
          .tickFormat(() => '')
      )
      .append('text')
      .attr('fill', '#000')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('text-anchor', 'end')
      .attr('font-size', axisLabelFontSize)
      .text(yAxis.label);

    // create linear line with points, scale doesn't matter
    // TODO: add category labels

    const chartPoints = chart.append('g').selectAll('dot').data(points).enter();

    chartPoints
      .append('circle')
      .attr('cx', (d) => {
        return x(d.x);
      })
      .attr('cy', (d) => {
        return y(d.y);
      })
      .attr('r', 5)
      .attr('fill', lineColor);

    chartPoints
      .append('text')
      .attr('x', (d) => x(d.x) + 20)
      .attr('y', (d) => y(d.y) + 10)
      .attr('fill', labelColor)
      .attr('font-weight', 'bold')
      .attr('font-size', labelFontSize)
      .text((d) => d.category);
    chartPoints
      .append('text')
      .attr('x', (d) => x(d.x) + 20)
      .attr('y', (d) => y(d.y) + 30)
      .attr('fill', valueColor)
      .attr('font-size', defaultFontSize)
      .text((d) =>
        yAxis.dollars
          ? d.value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
            })
          : d.value
      );

    // create line and label between points
    for (let i = 0; i < points.length - 1; i++) {
      const x1 = points[i].x;
      const x2 = points[i + 1].x;
      const y1 = points[i].y;
      const y2 = points[i + 1].y;
      const xMid = (x1 + x2) / 2;
      const yMid = (y1 + y2) / 2;

      const difference = points[i + 1].value - points[i].value;

      chart
        .append('line')
        .attr('fill', 'none')
        .attr('stroke', lineColor)
        .attr('stroke-width', 1)
        .attr('x1', x(x1))
        .attr('y1', y(y1))
        .attr('x2', x(xMid))
        .attr('y2', y(yMid))
        .attr('marker-end', 'url(#arrowhead)');
      chart
        .append('line')
        .attr('fill', 'none')
        .attr('stroke', lineColor)
        .attr('stroke-width', 1)
        .attr('x1', x(xMid))
        .attr('y1', y(yMid))
        .attr('x2', x(x2))
        .attr('y2', y(y2));

      if (showDifference) {
        chart
          .append('text')
          .attr('text-anchor', 'end')
          .attr('x', x(xMid) - 10)
          .attr('y', y(yMid) - 10)
          .attr('fill', differenceLabelColor)
          .attr('font-size', defaultFontSize)
          .text(
            `+${
              yAxis.dollars
                ? difference.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                  })
                : difference
            }`
          );
      }
    }
  }
}

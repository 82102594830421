<div fxFlex fxLayout="column" fxLayoutAlign="center center">
  <div
    fxFlex
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="30px"
  >
    <h1>Start building visualizations with our templates</h1>
    <button class="btn-filled" (click)="goToBuild()">
      <img src="shared-assets/icons/plus-black-icon.svg" alt="" />
      Build
    </button>
    <p>
      {{ page | capitalizeFirst }} page is not yet available on mobile devices.
      Please use a desktop device to have the full
      <img
        src="shared-assets/icons/higeorge-logo/dataherald-logo-full-color.svg"
        alt=""
      />
      experience.
    </p>
  </div>
  <p class="contact">
    <span>Shouldn't be seeing this?</span>
    <a class="link" [href]="WEBSITE_URL + '/contact'"
      ><em>Contact support</em>
    </a>
  </p>
</div>
